import { useMenuToggle } from '../services/MenuToggleContext';

export const Main = ({ children }) => {
    const { collapsed, isSmallWidthDevice, toggleCollapsed } = useMenuToggle();

    return (
        <>
            <div className="main vh-100 overflow-auto position-fixed">{children}</div>
            {!collapsed && isSmallWidthDevice() && <div className="main-backdrop modal-backdrop show" onClick={toggleCollapsed}></div>}
        </>
    );
};
