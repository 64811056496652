import { useLayoutEffect, useRef, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Api } from '../services/Api';
import { Error } from './Error';

export const UserAccessTokenModal = ({ isOpen, toggle }) => {
    const [error, setError] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [copied, setCopied] = useState(false);
    const textarea = useRef(null);

    useLayoutEffect(() => {
        if (textarea.current?.style != null) {
            textarea.current.style.height = "inherit";
            textarea.current.style.height = `${textarea.current.scrollHeight}px`;
        }
    }, [accessToken]);

    const generate = () => {

        const request = Api.createPersonalAccessToken();
        request.then(data => {
            if (!request.aborted) {
                setAccessToken(data);
                setError(null);
            }
        }).catch(error => {
            if (!request.aborted) {
                setAccessToken(null);
                setError(error);
            }
        });
    };

    const copy = () => {
        navigator.clipboard.writeText(accessToken.accessToken);
        setCopied(true);
    };


    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader>Jeton d'accès personnel</ModalHeader>
            <ModalBody>
                {error && <Error error={error} />}
                {accessToken == null ?
                    (
                        <div>
                            Cliquez sur le bouton "Générer" pour générer un jeton d'accès personnel.<br/>
                            La durée de validité du jeton est de <u>24 heures</u>
                        </div>
                    ) : (
                        <>
                            <div className="mb-3">
                                Voici votre jeton d'accès personnel.<br />
                                La durée de validité du jeton est de <u>24 heures</u>
                            </div>
                            <div className="card p-3">
                                <div className="mb-3 text-danger">
                                    Ce jeton est personnel et revêt un caractère sensible.<br />
                                    Veillez à bien le protéger (ne pas le stoquer en clair) et à ne surtout pas le transmettre à un tiers.
                                </div>
                                <textarea
                                    ref={textarea}
                                    readOnly
                                    disabled
                                    value={accessToken.accessToken}
                                    className="form-control mb-3 overflow-hidden"
                                    style={{ resize: 'none' }} />
                                <button className="btn btn-secondary btn-sm" onClick={copy}>
                                    {copied ? "Jeton copié !" : "Copier le jeton"}</button>
                            </div>
                        </>
                    )
                }
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    className="btn btn-link"
                    onClick={toggle}>Annuler</button>
                {accessToken != null ?
                    (
                        <button
                            type="button"
                            className="btn btn-primary ms-2"
                            onClick={toggle}>Fermer</button>
                    ) :
                    (
                        <button
                            type="button"
                            className="btn btn-primary ms-2"
                            onClick={generate}>
                            <i className="bi-key me-3"></i>
                            Générer un jeton
                        </button>
                    )
                }
            </ModalFooter>
        </Modal>
    );
}