import { useState } from 'react';
import { Api } from '../../services/Api';
import { useScope } from '../../services/ScopeContext';
import { ConfirmButton } from '../ConfirmButton';
import { ResourceAccountsSelectorModal } from './ResourceAccountsSelectorModal';
import { ResourceAccountThumb } from './ResourceAccountThumb';

export const ResourceAccountsSelector = ({ value, onChange, disabled }) => {
    const scope = useScope();
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    }

    const removeItem = (index) => {
        let newValue = [...value];
        newValue.splice(index, 1);
        notify(newValue);
    }

    const onSearch = async (params) => {
        try {
            const args = Api.buildScopedParams({ ...params, used: false }, scope);
            const data = (await Api.lookupResourceAccounts(args)).values;
            const ids = value.map(o => o.id);
            return data.filter(a => ids.indexOf(a.id) < 0);
        } catch (error) {
            alert(error.status + ": " + error.message);
        }
    }

    const onModalConfirm = (item) => {
        let newValue = [...value];
        if (item) {
            newValue.push(item);
        }

        notify(newValue);
        toggleModal();
    }

    const notify = (value) => {
        onChange && onChange(value);
    }

    return (
        <div className="card">
            <div className="p-2 bg-light border-bottom card-header">
                <div className="d-flex flex-row justify-content-between">
                    <div>
                        <button
                            type="button"
                            className="btn btn-sm btn-primary me-3"
                            onClick={() => toggleModal()}
                            disabled={disabled}>
                            <i className="bi-plus pe-1"></i>
                            Ajouter
                        </button>
                    </div>
                </div>
            </div>
            <div className="table-responsive">
                <table className='table table-hover m-0'>
                    <colgroup>
                        <col />
                        {!disabled && <col width="60px" />}
                    </colgroup>
                    <tbody>
                        {value != null && value.map((item, index) =>
                            <tr key={item.id}>
                                <td className="ps-3">
                                    <ResourceAccountThumb value={item} />
                                </td>
                                {!disabled &&
                                    <td align="right" className="pe-3 align-middle">
                                        <ConfirmButton size="sm" onConfirm={(confirmed) => confirmed && removeItem(index)}>
                                            <i className="bi-trash"></i>
                                        </ConfirmButton>
                                    </td>
                                }
                            </tr>
                        )}
                    </tbody>
                </table>
                {value.length === 0 &&
                    <div className="p-3 text-center text-muted">Aucun</div>
                }
            </div>
            <ResourceAccountsSelectorModal
                isOpen={showModal}
                toggle={toggleModal}
                getMatches={onSearch}
                onChange={onModalConfirm}
            />
        </div>
    );
}
