import { useField } from 'formik';
import { useEffect } from 'react';
import { CallTargetLookup } from '../CallTargetLookup';
import { EntityFormField } from '../EntityFormField';
import { Label } from '../Label';
import { MessagePrompt } from '../MessagePrompt';
import { PhonePicker } from '../PhonePicker';
import { RadioList } from '../RadioList';
import { SwitchInput } from '../SwitchInput';

export const CallQueueThresholdOverrunAction = ({ name, value, onChange, disabled }) => {
    const [action, , actionHelpers] = useField(name + ".action");
    const [agent, , agentHelpers] = useField(name + ".agent");
    const [callQueue, , callQueueHelpers] = useField(name + ".callQueue");
    const [autoAttendant, , autoAttendantHelpers] = useField(name + ".autoAttendant");
    const [sharedVoicemail, , sharedVoicemailHelpers] = useField(name + ".sharedVoicemail");
    const [externalPstn, , externalPstnHelpers] = useField(name + ".externalPstn");
    const [sharedVoicemailPrompt, , sharedVoicemailPromptHelpers] = useField(name + ".sharedVoicemailPrompt");
    const [enableSharedVoicemailTranscription, , enableSharedVoicemailTranscriptionHelpers] = useField(name + ".enableSharedVoicemailTranscription");

    useEffect(() => {

        if (agent.value != null && action.value !== 1) {
            agentHelpers.setValue(null);
        }

        if (callQueue.value != null && action.value !== 2) {
            callQueueHelpers.setValue(null);
        }

        if (autoAttendant.value != null && action.value !== 3) {
            autoAttendantHelpers.setValue(null);
        }

        if (sharedVoicemail.value != null && action.value !== 4) {
            sharedVoicemailHelpers.setValue(null);
        }

        if (sharedVoicemailPrompt.value != null && action.value !== 4) {
            sharedVoicemailPromptHelpers.setValue(null);
        }

        if (action.value === 4) {
            if (sharedVoicemailPrompt.value == null) {
                sharedVoicemailPromptHelpers.setValue({
                    activeType: 1,
                    textToSpeech: null,
                    audioFile: null
                });
            }
        }

        if (enableSharedVoicemailTranscription.value && action.value !== 4) {
            enableSharedVoicemailTranscriptionHelpers.setValue(false);
        }

        if (externalPstn.value != null && externalPstn.value.length > 0 && action.value !== 5) {
            externalPstnHelpers.setValue(null);
        }

    }, [action.value,
        agent.value, agentHelpers,
        callQueue.value, callQueueHelpers,
        autoAttendant.value, autoAttendantHelpers,
        sharedVoicemail.value, sharedVoicemailHelpers,
        externalPstn.value, externalPstnHelpers,
        sharedVoicemailPrompt.value, sharedVoicemailPromptHelpers,
        enableSharedVoicemailTranscription.value, enableSharedVoicemailTranscriptionHelpers]);


    const options = [
        {
            value: 2,
            label: "Transférer vers un groupe d'agents",
            children: () => {
                return (
                    <EntityFormField field={callQueue}>
                        <CallTargetLookup
                            {...callQueue}
                            disabled={disabled}
                            placeholder="Rechercher un groupe d'agents"
                            type="callQueue"
                            onChange={(o) => callQueueHelpers.setValue(o)} />
                    </EntityFormField>

                );
            }
        },
        {
            value: 3,
            label: "Transférer vers un standard",
            children: () => {
                return (
                    <EntityFormField field={autoAttendant}>
                        <CallTargetLookup
                            {...autoAttendant}
                            disabled={disabled}
                            placeholder="Rechercher un standard"
                            type="autoAttendant"
                            onChange={(o) => autoAttendantHelpers.setValue(o)} />
                    </EntityFormField>
                );
            }
        },
        {
            value: 1,
            label: "Transférer vers une personne dans l'organisation",
            children: () => {
                return (
                    <EntityFormField field={agent}>
                        <CallTargetLookup
                            {...agent}
                            disabled={disabled}
                            placeholder="Rechercher un agent"
                            type="agent"
                            onChange={(o) => agentHelpers.setValue(o)} />
                    </EntityFormField>
                );
            }
        },
        {
            value: 5,
            label: "Transférer vers un numéro de téléphone externe",
            children: () => {
                return (
                    <EntityFormField field={externalPstn}>
                        <PhonePicker
                            {...externalPstn}
                            disabled={disabled}
                            onChange={(o) => externalPstnHelpers.setValue(o)} />
                    </EntityFormField>
                );
            }
        },
        {
            value: 4,
            label: "Transférer vers une messagerie vocale",
            children: () => {
                return (
                    <div className="card px-3 py-3">
                        <EntityFormField field={sharedVoicemail}>
                            <CallTargetLookup
                                {...sharedVoicemail}
                                disabled={disabled}
                                placeholder="Rechercher une messagerie vocale"
                                type="sharedVoicemail"
                                onChange={(o) => sharedVoicemailHelpers.setValue(o)} />
                        </EntityFormField>
                        <div className="mt-3">
                            <Label>Message d'accueil de messagerie</Label>
                            <div className="card px-3 py-3">
                                <MessagePrompt required
                                    {...sharedVoicemailPrompt}
                                    disabled={disabled}
                                    onChange={(value) => sharedVoicemailPromptHelpers.setValue(value)} />
                            </div>
                        </div>
                        <div className="mt-3">
                            <SwitchInput {...enableSharedVoicemailTranscription}
                                disabled={disabled}
                                trueLabel="Transcription activée"
                                falseLabel="Transcription désactivée" />
                        </div>
                    </div>
                );
            }
        },
        {
            value: 0,
            label: "Raccrocher"
        },
    ];

    return (
        <RadioList
            {...action}
            disabled={disabled}
            className="card px-3 py-2"
            options={options}
            onChange={(value) => actionHelpers.setValue(+value)} />
    );
}
