import React, { useState, useRef, useEffect } from 'react';
import { useCombobox } from 'downshift';
import { Spinner } from 'reactstrap';
import { mergeClassNames } from '../services/ComponentHelper';

// https://stackblitz.com/edit/react-downshift-select-hmkjfn?file=index.js

export const Autocomplete = ({
    getMatches,
    value,
    onChange,
    onBlur,
    name,
    itemToString,
    renderMenuItem,
    inputPlaceholder,
    emptyMessage,
    searchingMessage,
    debounce,
    disabled,
    pageSize,
    className,
    size,
    deletable
}) => {
    const [inputItems, setInputItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTimeoutId, setSearchTimeoutId] = useState(null);
    const inputRef = useRef();


    useEffect(() => {
        if (onBlur) {
            const input = inputRef.current;
            input.addEventListener("blur", (e) => setTimeout(() => onBlur(e), 0));
            return () => input.removeEventListener("blur", onBlur);
        }
    }, [onBlur]);

    const stopSearching = () => {
        setIsLoading(false);
        clearTimeout(searchTimeoutId);
    }

    const search = (query, immediate) => {
        stopSearching();

        setIsLoading(true);
        let timeoutId = setTimeout(async () => {
            setInputItems(await getMatches({ query, pageIndex: 0, pageSize }));
            setIsLoading(false);
        }, immediate ? 0 : debounce);

        setSearchTimeoutId(timeoutId);
    };

    const internalItemToString = (o) => {
        if (itemToString) return itemToString(o);
        else return o?.name ?? "";
    }

    const internalRenderMenuItem = (o) => {
        if (renderMenuItem) return renderMenuItem(o);
        else return internalItemToString(o);
    }

    const {
        isOpen,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        openMenu,
        closeMenu,
        selectItem,
        setInputValue,
        inputValue,
        selectedItem
    } = useCombobox({
        items: inputItems,
        // having a empty string fallback prevent this
        //https://github.com/downshift-js/downshift#control-props
        // Warning: A component is changing a controlled input of type text to be uncontrolled. Input elements should not switch from controlled to uncontrolled (or vice versa). Decide between using a controlled or uncontrolled input element for the lifetime of the component
        // cf https://github.com/downshift-js/downshift/issues/478#issuecomment-455668762
        selectedItem: value ?? "",
        itemToString: (item) => internalItemToString(item),
        onSelectedItemChange: (change) => {
            //console.log('onSelectedItemChange', change);

            onChange && onChange(change.selectedItem);
            inputRef.current.blur();
        },
        onStateChange: (state) => {
            //console.log('onStateChange', state);

            switch (state.type) {
                case useCombobox.stateChangeTypes.InputChange:
                    search(state.inputValue);
                    break;

                case useCombobox.stateChangeTypes.FunctionOpenMenu:
                    search(inputValue, true);
                    break;

                case useCombobox.stateChangeTypes.InputBlur:
                case useCombobox.stateChangeTypes.InputKeyDownEscape:
                    stopSearching();
                    setInputItems([]);
                    let selectedItemValue = internalItemToString(selectedItem);
                    if (inputValue !== selectedItemValue) {
                        setInputValue(selectedItemValue);
                    }
                    break;
                default:
                    break;
            }
        },
    });


    const createHighlightItemStyle = (index) => {
        return {
            backgroundColor: highlightedIndex === index ? '#bde4ff' : 'inherit',
        };
    };

    const remove = (e) => {
        closeMenu();
        // NOTE : selectItem doit être appelé après closeMenu pour une raison qui m'échappe à ce stade,
        // Si selectItem est appelé après, on ne rentre pas dans onSelectedItemChange
        selectItem(null);
        stopSearching();
    }

    return (
        <div className={mergeClassNames(className, "autocomplete position-relative")}>
            <div {...getComboboxProps({ className: size ? ('input-group input-group-' + size) : 'input-group' })}>
                <input
                    {...getInputProps({
                        ref: inputRef,
                        className: 'form-control',
                        onFocus: () => !isOpen && openMenu(),
                        placeholder: (inputPlaceholder || 'Rechercher'),
                        disabled,
                        name
                    })}
                />
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    tabIndex={-1}
                    onClick={remove}
                    title="Supprimer la sélection"
                    aria-label="Supprimer la sélection"
                    disabled={!deletable || disabled || value == null}>
                    <i className="bi-x-lg"></i>
                </button>
            </div>
            <div {...getMenuProps()}>
                {isOpen &&
                    <ul className="dropdown-menu d-block w-100 mt-1"
                        style={{
                            maxHeight: '300px',
                            overflowY: 'auto',
                        }}>
                        {!isLoading && inputItems.map((item, index) =>
                            <li {...getItemProps({
                                item,
                                index,
                                style: createHighlightItemStyle(index),
                                key: `${item.id}${index}`,
                                className: "cursor-pointer"
                            })}>
                                <div className="dropdown-item">{internalRenderMenuItem(item)}</div>
                            </li>
                        )}
                        {!isLoading && inputItems.length === 0 && (
                            <li className="text-muted">
                                <div className="dropdown-item disabled">{emptyMessage}</div>
                            </li>
                        )}
                        {isLoading && (
                            <li className="text-muted">
                                <div className="dropdown-item disabled">
                                    <Spinner size="sm" className="me-2" />
                                    {searchingMessage}
                                </div>
                            </li>
                        )}
                    </ul>
                }
            </div>
        </div>
    );
}

Autocomplete.defaultProps = {
    inputPlaceholder: "Rechercher ...",
    emptyMessage: "Aucun résultat",
    searchingMessage: "Recherche en cours ...",
    debounce: 300,
    disabled: false,
    pageSize: 20,
    deletable: true
};


// https://stackblitz.com/edit/react-downshift-select-n12wbz?file=index.js

//const ComboboxWithCreateWrapper = () => {
//    const [item, setItem] = useState({ id: '2', value: 'Two' });

//    const items = [
//        { id: '1', value: 'One' },
//        { id: '2', value: 'Two' },
//        { id: '3', value: 'Three' },
//        { id: '4', value: 'Four' },
//        { id: '5', value: 'Five' },
//    ];

//    return (
//        <div className="p-4">
//            <p>Selected Item is {JSON.stringify(item)}</p>
//            <div>
//                <ComboboxWithCreate
//                    value={item}
//                    items={items}
//                    onChange={(value) => setItem(value)}
//                    itemToString={(item) => (item ? item.value : '')}
//                    allowCreation
//                    onAdd={({ inputValue }) => alert(inputValue)}
//                    renderCreateComponent={({ inputValue }) => (
//                        <>
//                            + Add <b>{inputValue}</b>
//                        </>
//                    )}
//                />
//            </div>
//        </div>
//    );
//};

//function AutocompleteWithDownshiftWithCreate({
//    items,
//    value,
//    onChange,
//    itemToString,
//    allowCreation,
//    onAdd,
//    renderCreateComponent,
//}) {
//    const [inputItems, setInputItems] = useState(items);
//    const inputRef = useRef();
//    const menuRef = useRef();
//    const CREATE_ITEM_INDEX = -100;
//    const {
//        isOpen,
//        getMenuProps,
//        getInputProps,
//        getComboboxProps,
//        highlightedIndex,
//        setHighlightedIndex,
//        getItemProps,
//        openMenu,
//        closeMenu,
//        selectItem,
//        setInputValue,
//        inputValue,
//        selectedItem,
//    } = useCombobox({
//        items: inputItems,
//        selectedItem: value,
//        itemToString: (item) => itemToString(item),
//        onInputValueChange: ({ inputValue }) => {
//            setInputItems(filterItems(inputValue, items));
//        },
//        onSelectedItemChange: (change) => {
//            onChange && onChange(change.selectedItem);
//            inputRef.current.blur();
//        },
//        onStateChange: (state) => {
//            console.log('onStateChange', state);

//            switch (state.type) {
//                case useCombobox.stateChangeTypes.InputBlur:
//                case useCombobox.stateChangeTypes.InputKeyDownEscape:
//                    let selectedItemValue = itemToString(selectedItem);
//                    if (inputValue && inputValue !== selectedItemValue) {
//                        setInputValue(selectedItemValue);
//                    }
//                    break;

//                case state.type === useCombobox.stateChangeTypes.FunctionOpenMenu:
//                    setInputItems(filterItems(inputValue, items));
//                    break;
//            }
//        },
//    });

//    const onCreate = () => {
//        closeMenu();
//        // note: it's not clear what we should do here, who is responsible to cleanup things, component ? component client ?
//        setInputValue(itemToString(selectedItem));
//        //onChange && onChange(null);
//        setTimeout(() => {
//            onAdd && onAdd({ inputValue });
//        });
//    };

//    const createHighlightItemStyle = (index) => {
//        return {
//            backgroundColor: highlightedIndex === index ? '#bde4ff' : null,
//        };
//    };

//    return (
//        <div className="position-relative">
//            <div {...getComboboxProps({ className: 'input-group' })}>
//                <input
//                    {...getInputProps({
//                        ref: inputRef,
//                        className: 'form-control',
//                        onFocus: () => !isOpen && openMenu(),
//                        placeholder: 'Choose an element',
//                    })}
//                />
//                <button
//                    className="btn btn-sm btn-outline-secondary px-3"
//                    tabIndex={-1}
//                    onClick={() => selectItem(null)}
//                    aria-label="clear selection"
//                >
//                    X
//                </button>
//            </div>
//            <div
//                {...getMenuProps({
//                    ref: menuRef,
//                })}
//            >
//                {isOpen && (
//                    <ul className="dropdown-menu d-block w-100">
//                        <li>
//                            <ul
//                                style={{
//                                    maxHeight: '400px',
//                                    overflowY: 'auto',
//                                }}
//                                className="list-unstyled"
//                            >
//                                {inputItems.map((item, index) => {
//                                    return (
//                                        <li
//                                            key={`${item.id}${index}`}
//                                            {...getItemProps({
//                                                item,
//                                                index,
//                                                style: createHighlightItemStyle(index),
//                                            })}
//                                        >
//                                            <div className="dropdown-item">{item.value}</div>
//                                        </li>
//                                    );
//                                })}
//                            </ul>
//                        </li>
//                        {inputItems.length === 0 && (
//                            <>
//                                <li className="text-muted">
//                                    <div className="dropdown-item disabled">No results</div>
//                                </li>
//                                {allowCreation && (
//                                    <>
//                                        <li>
//                                            <div className="dropdown-divider"></div>
//                                        </li>
//                                        <li
//                                            onClick={() => onCreate()}
//                                            onMouseOver={() => setHighlightedIndex(CREATE_ITEM_INDEX)}
//                                            style={createHighlightItemStyle(CREATE_ITEM_INDEX)}
//                                        >
//                                            <div className="dropdown-item">
//                                                {renderCreateComponent({ inputValue })}
//                                            </div>
//                                        </li>
//                                    </>
//                                )}
//                            </>
//                        )}
//                    </ul>
//                )}
//            </div>
//        </div>
//    );
//}

//export AutocompleteWithDownshiftWithCreate;
