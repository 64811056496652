import { useField } from "formik";
import { useEffect, useState } from "react";
import { Input } from 'reactstrap';
import { useUser } from "../../services/UserContext";
import { EntityFormField } from "../EntityFormField";
import { EntitySyncState } from "../EntitySyncState";
import { Label } from "../Label";
import { SwitchInput } from "../SwitchInput";

const InputAlert = ({ children }) => {
    return (
        <div className="alert alert-info px-2 py-1 mt-2">
            <small>
                <i className="bi bi-exclamation-circle-fill pe-2"></i>
                {children}
            </small>
        </div>
    );
}

export const TenantEditor = ({ id, readonly }) => {
    const [name] = useField('name');
    const [azureDomain] = useField('azureDomain');
    const [ozmoCode] = useField('ozmoCode');
    const [status] = useField('status');
    const [connectivitySyncState] = useField('connectivitySyncState');
    const [connectivitySyncStateForce] = useField('connectivitySyncState.force');
    const [usersSyncState] = useField('usersSyncState');
    const [usersSyncStateForce] = useField('usersSyncState.force');
    const [agentsSyncState] = useField('agentsSyncState');
    const [agentsSyncStateForce] = useField('agentsSyncState.force');
    const [resourceAccountsSyncState] = useField('resourceAccountsSyncState');
    const [resourceAccountsSyncStateForce] = useField('resourceAccountsSyncState.force');
    const user = useUser();

    const [tenantAdminConsentUrl, setTenantAdminConsentUrl] = useState('');
    useEffect(() => {
        const tenantId = (azureDomain.value ?? "{tenantId}");
        const clientId = (user.env?.azureAppId ?? "{clientId}");
        setTenantAdminConsentUrl("https://login.microsoftonline.com/" + tenantId + "/adminconsent?client_id=" + clientId);
    }, [azureDomain, user.env, setTenantAdminConsentUrl])

    return (
        <>
            <EntityFormField label="Nom" field={name}>
                <Input {...name} disabled={readonly} bsSize="sm" />
            </EntityFormField>
            <EntityFormField label="Code Ozmo" field={ozmoCode}>
                <Input {...ozmoCode} disabled={readonly} bsSize="sm" />
                <InputAlert>Assurez vous que le code Ozmo saisi existe bien en tant que Client compte actif dans Ozmo CLBR.</InputAlert>
            </EntityFormField>
            <EntityFormField label="Domaine Azure" field={azureDomain}>
                <Input {...azureDomain} disabled={readonly} bsSize="sm" />
                <InputAlert>
                    Assurez vous que le domaine Azure saisi :
                    <ul className="pb-0 mb-1">
                        <li>Existe bien en tant que Target correctement configurée dans ScriptRunner</li>
                        <li>A fait l'objet d'un consentement admin des permissions à l'application (<a href={tenantAdminConsentUrl}>{tenantAdminConsentUrl}</a>)</li>
                    </ul>
                </InputAlert>
            </EntityFormField>
            <EntityFormField label="Statut" field={status}>
                <Input {...status} type="select" bsSize="sm" readOnly disabled>
                    <option value={0}>Brouillon</option>
                    <option value={1}>Ready</option>
                    <option value={-1}>A supprimer</option>
                </Input>
            </EntityFormField>
            {id > 0 &&
                <>
                    <div className="entity-form-field">
                        <Label>Mise à jour de la connectivité</Label>
                        <div className="form-control-sm form-control bg-light">
                            <EntitySyncState value={connectivitySyncState.value} />
                        </div>
                        <div>
                            Forcer la mise à jour
                            <div className="d-inline-block ms-2">
                                <SwitchInput {...connectivitySyncStateForce} disabled={readonly || connectivitySyncStateForce.value} trueLabel="Oui" falseLabel="Non" />
                            </div>
                        </div>
                    </div>
                    <div className="entity-form-field">
                        <Label>Mise à jour des utilisateurs</Label>
                        <div className="form-control-sm form-control bg-light">
                            <EntitySyncState value={usersSyncState.value} />
                        </div>
                        <div>
                            Forcer la mise à jour
                            <div className="d-inline-block ms-2">
                                <SwitchInput {...usersSyncStateForce} disabled={readonly || usersSyncStateForce.value} trueLabel="Oui" falseLabel="Non" />
                            </div>
                        </div>
                    </div>
                    <div className="entity-form-field">
                        <Label>Mise à jour des agents</Label>
                        <div className="form-control-sm form-control bg-light">
                            <EntitySyncState value={agentsSyncState.value} />
                        </div>
                        <div>
                            Forcer la mise à jour
                            <div className="d-inline-block ms-2">
                                <SwitchInput {...agentsSyncStateForce} disabled={readonly || agentsSyncStateForce.value} trueLabel="Oui" falseLabel="Non" />
                            </div>
                        </div>
                    </div>
                    <div className="entity-form-field">
                        <Label>Mise à jour des comptes de resource</Label>
                        <div className="form-control-sm form-control bg-light">
                            <EntitySyncState value={resourceAccountsSyncState.value} />
                        </div>
                        <div>
                            Forcer la mise à jour
                            <div className="d-inline-block ms-2">
                                <SwitchInput {...resourceAccountsSyncStateForce} disabled={readonly || resourceAccountsSyncStateForce.value} trueLabel="Oui" falseLabel="Non" />
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}
