import { Link } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import { Env } from '../services/Env';
import { useMenuToggle } from '../services/MenuToggleContext';
import { useUser } from '../services/UserContext';
import { UserLevels } from '../services/UserLevels';
import { UserRoles } from '../services/UserRoles';
import './NavMenu.css';
import { NavMenuItemLink } from './NavMenuItemLink';

const menu = [
    {
        children: [
            { label: "Standards", icon: "telephone-inbound", to: "/autoattendants" },
            { label: "Groupes d'agents", icon: "people", to: "/callqueues" },
            { label: "Congés", icon: "calendar3", to: "/holidays" },
            { label: "Boites vocales", icon: "voicemail", to: "/sharedvoicemails" },
        ]
    },
    {
        label: "Administration",
        visible: (user) => user.role >= UserRoles.ADMIN,
        children: [
            { label: "Agents", icon: "person-workspace", to: "/admin/agents" },
            { label: "Comptes de ressource", icon: "robot", to: "/admin/resourceaccounts", visible: (user) => user.level >= UserLevels.ROOT },
            //{ separator: true },
            { label: "Tenants", icon: "building", to: "/admin/tenants", visible: (user) => user.level >= UserLevels.ROOT },
            { label: "Organisations", icon: "building", to: "/admin/orgunits", visible: (user) => user.level >= UserLevels.TENANT },
            { label: "Sous-organisations", icon: "building", to: "/admin/suborgunits", visible: (user) => user.level >= UserLevels.ORGUNIT },
            { label: "Utilisateurs", icon: "person", to: "/admin/users" },
            { label: "Synchronisations", icon: "arrow-repeat", to: "/admin/entitysyncs", visible: (user) => user.role >= UserRoles.SUPERADMIN }
        ]
    }
];

export const NavMenu = () => {
    const user = useUser();
    const { collapsed, isSmallWidthDevice, toggleCollapsed } = useMenuToggle();

    if (collapsed) return null;

    return (
        <div className="nav-menu overflow-auto position-fixed top-0 bottom-0 d-flex flex-column flex-shrink-0 text-white bg-dark">
            <Link to="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none px-3 py-4 primary" onClick={() => isSmallWidthDevice() && toggleCollapsed()}>
                <span className="fs-5">{Env.basePageTitle}</span>
            </Link>
            <div className="py-3 mb-auto menu-border-top">
                {menu.filter(o => !o.visible || o.visible(user)).map((group, index) =>
                    <div key={index}>
                        {group.label &&
                            <small className="p-3 text-muted text-uppercase d-block fw-bold">{group.label}</small>
                        }
                        <Nav className="flex-column px-3" navbar >
                            {group.children.filter(o => !o.visible || o.visible(user)).map((child, index) =>
                                <div key={index}>
                                    {child.separator && <hr />}
                                    {!child.separator &&
                                        <NavItem>
                                            <NavMenuItemLink to={child.to} className="text-white" icon={child.icon}>{child.label}</NavMenuItemLink>
                                        </NavItem>
                                    }
                                </div>
                            )}
                        </Nav>
                    </div>
                )}
            </div>
        </div>
    );
};
