import PropTypes from "prop-types";
import { mergeClassNames } from "../services/ComponentHelper";

export const Label = ({ htmlFor, children, className }) => {
    return (
        <label className={mergeClassNames(className, "form-label fw-bold600")} htmlFor={htmlFor}>
            {children}
        </label>
    );
}

Label.propTypes = {
    htmlFor: PropTypes.string,
    children: PropTypes.node.isRequired
};