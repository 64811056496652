import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const ConfirmModal = ({
    onToggle,
    onConfirm,
    isOpened,
    confirmationHeader,
    confirmationMessage,
    cancelLabel,
    confirmLabel,
    confirmButtonColor
}) => {

    const toggle = () => {
        onToggle && onToggle();
    }

    const confirm = (value) => {
        onConfirm && onConfirm(value);
    }

    return (
        <Modal fade={false} isOpen={isOpened} toggle={toggle}>
            <ModalHeader>{confirmationHeader}</ModalHeader>
            <ModalBody>
                {confirmationMessage}
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="link"
                    onClick={() => confirm(false)}>{cancelLabel}</Button>
                <Button
                    type="button"
                    color={confirmButtonColor}
                    className="ms-2"
                    onClick={() => confirm(true)}>{confirmLabel}</Button>
            </ModalFooter>
        </Modal>
    );
}

ConfirmModal.propTypes = {
    onToggle: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    isOpened: PropTypes.bool.isRequired,
    confirmationHeader: PropTypes.string,
    confirmationMessage: PropTypes.string,
    cancelLabel: PropTypes.string,
    confirmLabel: PropTypes.string,
    confirmButtonColor: PropTypes.string
};

ConfirmModal.defaultProps = {
    confirmationHeader: "Confirmation",
    confirmationMessage: "Etes-vous certain de vouloir effecter l'opération ?",
    confirmLabel: "Confirmer",
    confirmButtonColor: "+ Ajouter",
    cancelLabel: "Annuler"
};