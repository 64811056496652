
export const isString = (o) => {
    return typeof o === 'string';
};

export const isArray = (o) => {
    return Array.isArray(o);
};

export const isObject = (o) => {
    return o !== null && typeof o === 'object';
};