import * as yup from 'yup';
import { UserLevels } from '../../services/UserLevels';
import { UserRoles } from '../../services/UserRoles';

export const userSchema = yup.object().shape({
    upn: yup.string().required('Requis'),
    firstName: yup.string().required('Requis'),
    lastName: yup.string().required('Requis'),
    role: yup.number().integer().oneOf([UserRoles.READER, UserRoles.WRITER, UserRoles.ADMIN, UserRoles.SUPERADMIN], "S�lection invalide"),
    level: yup.number().integer().oneOf([UserLevels.SUBORGUNIT, UserLevels.ORGUNIT, UserLevels.TENANT, UserLevels.ROOT], "S�lection invalide"),
    enabled: yup.bool(),
    tenant: yup.object().nullable()
        .when('level', (v, s) => {
            if (v <= UserLevels.TENANT) return s.required('Requis');
            else return s;
        }),
    orgUnit: yup.object().nullable()
        .when('level', (v, s) => {
            if (v <= UserLevels.ORGUNIT) return s.required('Requis');
            else return s;
        }),
    subOrgUnit: yup.object().nullable()
        .when('level', (v, s) => {
            if (v === UserLevels.SUBORGUNIT) return s.required('Requis');
            else return s;
        }),
});