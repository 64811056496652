import { Form, Formik, useField } from 'formik';
import { useEffect, useState } from 'react';
import { Redirect } from "react-router-dom";
import { useUser } from '../services/UserContext';
import { UserRoles } from '../services/UserRoles';
import { ConfirmButton } from './ConfirmButton';
import { Debugger } from './Debugger';
import { EntitySyncState } from './EntitySyncState';
import { EntityAuditTrail } from './EntityAuditTrail';
import { Error } from './Error';
import { FormDataLossGuard } from './FormDataLossGuard';
import { Loader } from './Loader';
import { PageContent } from "./PageContent";
import { PageHeader } from "./PageHeader";
import { PageTitle } from "./PageTitle";

const EntityFormSyncTrail = () => {
    const [syncState] = useField("syncState");
    return (
        <div className="entity-sync-trail">
            <EntitySyncState value={syncState?.value} />
        </div>
    );
}

export const EntityForm = ({
    id,
    getEntity,
    newEntity,
    createEntity,
    updateEntity,
    removeEntity,
    listUrl,
    pageTitle,
    getEntityDisplay,
    canEdit,
    canDelete,
    validationSchema,
    validate,
    isSync,
    isAuditable,
    children
}) => {
    const [backToList, setBackToList] = useState(false);
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [writing, setWriting] = useState(false);
    const [error, setError] = useState(null);
    const user = useUser();

    useEffect(() => {

        if (id === "new") {

            setValue(newEntity({ user }));
            setLoading(false);
            setError(null);

        } else {

            const parsedId = parseInt(id, 10);
            if (isNaN(parsedId) || parsedId <= 0) {

                setLoading(false);
                setError({ message: `Invalid Id ${id}` });

            } else {

                const request = getEntity({ id: parsedId });
                request.then(data => {
                    if (!request.aborted) {
                        setValue(data);
                        setLoading(false);
                        setError(null);
                    }
                }).catch(error => {
                    if (!request.aborted) {
                        setValue(null);
                        setLoading(false);
                        setError(error);
                    }
                });

                return request.abort;
            }
        }

    }, [id, getEntity, newEntity, user]);

    const submit = async (values, actions) => {
        setWriting(true);

        let saveEntity = id > 0 ? updateEntity : createEntity;
        saveEntity({ value: values })
            .then(goBackToList)
            .catch(setError)
            .finally(() => setWriting(false));
    }

    const goBackToList = () => {
        setBackToList(true);
    }

    const remove = async () => {
        if (id > 0) {
            setWriting(true);

            removeEntity({ id })
                .then(goBackToList)
                .catch(setError)
                .finally(() => setWriting(false));
        }
    }

    const render = (content) => {
        return (
            <div>
                <PageHeader>
                    <PageTitle path={[{ label: pageTitle, url: listUrl }, (value ? value.id ? getEntityDisplay({ value }) : "Nouveau" : "")]} />
                </PageHeader>
                <PageContent>
                    {content}
                </PageContent>
            </div>
        );
    }

    const userCanEdit = () => {
        return user && user.role >= UserRoles.WRITER && (canEdit == null || canEdit({ user, value }));
    };

    const userCanDelete = () => {
        return user && user.role >= UserRoles.WRITER && (canDelete == null || canDelete({ user, value }));
    };

    if (backToList) {
        return <Redirect to={listUrl} />;
    } else if (loading) {
        return render(<Loader />);
    } else {

        const getDebuggerProps = (props) => {
            const { values, errors, touched, dirty, isValid, isSubmitting, id, submitCount } = props;
            return { values, errors, touched, dirty, isValid, isSubmitting, id, submitCount };
        };

        return render(
            <Formik initialValues={value} validationSchema={validationSchema} validate={validate} validateOnMount>
                {(props) => (
                    <Form>
                        {error && <Error error={error} />}
                        {value && (
                            <>
                                <Debugger title={"Form debug"}>
                                    <pre className="overflow-visible" style={{ fontSize: "0.6rem" }}>{JSON.stringify(getDebuggerProps(props), null, 2)}</pre>
                                </Debugger>
                                {children({ id, readonly: !userCanEdit() })}
                                {id > 0 && (isAuditable || isSync) && (
                                    <div className="text-end pt-3">
                                        {isAuditable && <EntityAuditTrail />}
                                        {isSync && <EntityFormSyncTrail />}
                                    </div>
                                )}
                                <div className="mt-5">
                                    {userCanEdit() && <FormDataLossGuard />}
                                    <div className="d-flex flex-row  justify-content-between">
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary me-4"
                                            onClick={goBackToList}>Retour</button>
                                        <div>
                                            {userCanDelete() && id > 0 &&
                                                <ConfirmButton
                                                    color="danger me-4"
                                                    disabled={writing}
                                                    onConfirm={(confirmed) => confirmed && remove()}>Supprimer</ConfirmButton>
                                            }
                                            {userCanEdit() &&
                                                <button
                                                    type="submit"
                                                    className="btn btn-success"
                                                    onClick={() => submit(props.values)}
                                                    disabled={/*(!props.dirty && props.submitCount === 0 && id > 0) ||*/ !props.isValid || props.isValidating || writing}>Enregistrer</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        );
    }
}

EntityForm.defaultProps = {
    isAuditable: true,
};