import { FieldArray, useField } from 'formik';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { Label } from '../Label';
import { MessagePrompt } from '../MessagePrompt';
import { AutoAttendantMenuOptions } from './AutoAttendantMenuOptions';

export const AutoAttendantMenu = ({ name, value, onChange, disabled }) => {

    const [playMenuOptionsPrompt, , playMenuOptionsPromptHelpers] = useField(name + ".playMenuOptionsPrompt");
    const [menuOptions] = useField(name + ".menuOptions");

    return (
        <>
            <div>
                <Label>Configurez le message d'accueil du menu</Label>
                <div className="card px-3 py-2">
                    <MessagePrompt
                        {...playMenuOptionsPrompt}
                        onChange={(value) => playMenuOptionsPromptHelpers.setValue(value)}
                        required={true}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className="mt-3">
                <Label>Définir les options du menu</Label>
                <FieldArray name={menuOptions.name}>
                    {(props) =>
                        <AutoAttendantMenuOptions
                            {...{ value: menuOptions.value, ...props }}
                            disabled={disabled}
                        />
                    }
                </FieldArray>
                <FieldErrorMessage name={menuOptions.name} />
            </div>
        </>
    );
}
