import { useField } from "formik";
import { useEffect, useState } from "react";
import * as yup from 'yup';
import { Api } from '../../services/Api';
import { useUser } from "../../services/UserContext";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { ImportEntitiesForm } from "../ImportEntitiesForm";
import { Label } from '../Label';
import { Loader } from "../Loader";
import { ScopeEditor } from "../ScopeEditor";

const ImportAgentsForm = () => {
    const [agents, setAgents] = useState(null);
    const [agentsLoading, setAgentsLoading] = useState(false);

    const [tenantId, setTenantId] = useState(null);

    const [scope] = useField('scope');
    const [upns, , upnsHelpers] = useField('upns');

    useEffect(() => {

        if (scope.value.tenant?.id != null) {

            if (tenantId !== scope.value.tenant.id) {
                setAgentsLoading(true);
                const request = Api.getImportableAgents({
                    tenantId: scope.value.tenant.id
                });
                request.then(data => {
                    if (!request.aborted) {
                        setAgents(data);
                        upnsHelpers.setValue([]);
                    }
                }).catch(error => {
                    if (!request.aborted) {
                        setAgents(null);
                        upnsHelpers.setValue([]);
                        alert(error.msg);
                    }
                }).finally(() => {
                    setAgentsLoading(false);
                });
                setTenantId(scope.value.tenant.id);
            }

        } else if (tenantId != null) {
            setAgents(null);
            upnsHelpers.setValue([]);
            setTenantId(null);
        }

    }, [scope.value.tenant, agents, tenantId, setTenantId, upnsHelpers]);

    var toggle = (e) => {
        const upn = e.target.value;
        if (upns.value.indexOf(upn) >= 0) {
            upnsHelpers.setValue([...upns.value].filter(o => o !== upn))
        } else {
            upnsHelpers.setValue([...upns.value, upn]);
        }
    };

    var toggleAll = () => {
        if (!isAllSelected()) {
            upnsHelpers.setValue(agents.map(o => o.upn));
        } else {
            upnsHelpers.setValue([]);
        }
    };

    var isAllSelected = () => {
        return agents != null && upns.value.length === agents.length;
    };

    var isSelected = (agent) => {
        return upns.value.indexOf(agent.upn) >= 0;
    };

    return (
        <>
            <ScopeEditor />
            <div className="entity-form-field">
                <div className="d-flex">
                    <Label className="me-auto">Sélection des agents à importer</Label>
                    <button className="btn btn-sm btn-link no-focus-outline p-0 pb-2 lh-1" onClick={toggleAll}>{isAllSelected() ? "Tout désélectionner" : "Tout sélectionner"}</button>
                </div>
                <div className="list-group">
                    {agentsLoading &&
                        <label className="list-group-item">
                            <Loader delay={100} className="p-0" />
                        </label>
                    }
                    {!agentsLoading && agents && agents.sort((a, b) => a.name.localeCompare(b.name)).map((agent, index) =>
                        <label className={`list-group-item ${isSelected(agent) ? "list-group-item-primary" : ""}`} key={agent.upn}>
                            <input className="form-check-input me-3" type="checkbox" name="agents" value={agent.upn} checked={isSelected(agent)} onChange={(e) => toggle(e)} />
                            <b>{agent.name}</b> ({agent.upn})
                        </label>
                    )}
                    {!agentsLoading && (!agents || agents.length === 0) &&
                        <label className="list-group-item list-group-item-disabled">
                            <span className="text-muted">Aucun élément</span>
                        </label>
                    }
                </div>
                <FieldErrorMessage name="upns" />
            </div>
        </>
    );
}

export const ImportAgents = () => {
    const user = useUser();
    const value = {
        scope: user.scope,
        upns: []
    };
    const validationSchema = yup.object().shape({
        scope: yup.object().shape({
            tenant: yup.object().nullable().required('Requis'),
            orgUnit: yup.object().nullable(),
            subOrgUnit: yup.object().nullable(),
        }),
        upns: yup.array().of(yup.string().required('Requis')).required('Requis').min(1, "Au moins un agent doit être sélectionné")
    });

    return (
        <ImportEntitiesForm
            pageTitle="Agents"
            value={value}
            validationSchema={validationSchema}
            listUrl="/admin/agents/"
            save={(value) => Api.importAgents(value)}>
            {(props) => <ImportAgentsForm {...props} />}
        </ImportEntitiesForm>
    );
}
