import { useField } from "formik";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import { AudioFile } from './AudioFile';
import { EntityFormField } from "./EntityFormField";
import { RadioList } from './RadioList';

export const MessagePrompt = ({
    name,
    value,
    required,
    onChange,
    disabled
}) => {
    const [activeType, , activeTypeHelpers] = useField(name + ".activeType");
    const [audioFile, , audioFileHelpers] = useField(name + ".audioFile");
    const [textToSpeech] = useField(name + ".textToSpeech");

    let options = [
        {
            value: 1,
            label: "Lire un fichier audio",
            info: "Vous êtes responsable de l'effacement indépendant et de la sécurisation de tous les droits et autorisations nécessaires pour utiliser n'importe quel fichier audio ou de musique avec votre service Microsoft Teams. Cela inclut la propriété intellectuelle et d'autres droits dans la musique, les effets sonores, l'audio, les marques, les noms et tout autre contenu du fichier audio de tous les détenteurs de droits pertinents, y compris les artistes, les acteurs, les acteurs, les acteurs,  les éditeurs, les auteurs, les compositeurs, les étiquettes d'enregistrements, les éditeurs de musique, les unions, les organisations de droits, les organisations de gestion collective et tous les autres tiers qui possèdent, contrôlent ou concèdent les droits d'auteur de musique, les effets sonores, l'audio et d'autres droits de propriété intellectuelle.",
            children: () => {
                return (
                    <EntityFormField field={audioFile}>
                        <AudioFile {...audioFile}
                            disabled={disabled}
                            onChange={(value) => {
                                audioFileHelpers.setTouched(true);
                                audioFileHelpers.setValue(value);
                            }} />
                    </EntityFormField>
                );
            }
        },
        {
            value: 2,
            label: "Lire un message textuel",
            info: "Si vous choisissez cette option, entrez le texte que le système doit lire (entre 10 et 1000 caractères).",
            children: () => {
                return (
                    <EntityFormField field={textToSpeech}>
                        <Input {...textToSpeech}
                            disabled={disabled}
                            bsSize="sm"
                            type="textarea"
                            placeholder="Par exemple : Bienvenue chez Example"
                            value={(textToSpeech.value || '')} />
                        <div className="small mt-1 text-muted lh-1">Le message d'accueil doit comporter entre 10 et 1000 caractères.</div>
                    </EntityFormField>
                );
            }
        }
    ];

    if (!required) {
        options.unshift({
            value: 0,
            label: "Ne pas lire de message d'accueil"
        });
    }

    return (
        <RadioList
            {...activeType}
            disabled={disabled}
            options={options}
            onChange={(value) => activeTypeHelpers.setValue(+value)} />
    );
}

MessagePrompt.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({
        activeType: PropTypes.number.isRequired,
        textToSpeech: PropTypes.string,
        audioFile: PropTypes.shape({
            name: PropTypes.string,
            tmpId: PropTypes.string
        })
    }),
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};