import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useScope } from '../../services/ScopeContext';
import { CallQueueEditor } from '../callQueues/CallQueueEditor';
import { callQueueSchema } from './../callQueues/CallQueueValidation';

export const CreateCallQueueModal = ({ isOpen, toggle, title, onChange }) => {
    const scope = useScope();

    const newThresholdOverrun = () => {
        return {
            action: 0,
            agent: null,
            callQueue: null,
            autoAttendant: null,
            sharedVoicemail: null,
            sharedVoicemailPrompt: null,
            enableSharedVoicemailTranscription: false,
            externalPstn: null,
        };
    };

    const newCallQueue = () => {
        return {
            scope: scope,
            name: '',
            language: 'fr-FR',
            hasWelcomeMusic: false,
            welcomeMusic: null,
            useDefaultMusicOnHold: true,
            musicOnHold: null,
            agents: [],
            routingMethod: 0,
            presenceBasedRouting: false,
            conferenceMode: false,
            allowOptOut: false,
            agentAlertTime: 30,
            overflowThreshold: 50,
            overflowThresholdOverrun: newThresholdOverrun(),
            timeoutThreshold: 1200,
            timeoutThresholdOverrun: newThresholdOverrun(),
        };
    };

    const [value] = useState(newCallQueue());

    const save = (values) => {
        onChange && onChange(values);
    };

    if (!value) return null;

    return (
        <Formik initialValues={value} validationSchema={callQueueSchema} validateOnMount>
            {(props) => (
                <Modal
                    isOpen={isOpen}
                    toggle={toggle}
                    size="xl">
                    <ModalHeader>{title}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <CallQueueEditor id={"new"} hideScopeEditor />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            className="btn btn-link"
                            onClick={toggle}>Annuler</button>
                        <button
                            type="button"
                            className="btn btn-primary ms-2"
                            disabled={!props.isValid}
                            onClick={() => save(props.values)}>Enregistrer</button>
                    </ModalFooter>
                </Modal>
            )}
        </Formik>
    );
};