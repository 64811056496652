import { useField } from "formik";
import PropTypes from "prop-types";

export const FieldErrorMessage = ({ name, touchedOnly }) => {
    const [, meta] = useField(name);

    if (typeof meta.error === 'string' && meta.error.length > 0) {
        if (!touchedOnly || meta.touched) {
            return <div className="invalid-feedback">{meta.error}</div>;
        }
    }

    return null;
}

FieldErrorMessage.propTypes = {
    name: PropTypes.string.isRequired,
    touchedOnly: PropTypes.bool
};

FieldErrorMessage.defaultProps = {
    touchedOnly: false
};