import { useField } from 'formik';
import { useEffect } from 'react';
import { Input } from 'reactstrap';
import { Api } from '../../services/Api';
import { useUser } from '../../services/UserContext';
import { UserLevels } from '../../services/UserLevels';
import { Autocomplete } from '../Autocomplete';
import { EntityFormField } from '../EntityFormField';
import { Label } from '../Label';

export const UserScopeEditor = ({ readonly }) => {
    const user = useUser();
    const [level, , levelHelpers] = useField('level');
    const [tenant, , tenantHelpers] = useField('tenant');
    const [orgUnit, , orgUnitHelpers] = useField('orgUnit');
    const [subOrgUnit, , subOrgUnitHelpers] = useField('subOrgUnit');


    useEffect(() => {
        if (level.value > UserLevels.SUBORGUNIT && subOrgUnit.value) subOrgUnitHelpers.setValue(null);
        if (level.value > UserLevels.ORGUNIT && orgUnit.value) orgUnitHelpers.setValue(null);
        if (level.value > UserLevels.TENANT && tenant.value) tenantHelpers.setValue(null);
        if (!orgUnit.value && subOrgUnit.value) subOrgUnitHelpers.setValue(null);
        if (!tenant.value && orgUnit.value) {
            orgUnitHelpers.setValue(null);
            subOrgUnitHelpers.setValue(null);
        }
    }, [level.value, tenant.value, tenantHelpers, orgUnit.value, orgUnitHelpers, subOrgUnit.value, subOrgUnitHelpers]);

    return (
        <div className="entity-form-field">
            <Label>Emplacement</Label>
            <div className="card shadow-sm px-2 pb-3">
                <div className="container-fluid px-0">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
                        <div className="col">
                            <div className="pt-2 px-2 flex-fill">
                                <EntityFormField label="Niveau" field={level}>
                                    <Input {...level}
                                        type="select"
                                        bsSize="sm"
                                        disabled={readonly}
                                        placeholder="Choisissez une valeur"
                                        onChange={(e) => levelHelpers.setValue(+(e.target.value))}>
                                        {user.level >= UserLevels.SUBORGUNIT && <option value={UserLevels.SUBORGUNIT}>Sous-organisation</option>}
                                        {user.level >= UserLevels.ORGUNIT && <option value={UserLevels.ORGUNIT}>Organisation</option>}
                                        {user.level >= UserLevels.TENANT && <option value={UserLevels.TENANT}>Tenant</option>}
                                        {user.level >= UserLevels.ROOT && <option value={UserLevels.ROOT}>Racine</option>}
                                    </Input>
                                </EntityFormField>
                            </div>
                        </div>
                        {level.value <= UserLevels.TENANT &&
                            <div className="col">
                                <div className="pt-2 px-2 flex-fill">
                                    <EntityFormField label="Tenant" field={tenant}>
                                        <Autocomplete
                                            {...tenant}
                                            disabled={readonly || user.scope.tenant}
                                            getMatches={async (params) => (await Api.getTenants(params)).values}
                                            size="sm"
                                            onChange={(value) => {
                                                tenantHelpers.setTouched(true);
                                                tenantHelpers.setValue(value);
                                            }} />
                                    </EntityFormField>
                                </div>
                            </div>
                        }
                        {level.value <= UserLevels.ORGUNIT &&
                            <div className="col">
                                <div className="pt-2 px-2 flex-fill">
                                    <EntityFormField label="Organisation" field={orgUnit}>
                                        <Autocomplete
                                            {...orgUnit}
                                            disabled={readonly || user.scope.orgUnit || tenant.value == null}
                                            getMatches={async (params) => (await Api.getOrgUnits({ ...params, tenantId: tenant.value?.id })).values}
                                            size="sm"
                                            onChange={(value) => {
                                                orgUnitHelpers.setTouched(true);
                                                orgUnitHelpers.setValue(value);
                                            }} />
                                    </EntityFormField>
                                </div>
                            </div>
                        }
                        {level.value === UserLevels.SUBORGUNIT &&
                            <div className="col">
                                <div className="pt-2 px-2 flex-fill">
                                    <EntityFormField label="Sous-organisation" field={subOrgUnit}>
                                        <Autocomplete
                                            {...subOrgUnit}
                                            disabled={readonly || user.scope.subOrgUnit || orgUnit.value == null}
                                            getMatches={async (params) => (await Api.getSubOrgUnits({ ...params, tenantId: tenant.value?.id, orgUnitId: orgUnit.value?.id })).values}
                                            size="sm"
                                            onChange={(value) => {
                                                subOrgUnitHelpers.setTouched(true);
                                                subOrgUnitHelpers.setValue(value);
                                            }} />
                                    </EntityFormField>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}