import { useField } from "formik";
import { useEffect, useState } from "react";
import * as yup from 'yup';
import { Api } from '../../services/Api';
import { useUser } from "../../services/UserContext";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { ImportEntitiesForm } from "../ImportEntitiesForm";
import { Label } from '../Label';
import { Loader } from "../Loader";
import { ScopeEditor } from "../ScopeEditor";
import { ResourceAccountThumb } from "./ResourceAccountThumb";

const ImportResourceAccountsForm = () => {
    const [resourceAccounts, setResourceAccounts] = useState(null);
    const [resourceAccountsLoading, setResourceAccountsLoading] = useState(false);

    const [tenantId, setTenantId] = useState(null);

    const [scope] = useField('scope');
    const [upns, , upnsHelpers] = useField('upns');

    useEffect(() => {

        if (scope.value.tenant?.id != null) {

            if (tenantId !== scope.value.tenant.id) {
                setResourceAccountsLoading(true);
                const request = Api.getImportableResourceAccounts({
                    tenantId: scope.value.tenant.id
                });
                request.then(data => {
                    if (!request.aborted) {
                        setResourceAccounts(data);
                        upnsHelpers.setValue([]);
                    }
                }).catch(error => {
                    if (!request.aborted) {
                        setResourceAccounts(null);
                        upnsHelpers.setValue([]);
                        alert(error.msg);
                    }
                }).finally(() => {
                    setResourceAccountsLoading(false);
                });
                setTenantId(scope.value.tenant.id);
            }

        } else if (tenantId != null) {
            setResourceAccounts(null);
            upnsHelpers.setValue([]);
            setTenantId(null);
        }

    }, [scope.value.tenant, resourceAccounts, tenantId, setTenantId, upnsHelpers]);

    var toggle = (e) => {
        const upn = e.target.value;
        if (upns.value.indexOf(upn) >= 0) {
            upnsHelpers.setValue([...upns.value].filter(o => o !== upn))
        } else {
            upnsHelpers.setValue([...upns.value, upn]);
        }
    };

    var toggleAll = () => {
        if (!isAllSelected()) {
            upnsHelpers.setValue(resourceAccounts.map(o => o.upn));
        } else {
            upnsHelpers.setValue([]);
        }
    };

    var isAllSelected = () => {
        return resourceAccounts != null && upns.value.length === resourceAccounts.length;
    };

    var isSelected = (resourceAccount) => {
        return upns.value.indexOf(resourceAccount.upn) >= 0;
    };

    return (
        <>
            <ScopeEditor />
            <div className="entity-form-field">
                <div className="d-flex">
                    <Label className="me-auto">Sélection des comptes de ressource à importer</Label>
                    <button className="btn btn-sm btn-link no-focus-outline p-0 pb-2 lh-1" onClick={toggleAll}>{isAllSelected() ? "Tout désélectionner" : "Tout sélectionner"}</button>
                </div>
                <div className="list-group">
                    {resourceAccountsLoading &&
                        <label className="list-group-item">
                            <Loader delay={100} className="p-0" />
                        </label>
                    }
                    {!resourceAccountsLoading && resourceAccounts && resourceAccounts.sort((a, b) => a.name.localeCompare(b.name)).map((resourceAccount, index) =>
                        <label className={`d-flex list-group-item ${isSelected(resourceAccount) ? "list-group-item-primary" : ""}`} key={resourceAccount.upn}>
                            <input className="form-check-input me-3" type="checkbox" name="resourceAccounts" value={resourceAccount.upn} checked={isSelected(resourceAccount)} onChange={(e) => toggle(e)} />
                            <ResourceAccountThumb value={resourceAccount} />
                        </label>
                    )}
                    {!resourceAccountsLoading && (!resourceAccounts || resourceAccounts.length === 0) &&
                        <label className="list-group-item list-group-item-disabled">
                            <span className="text-muted">Aucun élément</span>
                        </label>
                    }
                </div>
                <FieldErrorMessage name="upns" />
            </div>
        </>
    );
}

export const ImportResourceAccounts = () => {
    const user = useUser();
    const value = {
        scope: user.scope,
        upns: []
    };
    const validationSchema = yup.object().shape({
        scope: yup.object().shape({
            tenant: yup.object().nullable().required('Requis'),
            orgUnit: yup.object().nullable(),
            subOrgUnit: yup.object().nullable(),
        }),
        upns: yup.array().of(yup.string().required('Requis')).required('Requis').min(1, "Au moins un élément doit être sélectionné")
    });

    return (
        <ImportEntitiesForm
            pageTitle="Comptes de ressource"
            value={value}
            validationSchema={validationSchema}
            listUrl="/admin/resourceAccounts/"
            save={(value) => Api.importResourceAccounts(value)}>
            {(props) => <ImportResourceAccountsForm {...props} />}
        </ImportEntitiesForm>
    );
}