import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { CallTargetLookup } from '../CallTargetLookup';
import { EntityFormField } from '../EntityFormField';
import { Info } from '../Info';
import { Label } from '../Label';
import { MessagePrompt } from '../MessagePrompt';
import { PhonePicker } from '../PhonePicker';
import { RadioList } from '../RadioList';
import { SwitchInput } from '../SwitchInput';
import { AutoAttendantMenu } from './AutoAttendantMenu';
import { CreateCallQueueModal } from './CreateCallQueueModal';

export const AutoAttendantCallFlow = ({ name, onChange, disabled }) => {
    const [greetings, , greetingsHelpers] = useField(name + ".greetings");
    const [action, , actionHelpers] = useField(name + ".action");
    const [agent, , agentHelpers] = useField(name + ".agent");
    const [callQueue, , callQueueHelpers] = useField(name + ".callQueue");
    const [autoAttendant, , autoAttendantHelpers] = useField(name + ".autoAttendant");
    const [sharedVoicemail, , sharedVoicemailHelpers] = useField(name + ".sharedVoicemail");
    const [enableSharedVoicemailSystemPromptSuppression, , enableSharedVoicemailSystemPromptSuppressionHelpers] = useField(name + ".enableSharedVoicemailSystemPromptSuppression");
    const [enableSharedVoicemailTranscription, , enableSharedVoicemailTranscriptionHelpers] = useField(name + ".enableSharedVoicemailTranscription");
    const [externalPstn, , externalPstnHelpers] = useField(name + ".externalPstn");
    const [menu, , menuHelpers] = useField(name + ".menu");

    const [createCallQueueModalOpened, setCreateCallQueueModalOpened] = useState(false);

    const onCreateCallQueueModalConfirm = (result) => {
        callQueueHelpers.setValue({ name: result.name, create: result });
        setCreateCallQueueModalOpened(false);
    };

    useEffect(() => {

        // FIXME : doing the job twice, here and in changeAction function

        if (agent.value != null && action.value !== 1) {
            agentHelpers.setValue(null);
        }

        if (callQueue.value != null && action.value !== 2) {
            callQueueHelpers.setValue(null);
        }

        if (autoAttendant.value != null && action.value !== 3) {
            autoAttendantHelpers.setValue(null);
        }

        if (sharedVoicemail.value != null && action.value !== 4) {
            sharedVoicemailHelpers.setValue(null);
        }

        if (enableSharedVoicemailTranscription.value && action.value !== 4) {
            enableSharedVoicemailTranscriptionHelpers.setValue(false);
        }

        if (enableSharedVoicemailSystemPromptSuppression.value && action.value !== 4) {
            enableSharedVoicemailSystemPromptSuppressionHelpers.setValue(false);
        }

        if (enableSharedVoicemailTranscription.value == null && action.value === 4) {
            enableSharedVoicemailTranscriptionHelpers.setValue(false);
        }

        if (enableSharedVoicemailSystemPromptSuppression.value == null && action.value === 4) {
            enableSharedVoicemailSystemPromptSuppressionHelpers.setValue(false);
        }

        if (externalPstn.value != null && externalPstn.value.length > 0 && action.value !== 5) {
            externalPstnHelpers.setValue(null);
        }

        if (menu.value != null && action.value !== 6) {
            menuHelpers.setValue(null);
        }

        if (action.value === 6 && menu.value == null) {
            menuHelpers.setValue({
                playMenuOptionsPrompt: {
                    activeType: 1,
                    audioFile: null,
                    textToSpeach: ''
                },
                menuOptions: [{ dtmfResponse: 0, action: 2 }]
            });
        }

    }, [action.value,
    agent.value, agentHelpers,
    callQueue.value, callQueueHelpers,
    autoAttendant.value, autoAttendantHelpers,
    sharedVoicemail.value, sharedVoicemailHelpers,
    externalPstn.value, externalPstnHelpers,
    enableSharedVoicemailSystemPromptSuppression.value, enableSharedVoicemailSystemPromptSuppressionHelpers,
    enableSharedVoicemailTranscription.value, enableSharedVoicemailTranscriptionHelpers,
    menu.value, menuHelpers]);


    const setValue = (helpers, value) => {
        helpers.setValue(value);
        // quick fix to trigger validation, otherwise validation is done on next compute
        setTimeout(() => helpers.setTouched(true));
    };

    const options = [
        {
            value: 2,
            label: "Transférer vers un groupe d'agents",
            children: () => {
                return (
                    <EntityFormField field={callQueue}>
                        <div className="d-flex">
                            <div className="flex-fill">
                                <CallTargetLookup
                                    {...callQueue}
                                    placeholder="Rechercher un groupe d'agents"
                                    type="callQueue"
                                    onChange={(o) => setValue(callQueueHelpers, o)}
                                    disabled={disabled}
                                />
                            </div>
                            <div className="ps-4">
                                {callQueue.value == null &&
                                    (
                                        <>
                                            <Button
                                                type="button"
                                                size="sm"
                                                disabled={disabled}
                                                onClick={() => setCreateCallQueueModalOpened(true)}>Créer</Button>
                                            {createCallQueueModalOpened &&
                                                <CreateCallQueueModal
                                                    title="Nouveau groupe d'appel"
                                                    isOpen={createCallQueueModalOpened}
                                                    toggle={() => setCreateCallQueueModalOpened(!createCallQueueModalOpened)}
                                                    onChange={onCreateCallQueueModalConfirm} />
                                            }
                                        </>
                                    )}
                            </div>
                        </div>
                    </EntityFormField>
                );
            }
        },
        {
            value: 3,
            label: "Transférer vers un standard",
            children: () => {
                return (
                    <EntityFormField field={autoAttendant}>
                        <CallTargetLookup
                            {...autoAttendant}
                            placeholder="Rechercher un standard"
                            type="autoAttendant"
                            onChange={(o) => setValue(autoAttendantHelpers, o)}
                            disabled={disabled}
                        />
                    </EntityFormField>
                );
            }
        },
        {
            value: 1,
            label: "Transférer vers une personne dans l'organisation",
            children: () => {
                return (
                    <EntityFormField field={agent}>
                        <CallTargetLookup
                            {...agent}
                            placeholder="Rechercher un agent"
                            type="agent"
                            onChange={(o) => setValue(agentHelpers, o)}
                            disabled={disabled}
                        />
                    </EntityFormField>
                );
            }
        },
        {
            value: 5,
            label: "Transférer vers un numéro de téléphone externe",
            children: () => {
                return (
                    <EntityFormField field={externalPstn}>
                        <PhonePicker
                            {...externalPstn}
                            onChange={(o) => setValue(externalPstnHelpers, o)}
                            disabled={disabled}
                        />
                    </EntityFormField>
                );
            }
        },
        {
            value: 4,
            label: "Transférer vers une messagerie vocale",
            children: () => {
                return (
                    <div className="card px-3 py-3">
                        <EntityFormField field={sharedVoicemail}>
                            <CallTargetLookup
                                {...sharedVoicemail}
                                placeholder="Rechercher une messagerie vocale"
                                type="sharedVoicemail"
                                onChange={(o) => setValue(sharedVoicemailHelpers, o)}
                                disabled={disabled}
                            />
                        </EntityFormField>
                        <div className="entity-form-field">
                            <Row>
                                <Col lg="4">
                                    <Label>Transcription</Label>
                                    <SwitchInput
                                        {...enableSharedVoicemailTranscription}
                                        trueLabel="Activée"
                                        falseLabel="Désactivée"
                                        disabled={disabled}
                                    />
                                </Col>
                                <Col lg="8">
                                    <Label>
                                        Ignorer le message système de messagerie vocale
                                        <Info id={name + "enableSharedVoicemailTranscription"}>
                                            L'activation de cette option ignorera le message vocal « Veuillez laisser un message après la tonalité.
                                            Une fois que vous avez terminé, raccrochez ou appuyez sur la touche # pour obtenir plus d'options. »
                                            Les appelants entendront uniquement le signal sonore et pourront commencer à enregistrer leur message.
                                        </Info>
                                    </Label>
                                    <SwitchInput
                                        {...enableSharedVoicemailSystemPromptSuppression}
                                        trueLabel="Activée"
                                        falseLabel="Désactivée"
                                        disabled={disabled}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                );
            }
        },
        {
            value: 6,
            label: "Transférer vers un SVI (Serveur Vocal Interactif)",
            children: () => {
                return (
                    <div className="card px-3 py-3">
                        <AutoAttendantMenu
                            name={name + ".menu"}
                            disabled={disabled} />
                    </div>
                );
            }
        },
        {
            value: 0,
            label: "Raccrocher"
        },
    ];

    const changeAction = (value) => {
        setValue(actionHelpers, value);

        switch (value) {
            case 0: // Disconnect
                break;
            case 1: // Agent
                setValue(agentHelpers, null);
                break;
            case 2: // CallQueue
                setValue(callQueueHelpers, null);
                break;
            case 3: // AutoAttendant
                setValue(autoAttendantHelpers, null);
                break;
            case 4: // SharedVoicemail
                setValue(sharedVoicemailHelpers, null);
                setValue(enableSharedVoicemailSystemPromptSuppressionHelpers, false);
                setValue(enableSharedVoicemailTranscriptionHelpers, false);
                break;
            case 5: // ExternalPSTN
                setValue(externalPstnHelpers, null);
                break;
            case 6: // PlayMenu
                setValue(menuHelpers, null);
                break;
            default: break;
        }
    };


    return (
        <>
            <EntityFormField label="Lire d'abord le message d'accueil" field={greetings}>
                <div className="card px-3 py-2">
                    <MessagePrompt
                        {...greetings}
                        onChange={(value) => greetingsHelpers.setValue(value)}
                        disabled={disabled}
                    />
                </div>
            </EntityFormField>
            <EntityFormField label="Acheminement de l'appel" field={action}>
                <div className="card px-3 py-2">
                    <RadioList
                        {...action}
                        options={options}
                        onChange={(value) => changeAction(+value)}
                        disabled={disabled}
                    />
                </div>
            </EntityFormField>
        </>
    );
}
