import { useField } from 'formik';
import { useEffect } from 'react';
import { Api } from './../services/Api';
import { useUser } from './../services/UserContext';
import { Autocomplete } from './Autocomplete';
import { FieldErrorMessage } from './FieldErrorMessage';
import { Label } from './Label';

export const ScopeEditor = ({ readonly }) => {
    const user = useUser();
    const [tenant, , tenantHelpers] = useField('scope.tenant');
    const [orgUnit, , orgUnitHelpers] = useField('scope.orgUnit');
    const [subOrgUnit, , subOrgUnitHelpers] = useField('scope.subOrgUnit');

    useEffect(() => {
        if (!orgUnit.value && subOrgUnit.value) subOrgUnitHelpers.setValue(null);
        if (!tenant.value && orgUnit.value) {
            orgUnitHelpers.setValue(null);
            subOrgUnitHelpers.setValue(null);
        }

    }, [tenant.value, orgUnit.value, subOrgUnit.value, tenantHelpers, orgUnitHelpers, subOrgUnitHelpers]);

    const shouldDisplayTenants = () => {
        if (user.scope.tenant) return false;
        else if (readonly && tenant.value == null) return false;
        else return true;
    };

    const shouldDisplayOrgUnits = () => {
        if (user.scope.orgUnit) return false;
        else if (readonly && orgUnit.value == null) return false;
        else if (!readonly && tenant.value && !tenant.value.hasChildren) return false;
        else return true;
    };

    const shouldDisplaySubOrgUnits = () => {
        if (!shouldDisplayOrgUnits()) return false;
        else if (user.scope.subOrgUnit) return false;
        else if (readonly && subOrgUnit.value == null) return false;
        else if (!readonly && orgUnit.value && !orgUnit.value.hasChildren) return false;
        else return true;
    };

    if (!shouldDisplayTenants() && !shouldDisplayOrgUnits() && !shouldDisplaySubOrgUnits()) return null;

    return (
        <div className="entity-form-field">
            <Label>Emplacement</Label>
            <div className="card shadow-sm px-2 pb-3">
                <div className="container-fluid px-2">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                        {shouldDisplayTenants() &&
                            <div className="col">
                                <div className="pt-2 flex-fill">
                                    <Label>Tenant</Label>
                                    <Autocomplete
                                        {...tenant}
                                        disabled={readonly}
                                        getMatches={async (params) => (await Api.getTenants(params)).values}
                                        size="sm"
                                        onChange={(value) => {
                                            tenantHelpers.setTouched(true);
                                            tenantHelpers.setValue(value);
                                        }} />
                                    <FieldErrorMessage name={tenant.name} />
                                </div>
                            </div>
                        }
                        {shouldDisplayOrgUnits() &&
                            <div className="col">
                                <div className="pt-2 flex-fill">
                                    <Label>Organisation</Label>
                                    <Autocomplete
                                        {...orgUnit}
                                        disabled={readonly || tenant.value == null}
                                        getMatches={async (params) => (await Api.getOrgUnits({ ...params, tenantId: tenant.value?.id })).values}
                                        size="sm"
                                        onChange={(value) => {
                                            orgUnitHelpers.setTouched(true);
                                            orgUnitHelpers.setValue(value);
                                        }} />
                                    <FieldErrorMessage name={orgUnit.name} />
                                </div>
                            </div>
                        }
                        {shouldDisplaySubOrgUnits() &&
                            <div className="col">
                                <div className="pt-2 flex-fill">
                                    <Label htmlFor="name">Sous-organisation</Label>
                                    <Autocomplete
                                        {...subOrgUnit}
                                        disabled={readonly || orgUnit.value == null}
                                        getMatches={async (params) => (await Api.getSubOrgUnits({ ...params, tenantId: tenant.value?.id, orgUnitId: orgUnit.value?.id })).values}
                                        size="sm"
                                        onChange={(value) => {
                                            subOrgUnitHelpers.setTouched(true);
                                            subOrgUnitHelpers.setValue(value);
                                        }} />
                                    <FieldErrorMessage name={subOrgUnit.name} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}