import { createContext, useContext } from 'react';

const ScopeContext = createContext(null);

export const ScopeProvider = ({ children, value }) =>
    <ScopeContext.Provider value={value}>{children}</ScopeContext.Provider>;

export const useScope = () => {
    const context = useContext(ScopeContext);
    if (context == null) {
        throw new Error('useScope must be used within a ScopeContext');
    }
    return context;
}