import PropTypes from "prop-types";

export const SwitchInput = ({
    name,
    value,
    trueLabel,
    falseLabel,
    onChange,
    onBlur,
    disabled,
    ...rest
}) => {

    const internalOnChanged = (event) => {
        onChange && onChange(event);
    }

    const internalOnBlur = (event) => {
        onBlur && onBlur(event);
    }

    return (
        <div className="form-check form-switch">
            <input className="form-check-input"
                type="checkbox"
                name={name}
                id={name}
                checked={value}
                onBlur={internalOnBlur}
                onChange={internalOnChanged}
                disabled={disabled}
            //onChange={onValueChanged}
            //{...rest}
            />
            <label className="form-check-label user-select-none" htmlFor={name}>
                {value ? trueLabel : falseLabel}
            </label>
        </div>
    );
}

SwitchInput.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
    ]),
    onChange: PropTypes.func.isRequired,
    trueLabel: PropTypes.string,
    falseLabel: PropTypes.string,
};

SwitchInput.defaultProps = {
    trueLabel: 'Activé',
    falseLabel: 'Désactivé',
};