import { useParams } from 'react-router-dom';
import { Api } from '../../services/Api';
import { OrgUnitEditor } from './OrgUnitEditor';
import { orgUnitSchema } from './OrgUnitValidation';
import { EntityForm } from '../EntityForm';
import { UserLevels } from '../../services/UserLevels';
import { UserRoles } from '../../services/UserRoles';

export function OrgUnitDetail() {
    const { id } = useParams();
    const newEntity = ({ user }) => {
        return {
            name: '',
            tenant: user.scope.tenant,
        };
    }

    return (
        <EntityForm
            id={id}
            pageTitle="Organisations"
            validationSchema={orgUnitSchema}
            listUrl="/admin/orgunits/"
            getEntityDisplay={({ value }) => value?.name || ''}
            newEntity={newEntity}
            getEntity={({ id }) => Api.getOrgUnit(id)}
            createEntity={({ value }) => Api.createOrgUnit(value)}
            updateEntity={({ value }) => Api.updateOrgUnit(value.id, value)}
            removeEntity={({ id }) => Api.removeOrgUnit(id)}
            canEdit={({ user }) => user && user.role >= UserRoles.ADMIN && user.level >= UserLevels.TENANT}
            canDelete={({ user }) => user && user.role >= UserRoles.ADMIN && user.level >= UserLevels.TENANT}>
            {(props) => <OrgUnitEditor {...props} />}
        </EntityForm>
    );
}