import { Route, Switch } from 'react-router';
import { AgentDetail } from './components/agents/AgentDetail';
import { Agents } from './components/agents/Agents';
import { Authenticate } from './components/Authenticate';
import { AutoAttendantDetail } from './components/autoAttendants/AutoAttendantDetail';
import { AutoAttendants } from './components/autoAttendants/AutoAttendants';
import { CallQueueDetail } from './components/callQueues/CallQueueDetail';
import { CallQueues } from './components/callQueues/CallQueues';
import { OrgUnitDetail } from './components/orgUnits/OrgUnitDetail';
import { OrgUnits } from './components/orgUnits/OrgUnits';
import { SubOrgUnitDetail } from './components/subOrgUnits/SubOrgUnitDetail';
import { SubOrgUnits } from './components/subOrgUnits/SubOrgUnits';
import { Error } from './components/Error';
import { HolidayDetail } from './components/holidays/HolidayDetail';
import { Holidays } from './components/holidays/Holidays';
import { Layout } from './components/Layout';
import { ResourceAccountDetail } from './components/resourceAccounts/ResourceAccountDetail';
import { ResourceAccounts } from './components/resourceAccounts/ResourceAccounts';
import { TenantDetail } from './components/tenants/TenantDetail';
import { Tenants } from './components/tenants/Tenants';
import { UserDetail } from './components/users/UserDetail';
import { Users } from './components/users/Users';
import { SharedVoicemailDetail } from './components/sharedVoicemails/SharedVoicemailDetail';
import { SharedVoicemails } from './components/sharedVoicemails/SharedVoicemails';
import './custom.css';
import { useUser } from './services/UserContext';
import { Redirect } from 'react-router-dom';
import { ImportAgents } from './components/agents/ImportAgents';
import { ImportUsers } from './components/users/ImportUsers';
import { MenuToggleProvider } from './services/MenuToggleContext';
import { UserRoles } from './services/UserRoles';
import { UserLevels } from './services/UserLevels';
import { ImportResourceAccounts } from './components/resourceAccounts/ImportResourceAccounts';
import { EntitySyncs } from './components/entitySyncs/EntitySyncs';
import { EntitySyncDetail } from './components/entitySyncs/EntitySyncDetail';

const AuthorizeRoute = ({ isAuthorized, children, ...rest }) => {
    const user = useUser();
    if (isAuthorized(user)) return <Route {...rest}>{children}</Route>;
    else return <Error error={{ status: 403 }} />;
}


const Routes = () => {
    const user = useUser();

    return (
        <Switch>
            <Route exact path='/'>
                <Redirect to="/autoattendants" />
            </Route>
            <Route exact path='/autoattendants' component={AutoAttendants} />
            <Route exact path='/autoattendants/:id' component={AutoAttendantDetail} />
            <Route exact path='/callqueues' component={CallQueues} />
            <Route exact path='/callqueues/:id' component={CallQueueDetail} />
            <Route exact path='/holidays' component={Holidays} />
            <Route exact path='/holidays/:id' component={HolidayDetail} />
            <Route exact path='/sharedvoicemails' component={SharedVoicemails} />
            <Route exact path='/sharedvoicemails/:id' component={SharedVoicemailDetail} />
            <AuthorizeRoute isAuthorized={u => u.role >= UserRoles.ADMIN} exact path='/admin/agents' component={Agents} />
            <AuthorizeRoute isAuthorized={u => u.role >= UserRoles.ADMIN} exact path='/admin/agents/import' component={ImportAgents} />
            <AuthorizeRoute isAuthorized={u => u.role >= UserRoles.ADMIN} exact path='/admin/agents/:id' component={AgentDetail} />
            <AuthorizeRoute isAuthorized={u => u.role >= UserRoles.ADMIN} exact path='/admin/resourceaccounts' component={ResourceAccounts} />
            <AuthorizeRoute isAuthorized={u => u.role >= UserRoles.SUPERADMIN} exact path='/admin/resourceaccounts/import' component={ImportResourceAccounts} />
            <AuthorizeRoute isAuthorized={u => u.role >= UserRoles.ADMIN} exact path='/admin/resourceaccounts/:id' component={ResourceAccountDetail} />
            <AuthorizeRoute isAuthorized={u => u.role >= UserRoles.ADMIN} exact path='/admin/users' component={Users} />
            <AuthorizeRoute isAuthorized={u => u.role >= UserRoles.ADMIN} exact path='/admin/users/import' component={ImportUsers} />
            <AuthorizeRoute isAuthorized={u => u.role >= UserRoles.ADMIN} exact path='/admin/users/:id'>
                {/*Why are we doing this ?*/}
                {({ match }) => <UserDetail id={match.params.id} />}
            </AuthorizeRoute>
            <Route exact path='/me'><UserDetail id={user.id} /></Route>
            <AuthorizeRoute isAuthorized={u => u.role >= UserRoles.ADMIN && u.level >= UserLevels.ROOT} exact path='/admin/tenants' component={Tenants} />
            <AuthorizeRoute isAuthorized={u => u.role >= UserRoles.ADMIN && u.level >= UserLevels.ROOT} exact path='/admin/tenants/:id' component={TenantDetail} />
            <AuthorizeRoute isAuthorized={u => u.role >= UserRoles.ADMIN && u.level >= UserLevels.TENANT} exact path='/admin/orgunits' component={OrgUnits} />
            <AuthorizeRoute isAuthorized={u => u.role >= UserRoles.ADMIN && u.level >= UserLevels.TENANT} exact path='/admin/orgunits/:id' component={OrgUnitDetail} />
            <AuthorizeRoute isAuthorized={u => u.role >= UserRoles.ADMIN && u.level >= UserLevels.ORGUNIT} exact path='/admin/suborgunits' component={SubOrgUnits} />
            <AuthorizeRoute isAuthorized={u => u.role >= UserRoles.ADMIN && u.level >= UserLevels.ORGUNIT} exact path='/admin/suborgunits/:id' component={SubOrgUnitDetail} />
            <AuthorizeRoute isAuthorized={u => u.role >= UserRoles.SUPERADMIN} exact path='/admin/entitysyncs' component={EntitySyncs} />
            <AuthorizeRoute isAuthorized={u => u.role >= UserRoles.SUPERADMIN} exact path='/admin/entitysyncs/:id' component={EntitySyncDetail} />
            <Route><Error error={{ status: 404 }} /></Route>
        </Switch>
    );
};

export const App = () => {

    return (
        <Authenticate>
            <MenuToggleProvider>
                <Layout>
                    <Routes />
                </Layout>
            </MenuToggleProvider>
        </Authenticate>
    );
}
