import { Link } from "react-router-dom";
import { UserLevels } from "../../services/UserLevels";
import { UserRoles } from "../../services/UserRoles";
import { PhoneUtils } from "../PhoneUtils";
import { Api } from './../../services/Api';
import { EntityList } from './../EntityList';

export const ResourceAccounts = () => {

    return (
        <EntityList
            pageTitle="Comptes de resource"
            getEntities={(params) => Api.getResourceAccounts(params)}
            entityCreationUrl="/admin/resourceaccounts/import"
            entityCreationButtonInner={<div><i className="bi bi-arrow-down-circle pe-2"></i>Importer</div>}
            canAdd={({ user }) => user && user.role >= UserRoles.SUPERADMIN && user.level >= UserLevels.ROOT}
            columns={[{
                sortBy: 'name',
                header: "Nom",
                renderCell: (o) => <Link to={`/admin/resourceaccounts/${o.id}`}>{o.name}</Link>,
            },
            {
                sortBy: 'upn',
                header: "Upn",
                renderCell: (o) => o.upn,
            },
            {
                sortBy: 'phoneNumber',
                header: "Numéro",
                renderCell: (o) => PhoneUtils.format(o.phoneNumber),
            }]} />
    );
}
