import { FieldArray, useField } from 'formik';
import * as moment from 'moment';
import { useEffect, useMemo } from 'react';
import { Input } from 'reactstrap';
import { EntityFormField } from '../EntityFormField';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { ScopeEditor } from '../ScopeEditor';
import { SwitchInput } from '../SwitchInput';
import { ValuesJoiner } from '../ValuesJoiner';
import { DatesRanges } from './../DatesRanges';
import { Label } from './../Label';

export const HolidayEditor = ({ id, readonly }) => {
    const [isFrenchBankHoliday] = useField('isFrenchBankHoliday');
    const [frenchBankHoliday, , frenchBankHolidayHelpers] = useField('frenchBankHoliday');
    const [name, , nameHelpers] = useField('name');
    const [dateRanges, , dateRangesHelpers] = useField('dateRanges');

    const frenchBankHolidayOptions = useMemo(() => {
        return [
            { value: 0, label: "Jour de l'an", disabled: true },
            { value: 1, label: "Fête du Travail", disabled: true },
            { value: 2, label: "Lundi de Pâques", disabled: true },
            { value: 3, label: "Armistice", disabled: true },
            { value: 4, label: "Ascension", disabled: true },
            { value: 5, label: "Lundi de Pentecôte", disabled: true },
            { value: 6, label: "Quatorze Juillet", disabled: true },
            { value: 7, label: "Assomption", disabled: true },
            { value: 8, label: "Toussaint", disabled: true },
            { value: 9, label: "Onze Novembre", disabled: true },
            { value: 10, label: "Noël", disabled: true },
            { value: 11, label: "Second jour de Noël" },
            { value: 12, label: "Vendredi saint" },
            { value: 13, label: "Abolition de l'esclavage (Mayotte)" },
            { value: 14, label: "Abolition de l'esclavage (Martinique)" },
            { value: 15, label: "Abolition de l'esclavage (Guadeloupe)" },
            { value: 16, label: "Abolition de l'esclavage (Saint-Martin)" },
            { value: 17, label: "Abolition de l'esclavage (Guyane)" },
            { value: 18, label: "Abolition de l'esclavage (Saint-Barthélemy)" },
            { value: 19, label: "Abolition de l'esclavage (La Reunion)" }
        ];
    }, []);

    useEffect(() => {
        if (isFrenchBankHoliday && frenchBankHoliday) {
            var selection = frenchBankHolidayOptions.filter(o => o.value === frenchBankHoliday.value);
            if (selection != null && selection.length && name.value !== selection[0].label) {
                nameHelpers.setValue(selection[0].label);
            }
        }
    }, [isFrenchBankHoliday, frenchBankHoliday, name, nameHelpers, frenchBankHolidayOptions]);

    useEffect(() => {
        if (id === "new" && isFrenchBankHoliday.value && dateRanges.value.length > 0) {
            dateRangesHelpers.setValue([]);
        }
    }, [id, isFrenchBankHoliday, dateRanges, dateRangesHelpers]);

    return (
        <div>
            <ScopeEditor readonly={id > 0} />
            <EntityFormField label="Jour férié automatique ?" field={isFrenchBankHoliday}>
                <SwitchInput {...isFrenchBankHoliday} disabled={readonly || +id > 0} trueLabel="Automatique" falseLabel="Manuel" />
            </EntityFormField>
            {isFrenchBankHoliday.value &&
                <EntityFormField label="Jour férié" field={frenchBankHoliday}>
                    <Input {...frenchBankHoliday}
                        bsSize="sm"
                        type="select"
                        placeholder="Choisissez une valeur"
                        onChange={(e) => {
                            frenchBankHolidayHelpers.setValue(+e.target.value);
                        }}
                        disabled={readonly || +id > 0}>
                        {frenchBankHoliday.value == null &&
                            <option>Choisir</option>
                        }
                        {frenchBankHolidayOptions.filter(o => +id > 0 || !o.disabled).map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
                    </Input>
                </EntityFormField>
            }
            {!isFrenchBankHoliday.value &&
                <>
                    <EntityFormField label="Nom" field={name}>
                        <Input {...name} bsSize="sm" disabled={readonly} />
                    </EntityFormField>
                    <div className="entity-form-field">
                        <Label>
                            Dates
                            <small className="text-muted fst-italic"> (entre 1 et 10 périodes doivent être renseignées)</small>
                        </Label>
                        <FieldArray name="dateRanges">
                            {(props) =>
                                <DatesRanges disabled={readonly} {...{ value: dateRanges.value, ...props }} />
                            }
                        </FieldArray>
                        <FieldErrorMessage name="dateRangesLength" />
                        <FieldErrorMessage name="dateRangesConflict" />
                    </div>
                </>
            }
            {isFrenchBankHoliday.value && +id > 0 &&
                <div className="entity-form-field">
                    <Label>
                        Dates
                    </Label>
                    <div className="card p-2">
                        <ValuesJoiner values={dateRanges.value} selector={(o) => moment.utc(o.start).format('DD/MM/YYYY')} />
                    </div>
                </div>
            }
        </div>
    );
}