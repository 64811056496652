import { Link } from "react-router-dom";
import { UserLevels } from "../../services/UserLevels";
import { UserRoles } from "../../services/UserRoles";
import { Api } from './../../services/Api';
import { EntityList } from './../EntityList';

export const Users = () => {
    return (
        <EntityList
            pageTitle="Utilisateurs"
            getEntities={(params) => Api.getUsers(params)}
            entityCreationUrl="/admin/users/import"
            entityCreationButtonInner={<div><i className="bi bi-arrow-down-circle pe-2"></i>Importer</div>}
            canAdd={({ user }) => user && user.role >= UserRoles.ADMIN}
            columns={[{
                sortBy: 'firstName,lastName',
                header: "Nom",
                renderCell: (o) => <Link to={`/admin/users/${o.id}`}>{o.firstName} {o.lastName}</Link>,
            },
            {
                sortBy: 'enabled',
                header: "Statut",
                renderCell: (o) => o.enabled ? "Activé" : "Désactivé",
            },
            {
                sortBy: 'role',
                header: "Rôle",
                renderCell: (o) => {
                    switch (o.role) {
                        case UserRoles.READER: return "Lecteur";
                        case UserRoles.WRITER: return "Rédacteur";
                        case UserRoles.ADMIN: return "Administrateur";
                        case UserRoles.SUPERADMIN: return "Super Administrateur";
                        default: return "NC";
                    }
                }
            },
            {
                sortBy: 'level',
                header: "Niveau",
                renderCell: (o) => {
                    switch (o.level) {
                        case UserLevels.SUBORGUNIT: return "Sous-organisation";
                        case UserLevels.ORGUNIT: return "Organisation";
                        case UserLevels.TENANT: return "Tenant";
                        case UserLevels.ROOT: return "Racine";
                        default: return "?";
                    }
                }
            }]} />
    );
}
