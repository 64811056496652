import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { EntityFormField } from '../EntityFormField';
import { PhoneUtils } from '../PhoneUtils';
import { ScopeEditor } from '../ScopeEditor';

export const ResourceAccountEditor = ({ id, readonly }) => {
    const [name] = useField('name');
    const [upn] = useField('upn');
    const [phoneNumber] = useField('phoneNumber');
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');

    useEffect(() => {
        setFormattedPhoneNumber(PhoneUtils.format(phoneNumber.value));
    }, [phoneNumber.value, setFormattedPhoneNumber]);

    return (
        <div>
            <ScopeEditor />
            <EntityFormField label="Nom" field={name}>
                <Input {...name} bsSize="sm" disabled={true} />
            </EntityFormField>
            <EntityFormField label="Upn" field={upn}>
                <Input {...upn} bsSize="sm" disabled={true} />
            </EntityFormField>
            <EntityFormField label="Numéro" field={phoneNumber}>
                <Input value={formattedPhoneNumber} bsSize="sm" disabled={true} />
            </EntityFormField>
        </div>
    );
}