import PropTypes from "prop-types";
import { useState, useEffect, useMemo, useCallback, Fragment } from 'react';
import { Link, useLocation, useHistory } from "react-router-dom";
import { Api } from "../services/Api";
import { useUser } from "../services/UserContext";
import { UserLevels } from "../services/UserLevels";
import { UserRoles } from "../services/UserRoles";
import { Autocomplete } from "./Autocomplete";
import { Debugger } from "./Debugger";
import { Error } from './Error';
import { Loader } from './Loader';
import { PageContent } from "./PageContent";
import { PageHeader } from "./PageHeader";
import { PageTitle } from "./PageTitle";

const IntParser = (o) => +o;
const DefaultParser = (o) => o;

export const useUrlSearchParams = (initialValue, parsers) => {
    const history = useHistory();
    const location = useLocation();
    const path = location.pathname;

    const locationSearch = location.search;

    const urlSearchParams = useMemo(() => {
        return new URLSearchParams(locationSearch);
    }, [locationSearch]);


    const getParser = useCallback((name) => {
        if (parsers.hasOwnProperty(name)) {
            return parsers[name];
        } else {
            return DefaultParser;
        }
    }, [parsers]);

    const value = useMemo(() => {
        var result = initialValue ?? {};

        urlSearchParams.forEach((value, key) => {
            result[key] = getParser(key)(value);
        });

        return result;

        //return urlSearchParams.has(name) ? urlSearchParams.get(name) : defaultValue;
    }, [urlSearchParams, getParser, initialValue]);

    //const urlParams = new URLSearchParams(location.search);
    //const value = urlParams.has(name) ? urlParams.get(name) : defaultValue;
    const setValue = (o) => {

        var newUrlSearchParams = new URLSearchParams(locationSearch);

        for (const prop in o) {
            if (o[prop] != null) {
                newUrlSearchParams.set(prop, o[prop]);
            } else if (newUrlSearchParams.has(prop)) {
                newUrlSearchParams.delete(prop);
            }
        }

        if (newUrlSearchParams.toString() !== urlSearchParams.toString()) {
            history.push(`${path}?${newUrlSearchParams}`);
        }
    }

    return [value, setValue];
}

export const useUrlSearchParam = (name, initialValue, parser) => {
    const history = useHistory();
    const location = useLocation();
    const path = location.pathname;

    const locationSearch = location.search;

    const urlSearchParams = useMemo(() => {
        return new URLSearchParams(locationSearch);
    }, [locationSearch]);

    const getParser = useCallback(() => {
        if (parser) {
            return parser;
        } else {
            return (o) => o;
        }
    }, [parser]);

    const value = useMemo(() => {
        if (urlSearchParams.has(name)) {
            const o = urlSearchParams.get(name);
            return getParser()(o) ?? initialValue;
        } else {
            return initialValue;
        }
    }, [urlSearchParams, getParser, initialValue, name]);

    const setValue = (o) => {
        var newUrlSearchParams = new URLSearchParams(locationSearch);

        if (o != null) {
            newUrlSearchParams.set(name, o);
        } else if (newUrlSearchParams.has(name)) {
            newUrlSearchParams.delete(name);
        }

        if (newUrlSearchParams.toString() !== urlSearchParams.toString()) {
            history.push(`${path}?${newUrlSearchParams}`);
        }
    }

    return [value, setValue];
}


const EntityListParamParsers = {
    page: IntParser,
    tenantId: IntParser,
    orgUnitId: IntParser,
    subOrgUnitId: IntParser
}

export const EntityList = ({
    columns,
    getEntities,
    emptyMessage,
    entityCreationUrl,
    entityCreationButtonInner,
    canAdd,
    pageSize,
    pageTitle,
    sortable,
    searcheable,
    hideTenantFilter,
    hideOrgUnitFilter,
    hideSubOrgUnitFilter,
    className,
    header,
    filtersAddon
}) => {
    const user = useUser();
    const [params, setParams] = useUrlSearchParams({ page: 0 }, EntityListParamParsers);
    const [entities, setEntities] = useState([]);
    const [queryDebug, setQueryDebug] = useState(null);
    const [queryDuration, setQueryDuration] = useState(null);
    const [hitCount, setHitCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [scope, setScope] = useState({ tenant: null, orgUnit: null, subOrgUnit: null });
    const [pendingQuery, setPendingQuery] = useState(params.query ?? '');
    const [displayTenant, setDisplayTenant] = useState(false);
    const [displayOrgUnit, setDisplayOrgUnit] = useState(false);
    const [displaySubOrgUnit, setDisplaySubOrgUnit] = useState(false);
    const [displayScope, setDisplayScope] = useState(false);

    const internalUserCanAdd = (user) => user && user.role >= UserRoles.WRITER && (canAdd == null || canAdd({ user }));

    const [hydrateSubOrgUnitRequest, setHydrateSubOrgUnitRequest] = useState(null);
    const [hydrateOrgUnitRequest, setHydrateOrgUnitRequest] = useState(null);
    const [hydrateTenantRequest, setHydrateTenantRequest] = useState(null);

    // init filters
    useEffect(() => {
        if (params.subOrgUnitId !== scope.subOrgUnit?.id) {

            if (params.subOrgUnitId) {
                if (hydrateSubOrgUnitRequest == null) {
                    var subOrgUnitRequest = Api.getSubOrgUnit(params.subOrgUnitId);
                    setHydrateSubOrgUnitRequest(subOrgUnitRequest);
                    subOrgUnitRequest.then(data => {
                        setScope({ tenant: data.tenant, orgUnit: data.orgUnit, subOrgUnit: data });
                    }).catch(() => {
                        setParams({ tenantId: null, orgUnitId: null, subOrgUnitId: null });
                    }).finally(() => {
                        setHydrateSubOrgUnitRequest(null);
                    });
                }
            } else {
                if (hydrateSubOrgUnitRequest != null) {
                    hydrateSubOrgUnitRequest.abort();
                }
                setScope({ subOrgUnit: null });
            }

        } else if (params.orgUnitId !== scope.orgUnit?.id) {

            if (params.orgUnitId) {
                if (hydrateOrgUnitRequest == null) {
                    var orgUnitRequest = Api.getOrgUnit(params.orgUnitId);
                    setHydrateOrgUnitRequest(orgUnitRequest);
                    orgUnitRequest.then(data => {
                        setScope({ tenant: data.tenant, orgUnit: data, subOrgUnit: null });
                    }).catch(() => {
                        setParams({ tenantId: null, orgUnitId: null, subOrgUnitId: null });
                    }).finally(() => {
                        setHydrateOrgUnitRequest(null);
                    });
                }
            } else {
                if (hydrateOrgUnitRequest != null) {
                    hydrateOrgUnitRequest.abort();
                }
                setScope({ orgUnit: null, subOrgUnit: null });
            }

        } else if (params.tenantId !== scope.tenant?.id) {

            if (params.tenantId) {
                if (hydrateTenantRequest == null) {
                    var tenantRequest = Api.getTenant(params.tenantId);
                    setHydrateTenantRequest(tenantRequest);
                    tenantRequest.then(data => {
                        setScope({ tenant: data, orgUnit: null, subOrgUnit: null });
                    }).catch(() => {
                        setParams({ tenantId: null, orgUnitId: null, subOrgUnitId: null });
                    }).finally(() => {
                        setHydrateTenantRequest(null);
                    });
                }
            } else {
                if (hydrateTenantRequest != null) {
                    hydrateTenantRequest.abort();
                }
                setScope({ tenant: null, orgUnit: null, subOrgUnit: null });
            }

        }
    }, [scope, params, setParams, hydrateSubOrgUnitRequest, hydrateOrgUnitRequest, hydrateTenantRequest]);

    // init displayScope
    useEffect(() => {

        var tenant = scope.tenant ?? user.scope?.tenant;
        var orgUnit = scope.orgUnit ?? user.scope?.orgUnit;

        var displayTenantValue = !hideTenantFilter && user.level >= UserLevels.ROOT;
        var displayOrgUnitValue = !hideOrgUnitFilter && user.level >= UserLevels.TENANT && tenant != null && tenant.hasChildren;
        var displaySubOrgUnitValue = !hideSubOrgUnitFilter && user.level >= UserLevels.ORGUNIT && orgUnit != null && orgUnit.hasChildren;

        setDisplayTenant(displayTenantValue);
        setDisplayOrgUnit(displayOrgUnitValue);
        setDisplaySubOrgUnit(displaySubOrgUnitValue);
        setDisplayScope(displayTenantValue || displayOrgUnitValue || displaySubOrgUnitValue);

    }, [user, scope,
        hideTenantFilter, hideOrgUnitFilter, hideSubOrgUnitFilter,
        setDisplayTenant, setDisplayOrgUnit, setDisplaySubOrgUnit, setDisplayScope]);

    const onTenantChanged = (o) => {
        setScope({ tenant: o, orgUnit: null, subOrgUnit: null });
        setParams({ tenantId: o?.id, orgUnitId: null, subOrgUnitId: null, page: null });
    }

    const onOrgUnitChanged = (o) => {
        setScope({ tenant: scope?.tenant, orgUnit: o, subOrgUnit: null });
        setParams({ tenantId: params.tenantId, orgUnitId: o?.id, subOrgUnitId: null, page: null });
    }

    const onSubOrgUnitChanged = (o) => {
        setScope({ tenant: scope?.tenant, orgUnit: scope?.orgUnit, subOrgUnit: o });
        setParams({ tenantId: params.tenantId, orgUnitId: params.orgUnitId, subOrgUnitId: o?.id, page: null });
    }

    const onPaginate = (index) => {
        setLoading(true);
        setParams({ page: index > 0 ? index : null });
    }

    const onQueryChanged = (value) => {
        setPendingQuery(value ?? '');
        let newQuery = value && value.length > 0 ? value : null;
        let currentQuery = params.query && params.query.length > 0 ? params.query : null;
        if (newQuery !== currentQuery) {
            setLoading(true);
            setParams({ page: null, query: newQuery });
        }
    }

    const onSort = (column) => {
        setLoading(true);
        if (params.sortBy && params.sortBy.length) {
            if (params.sortBy === column.sortBy || params.sortBy === '-' + column.sortBy) {
                if (params.sortBy[0] === '-') {
                    setParams({ sortBy: null });
                } else {
                    setParams({ sortBy: '-' + column.sortBy });
                }
            } else {
                setParams({ sortBy: column.sortBy });
            }

        } else {
            setParams({ sortBy: column.sortBy });
        }
    }


    // retrieve entities
    useEffect(() => {

        const request = getEntities({
            pageIndex: params.page,
            query: params.query && params.query.length > 0 ? params.query : null,
            sortBy: params.sortBy,
            tenantId: params.tenantId,
            orgUnitId: params.orgUnitId,
            subOrgUnitId: params.subOrgUnitId,
            pageSize
        });
        request.then(data => {
            if (!request.aborted) {
                setEntities(data.values);
                setQueryDebug(data.debug);
                setQueryDuration(data.duration);
                setHitCount(data.hitCount);
                setLoading(false);
                setError(null);
            }
        }).catch(error => {
            if (!request.aborted) {
                setEntities([]);
                setQueryDebug(null);
                setQueryDuration(null);
                setHitCount(0);
                setLoading(false);
                setError(error);
            }
        });

        return request.abort;

    }, [getEntities, params.page, params.query, params.sortBy, params.tenantId, params.orgUnitId, params.subOrgUnitId, pageSize]);


    const getScopeDisplay = (scope) => {
        let segments = [];

        if (user.level > UserLevels.TENANT && scope?.tenant?.name) segments.push(scope.tenant.name);
        if (user.level > UserLevels.ORGUNIT && scope?.orgUnit?.name) segments.push(scope.orgUnit.name);
        if (user.level > UserLevels.SUBORGUNIT && scope?.subOrgUnit?.name) segments.push(scope.subOrgUnit.name);

        return (
            <>
                {segments.map((o, index) =>
                    <Fragment key={index}>
                        {index > 0 && <span className="px-2 text-secondary fw-bold">&gt;</span>}
                        <span>{o}</span>
                    </Fragment>
                )}
            </>
        );
    }

    const getScopeSortByColumn = () => {
        if (!hideTenantFilter && !hideOrgUnitFilter && !hideSubOrgUnitFilter) return 'Tenant,OrgUnit,SubOrgUnit';
        else if (!hideTenantFilter && !hideOrgUnitFilter) return 'Tenant,OrgUnit';
        else if (!hideTenantFilter) return 'Tenant';
        else return null;
    }


    const render = (content) => {
        return (
            <div>
                <PageHeader>
                    <PageTitle path={pageTitle} />
                </PageHeader>
                <PageContent>
                    {header && <div className="mb-4">{header()}</div>}
                    {content}
                </PageContent>
            </div>
        );
    }

    if (error) {
        return render(<Error error={error} />);
    } else {

        return render(
            <div className={className}>

                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4">
                                {searcheable && (
                                    <form
                                        className="col mb-3"
                                        onSubmit={(e) => {
                                            onQueryChanged(pendingQuery);
                                            e.preventDefault();
                                        }}>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Rechercher ..."
                                                value={pendingQuery}
                                                onChange={e => setPendingQuery(e.target.value)} />
                                            {pendingQuery && pendingQuery.length > 0 &&
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => onQueryChanged(null)}>
                                                    <i className="bi-x-lg"></i>
                                                </button>
                                            }
                                            <button className="btn btn-outline-secondary">
                                                <i className="bi-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                )}
                                {displayTenant && (
                                    <Autocomplete
                                        className="col mb-3"
                                        inputPlaceholder="Filtrer par tenant ..."
                                        value={scope.tenant}
                                        getMatches={async (o) => (await Api.getTenants(o)).values}
                                        itemToString={option => option?.name || ''}
                                        onChange={onTenantChanged} />
                                )}
                                {displayOrgUnit && (
                                    <Autocomplete
                                        className="col mb-3"
                                        inputPlaceholder="Filtrer par organisation ..."
                                        value={scope.orgUnit}
                                        disabled={scope.tenant == null && user.level > UserLevels.TENANT}
                                        getMatches={async (o) => (await Api.getOrgUnits({ ...o, tenantId: params.tenantId })).values}
                                        itemToString={option => option?.name || ''}
                                        onChange={onOrgUnitChanged} />
                                )}
                                {displaySubOrgUnit && (
                                    <Autocomplete
                                        className="col mb-3"
                                        inputPlaceholder="Filtrer par sous-organisation ..."
                                        value={scope.subOrgUnit}
                                        disabled={scope.orgUnit == null && user.level > UserLevels.ORGUNIT}
                                        getMatches={async (o) => (await Api.getSubOrgUnits({ ...o, orgUnitId: params.orgUnitId })).values}
                                        itemToString={option => option?.name || ''}
                                        onChange={onSubOrgUnitChanged} />
                                )}
                                {filtersAddon && filtersAddon(setParams)}
                            </div>
                        </div>
                        {internalUserCanAdd(user) &&
                            <div className="col-md-2 mb-3 text-md-end">
                                <Link className="btn btn-primary" to={entityCreationUrl}>{entityCreationButtonInner}</Link>
                            </div>
                        }
                    </div>
                </div>
                <div className="table-responsive">
                    <table className='table mb-0'>
                        <thead>
                            <tr>
                                {columns.map((column, index) => <SortableHeader key={index} column={column} toggle={onSort} />)}
                                {displayScope &&
                                    <SortableHeader column={{
                                        sortBy: getScopeSortByColumn(),
                                        header: 'Emplacement'
                                    }} toggle={onSort} />
                                }
                            </tr>
                        </thead>
                        {!loading &&
                            <tbody>
                                {entities && entities.map(o =>
                                    <tr key={o.id}>
                                        {columns.map((column, index) =>
                                            <td key={index}>{column.renderCell(o)}</td>
                                        )}
                                        {displayScope &&
                                            <td>{getScopeDisplay(o.scope)}</td>
                                        }
                                    </tr>
                                )}
                            </tbody>
                        }
                    </table>
                    {!loading && entities.length === 0 &&
                        <div className="py-4 text-center text-muted bg-light">
                            <div style={{ fontSize: "5em", lineHeight: "1" }}><i className="bi bi-inbox-fill" style={{ color: "#ddd" }}></i></div>
                            <div>{emptyMessage}</div>
                        </div>
                    }
                </div>
                {loading && <Loader className="p-2" />}
                <EntityListPager hitCount={hitCount} pageIndex={params.page} pageSize={pageSize} onPaginate={(pageIndex) => onPaginate(pageIndex)} />
                <Debugger title={`Debug query (${queryDuration}ms)`}>
                    <pre className="overflow-visible text-secondary" style={{ whiteSpace: 'pre-wrap', fontSize: '0.6rem' }}>{queryDebug}</pre>
                </Debugger>
            </div>
        );
    }
}

EntityList.propTypes = {
    columns: PropTypes.array.isRequired,
    getEntities: PropTypes.func.isRequired,
    entityCreationUrl: PropTypes.string,
    entityCreationButtonInner: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    emptyMessage: PropTypes.string,
    canAdd: PropTypes.func,
    pageSize: PropTypes.number,
    pageTitle: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    searcheable: PropTypes.bool,
    hideTenantFilter: PropTypes.bool,
    hideOrgUnitFilter: PropTypes.bool,
    hideSubOrgUnitFilter: PropTypes.bool,
};

EntityList.defaultProps = {
    emptyMessage: "Aucun résultat",
    entityCreationButtonInner: <div><i className="bi bi-plus pe-2"></i>Ajouter</div>,
    pageSize: 20,
    sortable: true,
    searcheable: true,
    hideTenantFilter: false,
    hideOrgUnitFilter: false,
    hideSubOrgUnitFilter: false
};

const SortableHeader = ({ column, toggle }) => {
    const [sortBy] = useUrlSearchParam('sortBy');

    const onToggle = () => {
        if (column && column.sortBy && toggle) toggle(column);
    }

    const className = column.sortBy ? 'cursor-pointer user-select-none' : 'user-select-none';
    const title = column.sortBy ? sortBy ? sortBy[0] === '-' ? "Supprimer le tri" : "Trier par ordre décroissant" : "Trier par ordre croissant" : "";

    return (
        <th onClick={onToggle} className={className} title={title}>
            {column.header}
            {column.sortBy && sortBy && sortBy.length && (sortBy.substr(1) === column.sortBy || sortBy === column.sortBy) &&
                <span>{(sortBy[0] === '-' ? "▾" : "▴")}</span>
            }
        </th>
    );
}

SortableHeader.propTypes = {
    column: PropTypes.shape({
        sortBy: PropTypes.string,
        header: PropTypes.string.isRequired
    }).isRequired,
    toggle: PropTypes.func.isRequired,
};

export const EntityListPager = ({ hitCount, pageSize, pageIndex, onPaginate }) => {
    const maxPage = Math.ceil(hitCount / pageSize);

    if (hitCount <= pageSize) {
        return null;
    } else {
        return (
            <div className="d-flex justify-content-between border-top pt-4">
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    disabled={pageIndex <= 0}
                    onClick={() => onPaginate(pageIndex - 1)}>
                    <i className="bi-arrow-left me-2"></i>
                    Précédent
                </button>
                <div className="d-flex align-items-center">
                    <strong>Page {pageIndex + 1}/{maxPage}</strong>
                    <span>&nbsp;</span>
                    <span>({hitCount} éléments)</span>
                </div>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    disabled={pageIndex >= (maxPage - 1)}
                    onClick={() => onPaginate(pageIndex + 1)}>
                    Suivant
                    <i className="bi-arrow-right ms-2"></i>
                </button>
            </div>
        );
    }
}

EntityListPager.propTypes = {
    hitCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    pageIndex: PropTypes.number.isRequired,
    onPaginate: PropTypes.func.isRequired
};

//const EntityListQueryDebugger = ({ query, duration }) => {
//    const LSKey = "hideEntityListQueryDebugger";
//    const defaultHide = window.localStorage.getItem(LSKey) === "true";
//    const [hide, setHide] = useState(defaultHide);

//    const toggleHide = () => {
//        const value = !hide;
//        setHide(value);
//        if (value) window.localStorage.setItem(LSKey, "true");
//        else window.localStorage.removeItem(LSKey);
//    }

//    if (hide) {
//        return <button className="btn btn-secondary mt-4" onClick={toggleHide}>Show debug</button>;
//    } else {
//        return (
//            <div className="mt-4">
//                <div className="text-secondary py-2 px-3 fw-bold bg-secondary text-white rounded-top d-flex justify-content-between">
//                    <div>
//                        <span className="text-uppercase">Debug query</span>
//                        <span> ({duration}ms)</span>
//                    </div>
//                    <button type="button" class="btn-close btn-close-white" aria-label="Close" onClick={toggleHide}></button>
//                </div>
//                <pre className="bg-light text-break m-0 p-3 rounded-bottom border border-secondary shadow-sm text-secondary" style={{
//                    whiteSpace: 'pre-wrap',
//                    maxHeight: '300px',
//                    fontSize: '0.75rem'
//                }}>{query}</pre>
//            </div>
//        );
//    }
//}

//EntityListQueryDebugger.propTypes = {
//    query: PropTypes.string,
//    duration: PropTypes.number,
//};