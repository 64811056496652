import { DatesRange } from './DatesRange';

export const DatesRanges = ({ name, push, remove, value, disabled }) => {
    return (
        <div className="card shadow-sm">
            <table className='table table-striped m-0' style={{ tableLayout: 'fixed' }}>
                <colgroup>
                    <col />
                    <col />
                    {!disabled && <col width="65px" />}
                </colgroup>
                <thead>
                    <tr>
                        <th className="ps-3">Début</th>
                        <th>Fin</th>
                        {!disabled && <th className="pe-3"></th>}
                    </tr>
                </thead>
                <tbody>
                    {value && value.map((range, index) =>
                        <DatesRange disabled={disabled} key={index} name={name + `[${index}]`}>
                            {!disabled &&
                                <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    disabled={value.length === 1}
                                    onClick={() => remove(index)}>
                                    <i className="bi-trash"></i>
                                </button>
                            }
                        </DatesRange>
                    )}
                    {!disabled &&
                        <tr>
                            <td colSpan="3" className="p-3">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-primary"
                                    disabled={value.length > 10}
                                    onClick={() => push({ start: null, end: null })}>+ Ajouter une nouvelle période</button>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}