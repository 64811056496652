import { Fragment } from "react";

export const ValuesJoiner = ({ tag, values, separator, selector }) => {
    const Tag = tag ?? "div";

    const renderValue = (value, index) => {
        return (
            <Fragment key={index}>
                {index > 0 && <span>{separator ?? ", "}</span>}
                <span>{selector ? selector(value) : value}</span>
            </Fragment>
        );
    };

    if (values == null) return null;
    else return <Tag>{values.map(renderValue)}</Tag>;
};