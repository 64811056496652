import { useParams } from 'react-router-dom';
import { EntityForm } from '../EntityForm';
import { Api } from './../../services/Api';
import { EntitySyncEditor } from './EntitySyncEditor';

export const EntitySyncDetail = () => {
    const { id } = useParams();
    return (
        <EntityForm
            id={id}
            pageTitle="Synchronisations"
            listUrl="/admin/entitysyncs/"
            getEntityDisplay={({ value }) => value?.id?.toString() ?? ""}
            newEntity={{}}
            getEntity={({ id }) => Api.getEntitySync(id)}
            canEdit={() => false}
            canDelete={() => false}
            isAuditable={false}>
            {(props) => <EntitySyncEditor {...props} />}
        </EntityForm>
    );
}