import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { Input, TabContent, TabPane } from 'reactstrap';
import { DateTimeUtils } from '../../services/DateTimeUtils';
import { ScopeProvider } from '../../services/ScopeContext';
import { EntityFormField } from '../EntityFormField';
import { EntityFormTabs } from '../EntityFormTabs';
import { ScopeEditor } from '../ScopeEditor';
import { Config } from './../../services/Config';
import { AudioFile } from './../AudioFile';
import { RadioList } from './../RadioList';
import { RangeInput } from './../RangeInput';
import { SwitchInput } from './../SwitchInput';
import { CallQueueAgents } from './CallQueueAgents';
import { CallQueueThresholdOverrunAction } from './CallQueueThresholdOverrunAction';

export const CallQueueEditor = ({ id, readonly, hideScopeEditor }) => {

    const [currentTab, setCurrentTab] = useState(0);

    const [scope] = useField('scope');
    const [name] = useField('name');
    const [language] = useField('language');
    const [hasWelcomeMusic, , hasWelcomeMusicHelpers] = useField('hasWelcomeMusic');
    const [welcomeMusic, , welcomeMusicHelpers] = useField('welcomeMusic');
    const [useDefaultMusicOnHold, , useDefaultMusicOnHoldHelpers] = useField('useDefaultMusicOnHold');
    const [musicOnHold, , musicOnHoldHelpers] = useField('musicOnHold');
    const [agents, , agentsHelpers] = useField('agents');
    const [routingMethod, , routingMethodHelpers] = useField('routingMethod');
    const [presenceBasedRouting, , presenceBasedRoutingHelpers] = useField('presenceBasedRouting');
    const [allowOptOut] = useField('allowOptOut');
    const [agentAlertTime] = useField('agentAlertTime');
    const [overflowThreshold] = useField('overflowThreshold');
    const [overflowThresholdOverrun] = useField('overflowThresholdOverrun');
    const [timeoutThreshold] = useField('timeoutThreshold');
    const [timeoutThresholdOverrun] = useField('timeoutThresholdOverrun');

    const tabs = [
        "Général",
        "Agents",
        "Stratégie de sélection d'agents",
        "Débordement",
        "Délais d'attente"
    ];

    useEffect(() => {
        if (presenceBasedRouting.value && routingMethod.value === 3) presenceBasedRoutingHelpers.setValue(false);
    }, [presenceBasedRouting.value, routingMethod.value, presenceBasedRoutingHelpers]);

    return (
        <ScopeProvider value={scope.value}>
            <EntityFormTabs tabs={tabs} activeTab={currentTab} activateTab={setCurrentTab}/>
            <TabContent activeTab={currentTab} className="border rounded-bottom p-4">
                <TabPane tabId={0}>
                    {!hideScopeEditor &&
                        <ScopeEditor readonly={id > 0} />
                    }

                    <EntityFormField label="Nom" field={name}>
                        <Input {...name} bsSize="sm" disabled={readonly} />
                    </EntityFormField>
                    <EntityFormField label="Langue" field={language}>
                        <Input type="select" {...language} bsSize="sm" disabled={readonly} >
                            {!language.value &&
                                <option>Choisir</option>
                            }
                            {Config.getLanguages().map(o => <option value={o.value} key={o.value}>{o.label}</option>)}
                        </Input>
                    </EntityFormField>
                    <EntityFormField field={hasWelcomeMusic} label="Message d'accueil" info="Fichier son contenant un message qui sera lu entièrement avant de commencer à acheminer l'appel (idéalement un message assez court)">
                        <RadioList
                            {...hasWelcomeMusic}
                            disabled={readonly}
                            className="card px-3 py-2"
                            options={[
                                {
                                    value: false,
                                    label: "Ne pas lire de message d'accueil"
                                },
                                {
                                    value: true,
                                    label: "Lire un fichier audio",
                                    info: "Vous êtes responsable de l'effacement indépendant et de la sécurisation de tous les droits et autorisations nécessaires pour utiliser n'importe quel fichier audio ou de musique avec votre service Microsoft Teams. Cela inclut la propriété intellectuelle et d'autres droits dans la musique, les effets sonores, l'audio, les marques, les noms et tout autre contenu du fichier audio de tous les détenteurs de droits pertinents, y compris les artistes, les acteurs, les acteurs, les acteurs,  les éditeurs, les auteurs, les compositeurs, les étiquettes d'enregistrements, les éditeurs de musique, les unions, les organisations de droits, les organisations de gestion collective et tous les autres tiers qui possèdent, contrôlent ou concèdent les droits d'auteur de musique, les effets sonores, l'audio et d'autres droits de propriété intellectuelle.",
                                    children: () => {
                                        return (
                                            <EntityFormField field={welcomeMusic}>
                                                <AudioFile
                                                    value={welcomeMusic.value}
                                                    disabled={readonly}
                                                    onChange={(value) => {
                                                        welcomeMusicHelpers.setTouched(true);
                                                        welcomeMusicHelpers.setValue(value);
                                                    }} />
                                            </EntityFormField>
                                        );
                                    }
                                },
                            ]}
                            onChange={(value) => hasWelcomeMusicHelpers.setValue(value === "true")} />
                    </EntityFormField>
                    <EntityFormField field={useDefaultMusicOnHold} label="Attente musicale" info="Fichier son pouvant contenir un message informatif qui est lu pendant le temps de sonnerie. Il peut être interrompu à tout moment">
                        <RadioList
                            {...useDefaultMusicOnHold}
                            disabled={readonly}
                            className="card px-3 py-2"
                            options={[
                                {
                                    value: true,
                                    label: "Lire la musique par défaut"
                                },
                                {
                                    value: false,
                                    label: "Lire un fichier audio",
                                    info: "Vous êtes responsable de l'effacement indépendant et de la sécurisation de tous les droits et autorisations nécessaires pour utiliser n'importe quel fichier audio ou de musique avec votre service Microsoft Teams. Cela inclut la propriété intellectuelle et d'autres droits dans la musique, les effets sonores, l'audio, les marques, les noms et tout autre contenu du fichier audio de tous les détenteurs de droits pertinents, y compris les artistes, les acteurs, les acteurs, les acteurs,  les éditeurs, les auteurs, les compositeurs, les étiquettes d'enregistrements, les éditeurs de musique, les unions, les organisations de droits, les organisations de gestion collective et tous les autres tiers qui possèdent, contrôlent ou concèdent les droits d'auteur de musique, les effets sonores, l'audio et d'autres droits de propriété intellectuelle.",
                                    children: () => {
                                        return (
                                            <EntityFormField field={musicOnHold}>
                                                <AudioFile
                                                    value={musicOnHold.value}
                                                    disabled={readonly}
                                                    onChange={(value) => {
                                                        musicOnHoldHelpers.setTouched(true);
                                                        musicOnHoldHelpers.setValue(value);
                                                    }} />
                                            </EntityFormField>
                                        );
                                    }
                                },
                            ]}
                            onChange={(value) => useDefaultMusicOnHoldHelpers.setValue(value === "true")} />
                    </EntityFormField>
                </TabPane>
                <TabPane tabId={1}>
                    <EntityFormField label="Agents" field={agents}>
                        <div style={{ maxWidth: '700px' }}>
                            <CallQueueAgents
                                value={agents.value}
                                disabled={readonly}
                                onChange={(value) => {
                                    agentsHelpers.setTouched(true);
                                    agentsHelpers.setValue(value);
                                }} />
                        </div>
                    </EntityFormField>
                </TabPane>
                <TabPane tabId={2}>
                    <EntityFormField label="Méthode de routage" field={routingMethod}>
                        <RadioList
                            {...routingMethod}
                            disabled={readonly}
                            className="card px-3 py-2"
                            readonly={readonly}
                            options={[
                                {
                                    value: 0,
                                    label: "Routage du standard",
                                    info: "Lorsque la méthode Routage du standard est utilisée, le premier appel de la file d'attente fera sonner les appareils de tous les agents d'appel simultanément. Le premier agent d'appel qui décroche obtient l'appel."
                                },
                                {
                                    value: 1,
                                    label: "Routage en série",
                                    info: "Lorsque la méthode Routage en série est utilisée, chaque appel entrant fait sonner successivement tous les appareils des agents, l'un après l'autre. Le premier agent contacté est celui situé au début de la liste des agents d'appel."
                                },
                                {
                                    value: 2,
                                    label: "Répartition uniforme",
                                    info: "Lorsque le routage par répartition uniforme est utilisé, chaque agent d'appel obtient le même nombre d'appels de la file d'attente."
                                },
                                {
                                    value: 3,
                                    label: "Le plus longuement inactif",
                                    info: "Lorsque la méthode Routage le plus longuement inactif est utilisée, l'appel suivant dans la file d'attente fait sonner l'agent d'appel disponible depuis le plus longtemps."
                                }
                            ]}
                            onChange={(value) => {
                                routingMethodHelpers.setValue(+value)
                            }} />
                    </EntityFormField>
                    <EntityFormField label="Routage basé sur la présence" field={presenceBasedRouting} info="Si vous activez cette option, seul les agents au statut disponible dans Teams recevront les appels (Désactivé par défaut).">
                        <SwitchInput {...presenceBasedRouting} disabled={readonly || routingMethod.value === 3} />
                        {routingMethod.value === 3 &&
                            <div className="small mt-1 text-muted lh-1">
                                Le Routage basé sur la présence ne peut pas être activé si la méthode de routage 'Le plus longuement inactif' est sélectionnée
                            </div>
                        }
                    </EntityFormField>
                    <EntityFormField label="Les agents d'appel peuvent choisir de ne pas prendre d'appels" field={allowOptOut} info="Si vous désactivez cette option et qu'un agent reçoit un appel, il devra répondre. Si vous l'activez, chaque agent d'appel peut choisir de répondre ou non à un appel entrant dans les paramètres de Teams en activant ou pas la file d’attente.">
                        <SwitchInput {...allowOptOut} disabled={readonly} />
                    </EntityFormField>
                    <EntityFormField label="Durée de l'alerte d'appel de l'agent" field={agentAlertTime}>
                        <RangeInput
                            {...agentAlertTime}
                            disabled={readonly}
                            min={15}
                            max={180}
                            valueFormatter={DateTimeUtils.humanizeDuration}
                        />
                    </EntityFormField>
                </TabPane>
                <TabPane tabId={3}>
                    <EntityFormField label="Nombre maximal d'appels dans la file d'attente" field={overflowThreshold}>
                        <RangeInput
                            {...overflowThreshold}
                            disabled={readonly}
                            min={0}
                            max={200}
                            valueFormatter={(value) => <span>{value} appel(s)</span>}
                        />
                    </EntityFormField>
                    <EntityFormField label="Lorsque le nombre maximal d'appels est atteint" field={overflowThresholdOverrun}>
                        <p className="mb-3">
                            Lorsque le nombre maximal d'appels dans la file d'attente est atteint, les appels suivants sont déconnectés ou transférés en fonction de votre sélection.
                        </p>
                        <CallQueueThresholdOverrunAction
                            disabled={readonly}
                            name="overflowThresholdOverrun" />
                    </EntityFormField>
                </TabPane>
                <TabPane tabId={4}>
                    <EntityFormField label="Délai d'attente maximal" field={timeoutThreshold}>
                        <RangeInput
                            {...timeoutThreshold}
                            disabled={readonly}
                            min={0}
                            max={2700}
                            step={15}
                            valueFormatter={DateTimeUtils.humanizeDuration}
                        />
                    </EntityFormField>
                    <EntityFormField label="Lors de l'expiration du délai de l'appel" field={timeoutThresholdOverrun}>
                        <p className="mb-3">
                            Si l'appel ne reçoit aucune réponse au cours du temps d'attente maximal, il sera déconnecté ou transféré en fonction de ce que vous sélectionnez.
                        </p>
                        <CallQueueThresholdOverrunAction
                            disabled={readonly}
                            name="timeoutThresholdOverrun" />
                    </EntityFormField>
                </TabPane>
            </TabContent>
        </ScopeProvider>
    );
}