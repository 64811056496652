import { useParams } from 'react-router-dom';
import { Api } from './../../services/Api';
import { EntityForm } from '../EntityForm';
import { AgentEditor } from './AgentEditor';
import { agentSchema } from './AgentValidation';
import { UserRoles } from '../../services/UserRoles';

export function AgentDetail() {
    const { id } = useParams();

    const newEntity = ({ user }) => {
        return {
            scope: user.scope,
            name: '',
            upn: '',
        };
    };

    return (
        <EntityForm
            id={id}
            pageTitle="Agents"
            validationSchema={agentSchema}
            listUrl="/admin/agents/"
            getEntityDisplay={({ value }) => value?.name || ''}
            newEntity={newEntity}
            getEntity={({ id }) => Api.getAgent(id)}
            createEntity={({ value }) => Api.createAgent(value)}
            updateEntity={({ value }) => Api.updateAgent(value.id, value)}
            removeEntity={({ id }) => Api.removeAgent(id)}
            canEdit={({ user }) => user.role >= UserRoles.ADMIN}
            canDelete={({ user }) => user.role >= UserRoles.ADMIN}>
            {(props) => <AgentEditor  {...props} />}
        </EntityForm>
    );
}