import { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useUser } from './../services/UserContext';
import { Avatar } from './Avatar';
import { UserAccessTokenModal } from './UserAccessTokenModal';
import { UserRoles } from '../services/UserRoles';

export const UserStatus = ({ direction, name }) => {
    const [userStatusExpanded, setUserStatusExpanded] = useState(false);
    const [showAccessTokenModal, setShowAccessTokenModal] = useState(false);
    const user = useUser();
    const toggle = () => setUserStatusExpanded(!userStatusExpanded);
    const logout = () => window.location.href = "/api/accounts/logout";

    const toggleAccessTokenModal = () => {
        setShowAccessTokenModal(!showAccessTokenModal);
    }

    if (!user) return '';

    return (
        <Dropdown className="user-select-none" isOpen={userStatusExpanded} toggle={toggle} direction={direction ?? "down"} title={user.firstName + " " + user.lastName}>
            <DropdownToggle data-toggle="dropdown" tag="div" className="dropdown-toggle cursor-pointer p-2 py-2">
                <Avatar user={user} className="avatar rounded-circle" />
                {name && <span className="px-2 mx-auto">{user.firstName} {user.lastName}</span>}
            </DropdownToggle>
            <DropdownMenu className="mt-2 pt-0">
                <a className="px-3 py-4 d-block no-decoration" href={"/me"}>
                    <div className="text-center mb-2">
                        <Avatar user={user} className="avatar rounded-circle" style={{ width: "40px" }} />
                    </div>
                    <div className="text-center">
                        <div>{user.firstName} {user.lastName}</div>
                        <div className="text-muted">{user.upn}</div>
                    </div>
                </a>
                {user.role === UserRoles.SUPERADMIN &&
                    <>
                        <div className="dropdown-divider my-0"></div>
                        <div className="dropdown-item cursor-pointer py-2" onClick={toggleAccessTokenModal}>
                            <i className="bi-key me-3"></i>
                            <span>Jeton d'accès personnel</span>
                        </div>
                    </>
                }
                <div className="dropdown-divider my-0"></div>
                <div className="dropdown-item cursor-pointer py-2" onClick={logout}>
                    <i className="bi-power me-3"></i>
                    <span className="text-danger">Déconnexion</span>
                </div>
            </DropdownMenu>
            {showAccessTokenModal &&
                <UserAccessTokenModal isOpen={showAccessTokenModal} toggle={toggleAccessTokenModal} />
            }
        </Dropdown>
    );
};