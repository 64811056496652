import * as yup from 'yup';
import { scopeSchema } from '../scopes/ScopeValidation';

export const resourceAccountSchema = yup.object().shape({
    scope: scopeSchema.required('Requis'),
    name: yup.string().required('Requis'),
    upn: yup.string().required('Requis'),
    tenant: yup.object().nullable()
        .when('orgUnit', (v, s) => v ? s.required('Requis') : s)
        .when('subOrgUnit', (v, s) => v ? s.required('Requis') : s),
    orgUnit: yup.object().nullable()
        .when('subOrgUnit', (v, s) => v ? s.required('Requis') : s),
    subOrgUnit: yup.object().nullable(),
});