const isNullOrEmpty = (input) => input == null || input.length === 0;

export const mergeClassNames = (a, b) => {
    if (!isNullOrEmpty(a) && !isNullOrEmpty(b)) {
        return a + " " + b;
    } else if (!isNullOrEmpty(a)) {
        return a;
    } else if (!isNullOrEmpty(b)) {
        return b;
    } else {
        return null;
    }
};