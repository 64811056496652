import { Link } from "react-router-dom";
import { UserLevels } from "../../services/UserLevels";
import { UserRoles } from "../../services/UserRoles";
import { Api } from './../../services/Api';
import { EntityList } from './../EntityList';

export const Tenants = () => {
    return (
        <EntityList
            pageTitle="Tenants"
            getEntities={(params) => Api.getTenants(params)}
            entityCreationUrl="/admin/tenants/new"
            canAdd={({ user }) => user && user.role >= UserRoles.SUPERADMIN && user.level >= UserLevels.ROOT}
            hideTenantFilter={true}
            hideOrgUnitFilter={true}
            hideSubOrgUnitFilter={true}
            columns={[{
                sortBy: 'name',
                header: "Nom",
                renderCell: (o) => <Link to={`/admin/tenants/${o.id}`}>{o.name}</Link>,
            },
            {
                sortBy: 'ozmoCode',
                header: "Code Ozmo",
                renderCell: (o) => o.ozmoCode,
            },
            {
                sortBy: 'azureDomain',
                header: "Domaine Azure",
                renderCell: (o) => o.azureDomain,
            }]} />
    );
}
