import { FormGroup, Input, Label } from 'reactstrap';

export const CheckboxList = ({
    name,
    value,
    onChange,
    options,
    className,
    disabled
}) => {
    
    const onInputChange = (event) => {
        const optionValue = +event.target.value;
        let newValue = [];
        if (value.indexOf(optionValue) >= 0) {
            newValue = value.filter(o => o !== optionValue);
        } else {
            newValue = [...value, optionValue];
        }
        if (onChange) onChange(newValue);
    }

    const getOptionId = (index) => name + '.' + index;

    return (
        <div {...{ className }}>
            {options && options.map((option, index) => (
                <FormGroup
                    key={getOptionId(index)}
                    check
                    className="mt-1">
                    <Input
                        type="checkbox"
                        id={getOptionId(index)}
                        name={name}
                        checked={value.indexOf(option.value) >= 0}
                        value={option.value}
                        onChange={onInputChange}
                        disabled={disabled}
                    />
                    {' '}
                    <Label className="user-select-none" check htmlFor={getOptionId(index)}>{option.label}</Label>
                </FormGroup>
            ))}
        </div>
    );
}