import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { Prompt } from 'react-router';

const useBeforeunload = (fn) => {
    useEffect(() => {
        window.addEventListener("beforeunload", fn);
        return () => window.removeEventListener("beforeunload", fn);
    }, [fn]);
}

export const FormDataLossGuard = () => {
    const { dirty } = useFormikContext();

    const ensureNoDataLoss = () => {
        if (dirty) {
            return "En quittant cette page, les modifications effectuées seront perdues";
        } else {
            return true;
        }
    }

    useBeforeunload((event) => {
        var msg = ensureNoDataLoss();
        if (msg !== true) {
            event.returnValue = msg;
            return msg;
        }
    });

    return <Prompt message={() => ensureNoDataLoss()} />;
};