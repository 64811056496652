import { useField } from 'formik';
import { Input } from 'reactstrap';
import { ScopeProvider } from '../../services/ScopeContext';
import { EntityFormField } from '../EntityFormField';
import { ScopeEditor } from '../ScopeEditor';
import { SharedVoicemailAgents } from './SharedVoicemailAgents';

export const SharedVoicemailEditor = ({ id, readonly }) => {
    const [scope] = useField('scope');
    const [name] = useField('name');
    const [agents, ,agentsHelpers] = useField('agents');

    return (
        <ScopeProvider value={scope.value}>
            <ScopeEditor readonly={id > 0} />
            <EntityFormField label="Nom" field={name}>
                <Input {...name} bsSize="sm" disabled={readonly} />
            </EntityFormField>
            <EntityFormField label="Membres" field={agents}>
                <SharedVoicemailAgents
                    disabled={readonly}
                    value={agents.value}
                    onChange={(value) => {
                        agentsHelpers.setTouched(true);
                        agentsHelpers.setValue(value);
                    }} />
            </EntityFormField>
        </ScopeProvider>
    );
}