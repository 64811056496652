import { Link } from "react-router-dom";
import { EntityList } from "../EntityList";
import { Api } from './../../services/Api';

export const AutoAttendants = () => {

    return (
        <EntityList
            pageTitle="Standards"
            getEntities={(params) => Api.getAutoAttendants(params)}
            entityCreationUrl="/autoattendants/new"
            columns={[{
                sortBy: 'Name',
                header: "Nom",
                renderCell: (o) => <Link to={`/autoattendants/${o.id}`}>{o.name}</Link>,
            }, {
                sortBy: 'Bypass',
                header: "Renvoi activé",
                renderCell: (o) => o.bypass ? <div className="badge bg-warning text-dark">Oui</div> : 'Non',
            }]}
            header={() =>
                <>
                    Les standards permettent aux personnes qui appellent de parcourir un système de menus afin d'être mises en lien avec le service, la personne ou l'opérateur approprié au sein de votre organisation.
                    Lorsque vous créez un standard, vous choisissez les messages d'accueil, les menus et la redirection des appels.
                </>
            }
        />
    );
}
