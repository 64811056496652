import { Link } from "react-router-dom";
import { EntityList } from "../EntityList";
import { Api } from './../../services/Api';

export const CallQueues = () => {
    return (
        <EntityList
            pageTitle="Groupes d'agents"
            getEntities={(params) => Api.getCallQueues(params)}
            entityCreationUrl="/callqueues/new"
            columns={[{
                sortBy: 'name',
                header: "Nom",
                renderCell: (o) => <Link to={`/callqueues/${o.id}`}>{o.name}</Link>,
            }, {
                sortBy: 'nbAgents',
                header: "Nombre d'agents",
                renderCell: (o) => o.nbAgents,
            }]}
            header={() =>
                <>
                    Les files d'attente d'appels fournissent une méthode de routage des appelants vers les membres de votre organisation qui peuvent vous aider à résoudre un problème ou une question particulière.
                    Les appels sont distribués l'un après l'autre aux personnes de la file d'attente (appelées agents).
                    En attendant un agent, un message d'accueil et des musiques peuvent être lus aux appelants.
                </>
            }
        />
    );
}
