import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Env } from '../services/Env';
import { isArray, isObject, isString } from '../services/Utils';
import './PageTitle.css';

export const PageTitle = ({ className, path }) => {
    const [value, setValue] = useState([]);

    useEffect(() => {
        let segments = [];
        if (path) {
            const addSegment = (label, url) => segments.push({ label, url });

            if (isArray(path)) {
                path.forEach((segment) => {
                    if (segment) {
                        if (isString(segment)) {
                            addSegment(segment);
                        } else if (isObject(segment) && !isArray(segment) && segment.hasOwnProperty('label')) {
                            addSegment(segment.label, segment.url);
                        } else {

                        }
                    }
                });
            } else if (isString(path)) {
                addSegment(path);
            }
        }
        setValue(segments);

        let title = Env.basePageTitle;
        segments.forEach((segment) => title += " > " + segment.label);
        if (document.title !== title) document.title = title;

    }, [path]);   

    return <h1 className="header-title lh-1">
        {value.map((segment, index) =>
            <span key={index}>
                {index > 0 && <>{' '}&gt;{' '}</>}
                {segment.url && <Link to={segment.url}>{segment.label}</Link>}
                {!segment.url && segment.label}
            </span>
        )}
    </h1>;
};