import PropTypes from "prop-types";
import { useState } from 'react';
import { Button, Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import { Env } from "../services/Env";

export const Debugger = ({ title, isOpenByDefault, children }) => {
    const [show, setShow] = useState(isOpenByDefault);

    const toggle = () => {
        setShow(!show);
    }

    const stopPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    if (!Env.allowDebugger) return null;

    return (
        <Button
            type="button"
            color="dark"
            size="md"
            className="btn-fab"
            onClick={toggle}
            title="Show debugger">
            <i className="bi-bug" />
            <Offcanvas
                direction="end"
                fade={false}
                backdrop={false}
                isOpen={show}
                toggle={toggle}
                onClick={stopPropagation}>
                <OffcanvasHeader className="border-bottom" toggle={toggle}>{title}</OffcanvasHeader>
                <OffcanvasBody className="px-3 lh-1">
                    {children}
                </OffcanvasBody>
            </Offcanvas>
        </Button>
    );
}

Debugger.propTypes = {
    title: PropTypes.string,
    isOpenByDefault: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

Debugger.defaultProps = {
    title: "Debug",
    isOpenByDefault: false
};