import { FormGroup, Input, Label } from 'reactstrap';
import { Info } from './Info';

export const RadioList = ({
    name,
    value,
    onChange,
    options,
    className,
    disabled
}) => {

    const onInputChange = (event) => onChange && onChange(event.target.value);
    
    const getOptionId = (index) => name + '.' + index;
    const getOptionInfoId = (index) => name + '.' + index + '.info';

    return (
        <div {...{ className }}>
            {options && options.map((option, index) => (
                <FormGroup
                    key={getOptionId(index)}
                    check
                    className="mt-1">
                    <Input
                        type="radio"
                        id={getOptionId(index)}
                        name={name}
                        checked={value === option.value}
                        value={option.value}
                        onChange={onInputChange}
                        disabled={disabled} />
                    {' '}
                    <Label className="user-select-none" check htmlFor={getOptionId(index)}>{option.label}</Label>
                    {option.info && <Info id={getOptionInfoId(index)}>{option.info}</Info>}
                    {value === option.value && option.children && <div className="mt-2">{option.children()}</div>}
                </FormGroup>
            ))}
        </div>
    );
}