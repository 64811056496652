import { useEffect, useState } from 'react';

export const Avatar = ({ user, ...props }) => {
    const [text, setText] = useState('');
    const [color, setColor] = useState([0, 0, 0]);

    useEffect(() => {

        const text = getText(user);
        const color = getColor(text);
        setText(text);
        setColor(color);

    }, [user]);

    const getText = (user) => {
        let text = '';
        if (user && user.firstName && user.firstName.length > 0) text += user.firstName[0];
        if (user && user.lastName && user.lastName.length > 0) text += user.lastName[0];
        return text;
    };

    const getColor = (text) => {
        if (text && text.length > 0) {
            let index = 0;
            let char = text.toUpperCase().charCodeAt(0);
            if (char >= 65 && char <= 90) {
                index = (char - 65) % Avatar.ColorPalette.length;
            } else {
                index = char % Avatar.ColorPalette.length;
            }
            return Avatar.ColorPalette[index % Avatar.ColorPalette.length];
        } else {
            return [0, 0, 0];
        }
    }

    return (
        <svg viewBox="0 0 100 100" {...props}>
            <rect x="0" y="0" width="100" height="100" fill={`rgb(${color[0]}, ${color[1]}, ${color[2]})`} />
            <text x="50" y="67" textAnchor="middle" fontSize="3em" fontFamily="Arial" fill="white">{text}</text>
        </svg>
    );
}

Avatar.ColorPalette = [
    [226, 95, 81],    // A
    [242, 96, 145],   // B
    [187, 101, 202],  // C
    [149, 114, 207],  // D
    [120, 132, 205],  // E
    [91, 149, 249],   // F
    [72, 194, 249],   // G
    [69, 208, 226],   // H
    [72, 182, 172],   // I
    [82, 188, 137],   // J
    [155, 206, 95],   // K
    [212, 227, 74],   // L
    [254, 218, 16],   // M
    [247, 192, 0],    // N
    [255, 168, 0],    // O
    [255, 138, 96],   // P
    [194, 194, 194],  // Q
    [143, 164, 175],  // R
    [162, 136, 126],  // S
    [163, 163, 163],  // T
    [175, 181, 226],  // U
    [179, 155, 221],  // V
    [194, 194, 194],  // W
    [124, 222, 235],  // X
    [188, 170, 164],  // Y
    [173, 214, 125]   // Z
];
