import { Nav, NavItem, NavLink } from 'reactstrap';

export const EntityFormTabs = ({ tabs, activeTab, activateTab }) => {
    return (
        <Nav tabs className="flex-nowrap overflow-x-auto overflow-y-hidden" style={{ marginBottom: "-1px" }}>
            {tabs.map((tab, index) => (
                <NavItem key={index}>
                    <NavLink
                        href="#"
                        className="text-nowrap"
                        active={activeTab === index}
                        onClick={() => activateTab(index)}>{tab}</NavLink>
                </NavItem>
            ))}
        </Nav>
    );
}