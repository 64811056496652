import { useParams } from 'react-router-dom';
import { Api } from '../../services/Api';
import { EntityForm } from '../EntityForm';
import { SharedVoicemailEditor } from './SharedVoicemailEditor';
import { sharedVoicemailSchema } from './SharedVoicemailValidation';

export function SharedVoicemailDetail() {
    const { id } = useParams();

    const newEntity = ({ user }) => {
        return {
            scope: user.scope,
            name: '',
            agents: [],
        };
    };

    return (
        <EntityForm
            id={id}
            pageTitle="Boites vocales"
            validationSchema={sharedVoicemailSchema}
            listUrl="/sharedvoicemails/"
            getEntityDisplay={({ value }) => value?.name || ''}
            newEntity={newEntity}
            getEntity={({ id }) => Api.getSharedVoicemail(id)}
            createEntity={({ value }) => Api.createSharedVoicemail(value)}
            updateEntity={({ value }) => Api.updateSharedVoicemail(value.id, value)}
            removeEntity={({ id }) => Api.removeSharedVoicemail(id)}
            isSync>
            {(props) => <SharedVoicemailEditor {...props} />}
        </EntityForm>
    );
}