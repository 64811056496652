import * as yup from 'yup';
import { DateTimeUtils } from '../../services/DateTimeUtils';
import { scopeSchema } from '../scopes/ScopeValidation';

const endBeforeStartTestOptions = {
    message: "La date de fin doit être postérieure à la date de début",
    test: (value, context) => {
        return context.parent.start == null || value == null || context.parent.start < value;
    }
};

const dateRangesLengthTestOptions = {
    message: `Entre 1 et 10 éléments sont requis`,
    test: (value, context) => {
        const array = context.parent.dateRanges;
        return array && Array.isArray(array) && array.length >= 1 && array.length <= 10;
    }
};

const dateRangesConflictTestOptions = {
    message: `Certaines périodes sont en conflit`,
    test: (value, context) => {

        const dateRanges = context.parent.dateRanges;

        let conflicts = new Map();
        if (Array.isArray(dateRanges)) {

            let intervals = [];
            dateRanges.forEach((dateRange, i) => {

                const isoStart = DateTimeUtils.convertDateToIso(dateRange.start);
                const isoEnd = DateTimeUtils.convertDateToIso(dateRange.end);

                if (isoStart && isoEnd) {

                    let conflictIndexes = [];
                    intervals.forEach((interval, j) => {

                        const intervalIsoStart = DateTimeUtils.convertDateToIso(interval.start);
                        const intervalIsoEnd = DateTimeUtils.convertDateToIso(interval.end);

                        if (isoStart <= intervalIsoEnd && isoEnd >= intervalIsoStart) {
                            conflictIndexes.push(j);
                        }
                    });

                    if (conflictIndexes.length) {
                        conflicts.set(i, conflictIndexes);
                    } else {
                        intervals.push(dateRange);
                    }
                }
            });
        }

        return conflicts.size === 0;
    }
};

const dateRangeSchema = yup.object().nullable().shape({
    start: yup.date().typeError('Date invalide').nullable().required('Requis'),
    end: yup.date().typeError('Date invalide').nullable().required('Requis').test(endBeforeStartTestOptions),
});

export const holidaySchema = yup.object().shape({
    scope: scopeSchema.required('Requis'),
    isFrenchBankHoliday: yup.bool(),
    frenchBankHoliday: yup.string().nullable().when('isFrenchBankHoliday', (v, s) => v ? s.required('Requis') : s),
    name: yup.string().nullable().when('isFrenchBankHoliday', (v, s) => !v ? s.required('Requis') : s),
    dateRanges: yup.array().of(dateRangeSchema).nullable().when('isFrenchBankHoliday', (v, s) => !v ? s.required('Requis') : s),
    dateRangesLength: yup.bool().when('isFrenchBankHoliday', (v, s) => !v ? s.test(dateRangesLengthTestOptions) : s),
    dateRangesConflict: yup.bool().when('isFrenchBankHoliday', (v, s) => !v ? s.test(dateRangesConflictTestOptions) : s)
});