import { UserLevels } from '../../services/UserLevels';
import { UserRoles } from '../../services/UserRoles';
import { EntityForm } from '../EntityForm';
import { Api } from './../../services/Api';
import { UserEditor } from './UserEditor';
import { userSchema } from './UserValidation';

export const UserDetail = ({ id }) => {

    const newEntity = ({ user }) => {
        return {
            upn: '',
            firstName: '',
            lastName: '',
            enabled: true,
            role: UserRoles.READER,
            level: Math.min(user.level, UserLevels.ORGUNIT),
            tenant: user.scope?.tenant,
            orgUnit: user.scope?.orgUnit,
            subOrgUnit: user.scope?.subOrgUnit,
        };
    };

    return (
        <EntityForm
            id={id}
            pageTitle="Utilisateurs"
            listUrl="/admin/users/"
            validationSchema={userSchema}
            getEntityDisplay={({ value }) => value ? (value.firstName + ' ' + value.lastName) : ''}
            newEntity={newEntity}
            getEntity={({ id }) => Api.getUser(id)}
            createEntity={({ value }) => Api.createUser(value)}
            updateEntity={({ value }) => Api.updateUser(value.id, value)}
            removeEntity={({ id }) => Api.removeUser(id)}
            canEdit={({ user, value }) => user && user.role >= UserRoles.ADMIN && value.id !== user.id && value.id !== 1}
            canDelete={({ user, value }) => user && user.role >= UserRoles.ADMIN && value.id !== user.id && value.id !== 1}>
            {(props) => <UserEditor {...props} />}
        </EntityForm>
    );
}