import { Fragment } from 'react';

import { useField } from 'formik';
import { Input } from 'reactstrap';
import { Config } from '../../services/Config';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { EntityFormField } from '../EntityFormField';

export const AutoAttendantBusinessHours = ({ name, value, onChange, disabled }) => {
    const monday = useField(name + '.monday');
    const tuesday = useField(name + '.tuesday');
    const wednesday = useField(name + '.wednesday');
    const thursday = useField(name + '.thursday');
    const friday = useField(name + '.friday');
    const saturday = useField(name + '.saturday');
    const sunday = useField(name + '.sunday');

    const mondayConflict = useField(name + '.mondayConflict');
    const tuesdayConflict = useField(name + '.tuesdayConflict');
    const wednesdayConflict = useField(name + '.wednesdayConflict');
    const thursdayConflict = useField(name + '.thursdayConflict');
    const fridayConflict = useField(name + '.fridayConflict');
    const saturdayConflict = useField(name + '.saturdayConflict');
    const sundayConflict = useField(name + '.sundayConflict');

    const dayFields = [monday, tuesday, wednesday, thursday, friday, saturday, sunday];

    const conflictFields = [mondayConflict, tuesdayConflict, wednesdayConflict, thursdayConflict, fridayConflict, saturdayConflict, sundayConflict];

    const days = Config.getDays();

    const resetAllBusinessHours = () => {
        var newValue = { ...value };

        var defaultBusinessWeekHours = Config.getDefaultBusinessWeekHours();
        for (var i = 0; i < days.length; i++) {
            var day = days[i].value;
            newValue[day] = defaultBusinessWeekHours[day];
        }
        notify(newValue);
    }

    const removeAllBusinessHours = () => {
        var newValue = { ...value };
        const days = Config.getDays();
        for (var i = 0; i < days.length; i++) {
            newValue[days[i].value] = [];
        }
        notify(newValue);
    }

    const removeBusinessHours = (day, index) => {
        var newValue = { ...value };
        newValue[day].splice(index, 1);
        notify(newValue);
    }

    const addBusinessHours = (day) => {
        var newValue = { ...value };
        newValue[day].push({ start: 0, end: 24 * 60 });
        notify(newValue);
    }

    const notify = (value) => {
        onChange && onChange(value);
    }

    return (
        <div className="card shadow-sm">
            <div className="px-3 pt-3 pb-0 bg-light border-bottom card-header">
                <div className="d-flex flex-column flex-sm-row">
                    <div className="pb-3">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={resetAllBusinessHours}
                            disabled={disabled}>
                            <i className="bi-arrow-90deg-left pe-1"></i>
                            Rétablir les valeurs par défaut
                        </button>
                    </div>
                    <div className="pb-3">
                        <button
                            type="button"
                            className="btn btn-sm btn-danger ms-sm-3"
                            onClick={removeAllBusinessHours}
                            disabled={disabled}>
                            <i className="bi-x-lg pe-1"></i>
                            Effacer toutes les heures
                        </button>
                    </div>
                </div>
            </div>
            <div className="table-responsive">
                <table className='table m-0'>
                    <colgroup>
                        <col />
                        <col />
                        <col />
                        <col width="40px" />
                        <col width="160px" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th className="ps-3">Jour</th>
                            <th>Démarrer à</th>
                            <th>Terminer à</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dayFields && dayFields.map((dayField, index) => {

                            const day = days[index];
                            const [field] = dayField;
                            const [conflict, conflictMeta] = conflictFields[index];
                            const hasConflict = conflictMeta && conflictMeta.error;

                            const rowSpan = Math.max(1, field.value.length) + (hasConflict ? 1 : 0);
                            const even = index % 2 === 0;
                            const rowClassName = even ? 'bg-light' : '';

                            const dayCell = (
                                <th rowSpan={rowSpan} className="ps-3 pt-2">
                                    {day.label}
                                </th>
                            );

                            const addCell = (
                                <td rowSpan={rowSpan}>
                                    {!disabled &&
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-outline-primary"
                                            onClick={() => addBusinessHours(day.value)}> + Ajouter des horaires</button>
                                    }
                                </td>
                            );

                            return (
                                <Fragment key={index}>
                                    {field.value.map((range, rangeIndex) => {

                                        const key = day.value + "." + rangeIndex;
                                        const isFirst = rangeIndex === 0;
                                        const isLast = rangeIndex === field.value.length - 1;
                                        const rangeName = name + '.' + day.value + '[' + rangeIndex + ']';


                                        let rangeClassName = isLast && !hasConflict ? 'border-bottom-1' : 'border-bottom-0';
                                        if (field.value.length > 1 && !isLast) {
                                            rangeClassName += ' pb-0';
                                        }

                                        return (
                                            <tr key={key} className={rowClassName}>
                                                {isFirst && dayCell}
                                                <Range
                                                    name={rangeName}
                                                    tdClassName={rangeClassName}
                                                    remove={() => removeBusinessHours(day.value, rangeIndex)}
                                                    disabled={disabled}
                                                />
                                                {isFirst && addCell}
                                            </tr>
                                        );
                                    })}
                                    {field.value.length === 0 && (
                                        <tr className={rowClassName}>
                                            {dayCell}
                                            <td className="pt-2">Fermé</td>
                                            <td className="pt-2">Fermé</td>
                                            <td className="pt-2"></td>
                                            {addCell}
                                        </tr>
                                    )}
                                    {hasConflict && (
                                        <tr className={rowClassName}>
                                            <td colSpan="3" className="border-top-0">
                                                <FieldErrorMessage name={conflict.name} />
                                            </td>
                                        </tr>
                                    )}
                                </Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}



const Range = ({ name, remove, tdClassName, disabled }) => {
    const [start, , startHelpers] = useField(name + '.start');
    const [end, , endHelpers] = useField(name + '.end');
    const startTimeValues = Config.getStartTimeValues();
    const endTimeValues = Config.getEndTimeValues();

    return (
        <>
            <td className={tdClassName}>
                <EntityFormField field={start}>
                    <Input {...start}
                        bsSize="sm"
                        style={{ minWidth: "80px" }}
                        type="select"
                        placeholder="Choisissez une valeur"
                        onChange={(e) => startHelpers.setValue(+e.target.value)}
                        disabled={disabled}>
                        {startTimeValues.map(time =>
                            <option
                                key={time.value}
                                value={time.value}>{time.label}</option>
                        )}
                    </Input>
                </EntityFormField>
            </td>
            <td className={tdClassName}>
                <EntityFormField field={end}>
                    <Input {...end}
                        bsSize="sm"
                        style={{ minWidth: "80px" }}
                        type="select"
                        placeholder="Choisissez une valeur"
                        onChange={(e) => endHelpers.setValue(+e.target.value)}
                        disabled={disabled}>
                        {endTimeValues.map(time =>
                            <option
                                key={time.value}
                                value={time.value}>{time.label}</option>
                        )}
                    </Input>
                </EntityFormField>
            </td>
            <td className={tdClassName}>
                {!disabled &&
                    <button
                        type="button"
                        className="btn btn-sm btn-danger text-center"
                        onClick={remove}>
                        <i className="bi-trash"></i>
                    </button>
                }
            </td>
        </>
    );
}