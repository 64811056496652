import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Spinner } from 'reactstrap';

export const Loader = ({ message, delay, color, size, className }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setVisible(true), delay);
        return () => clearTimeout(timeout);
    }, [message, delay]);

    if (!visible) return '';

    return (
        <div className={className}>
            <Spinner color={color} size={size} className="me-2" />
            <em>{message}</em>
        </div>
    );
}

Loader.propTypes = {
    message: PropTypes.string,
    delay: PropTypes.number,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string
};

Loader.defaultProps = {
    message: "En cours de chargement ...",
    delay: 200,
    color: "primary",
    size: "sm"
};