import PropTypes from "prop-types";
import { UncontrolledTooltip } from 'reactstrap';
import { mergeClassNames } from "../services/ComponentHelper";
import { idSafer } from './../services/IdSafer';

export const Info = ({ id, className, children }) => {
    const safeId = idSafer(id);

    return (
        <>
            <small
                id={safeId}
                className={mergeClassNames(className, "bi-info-circle-fill text-info ms-2 info")} />
            <UncontrolledTooltip
                target={safeId}
                placement="bottom"
                trigger="hover"
                autohide={false}>{children}</UncontrolledTooltip>
        </>
    );
}

Info.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]).isRequired,
    className: PropTypes.string
};

Info.defaultProps = {
    className: ''
};