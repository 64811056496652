import PropTypes from "prop-types";
import { mergeClassNames } from "../services/ComponentHelper";
import { useScope } from "../services/ScopeContext";
import { ValuesJoiner } from "./ValuesJoiner";

const EntityThumbnailScope = ({ scope }) => {
    const currentScope = useScope();

    if (!scope) return null;

    let values = [];

    if (currentScope != null) {
        if (scope.tenant?.name != null) {
            if (currentScope.tenant?.name === scope.tenant?.name) {
                // noop
            } else {
                values.push(scope.tenant.name);
            }
        }

        if (scope.orgUnit?.name != null) {
            if (currentScope.orgUnit?.name === scope.orgUnit?.name) {
                // noop
            } else {
                values.push(scope.orgUnit.name);
            }
        }

        if (scope.subOrgUnit?.name != null) {
            if (currentScope.subOrgUnit?.name === scope.subOrgUnit?.name) {
                // noop
            } else {
                values.push(scope.subOrgUnit.name);
            }
        }
    }

    if (values.length === 0) return null;


    return (
        <div className="pt-1">
            <small className="badge bg-light text-dark border">
                <ValuesJoiner tag="span" values={values} separator=" / " />
            </small>
        </div>
    );
};

export const EntityRefThumbnail = ({ value, className }) => {
    return (    
        <div className={mergeClassNames(className, "lh-1")}>
            <div>{value.name ?? ''}</div>
            {value.upn && <small className="text-muted">{value.upn}</small>}
            <EntityThumbnailScope scope={value.scope} />
        </div>
    );
}

EntityRefThumbnail.propTypes = {
    value: PropTypes.shape({
        name: PropTypes.string,
        upn: PropTypes.string
    }).isRequired,
    className: PropTypes.string
};