import { useField } from 'formik';
import { useEffect } from 'react';
import { Input } from 'reactstrap';
import { Api } from '../../services/Api';
import { useUser } from '../../services/UserContext';
import { Autocomplete } from '../Autocomplete';
import { EntityFormField } from '../EntityFormField';

export const SubOrgUnitEditor = ({ id, readonly }) => {
    const [name] = useField('name');
    const [orgUnit, , orgUnitHelpers] = useField('orgUnit');
    const [tenant, , tenantHelpers] = useField('tenant');
    const user = useUser();

    useEffect(() => {
        if (!tenant.value && orgUnit.value) orgUnitHelpers.setValue(null);
    }, [tenant.value, orgUnit.value, orgUnitHelpers]);


    return (
        <div>
            <EntityFormField label="Tenant" field={tenant}>
                <Autocomplete
                    {...tenant}
                    disabled={readonly || id > 0 || user.scope.tenant}
                    getMatches={async (params) => (await Api.getTenants(params)).values}
                    size="sm"
                    onChange={(value) => {
                        tenantHelpers.setTouched(true);
                        tenantHelpers.setValue(value);
                    }} />
            </EntityFormField>
            <EntityFormField label="Organisation" field={orgUnit}>
                <Autocomplete
                    {...orgUnit}
                    getMatches={async (params) => (await Api.getOrgUnits({ ...params, tenantId: tenant.value?.id })).values}
                    size="sm"
                    disabled={readonly || id > 0 || tenant.value == null || user.scope.orgUnit}
                    onChange={(value) => {
                        orgUnitHelpers.setTouched(true);
                        orgUnitHelpers.setValue(value);
                    }} />
            </EntityFormField>
            <EntityFormField label="Nom" field={name}>
                <Input {...name} bsSize="sm" disabled={readonly} />
            </EntityFormField>
        </div>
    );
}