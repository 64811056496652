const CONSTANTS = {
    API_BASE_URL: './api/',
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
};

const jsonHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

const request = (url, method, body, isJson) => {

    const abortController = new AbortController();

    const options = {
        method,
        headers: isJson ? jsonHeaders : undefined,
        body: body && isJson ? JSON.stringify(body) : body,
        signal: abortController.signal
    };

    const promise = new Promise((resolve, reject) => {
        fetch(CONSTANTS.API_BASE_URL + url, options)
            .then((response) => {
                if (response.ok) {
                    response.json()
                        .then(data => resolve(data))
                        .catch(() => resolve({ message: "Unable to parse response as json" }));
                } else {
                    response.json()
                        .then(error => reject({ status: response.status, message: response.statusText, error }))
                        .catch(() => reject({ status: response.status, message: response.statusText }));
                }
            }).catch((error) => {
                if (error && error.name === "AbortError") resolve();
                else reject(error);
            });
    });

    promise.aborted = false;
    promise.abort = () => {
        abortController.abort();
        promise.aborted = true;
    };

    return promise;
}

const get = (url, isJson = true) => request(url, CONSTANTS.GET, null, isJson);
const create = (url, body, isJson = true) => request(url, CONSTANTS.POST, body, isJson);
const update = (url, body, isJson = true) => request(url, CONSTANTS.PUT, body, isJson);
const remove = (url, isJson = true) => request(url, CONSTANTS.DELETE, null, isJson);

const buildUrlWithParams = (url, params) => {
    const { noPaging, pageIndex, pageSize, query, ...paramsRest } = params;

    let search = new URLSearchParams();
    search.set('pageIndex', pageIndex || 0);
    search.set('pageSize', pageSize || 20);
    search.set('query', query || '');

    for (const prop in paramsRest) {
        if (paramsRest[prop] != null) {
            search.set(prop, paramsRest[prop]);
        }
    }

    return `${url}?${search}`;
}

export const Api = {


    buildScopedParams: (params, scope) => {
        if (scope == null) throw new Error("Invalid scope");

        return {
            ...params,
            tenantId: scope.tenant?.id,
            orgUnitId: scope.orgUnit?.id,
            subOrgUnitId: scope.subOrgUnit?.id
        };
    },

    uploadFile: (file) => {
        const data = new FormData();
        data.append('File', file);
        return create('audiofiles', data, false);
    },

    getUserSession: () => get('sessions/current'),
    createPersonalAccessToken: () => create('accounts/accesstoken'),

    getAgents: (params) => get(buildUrlWithParams('agents', params)),
    lookupAgents: (params) => get(buildUrlWithParams('agents/lookup', params)),
    getAgent: (id) => get('agents/' + id),
    updateAgent: (id, o) => update('agents/' + id, o),
    removeAgent: (id) => remove('agents/' + id),
    getImportableAgents: (o) => get(buildUrlWithParams('importagents', o)),
    importAgents: (o) => create('importagents', o),

    getResourceAccounts: (params) => get(buildUrlWithParams('resourceAccounts', params)),
    lookupResourceAccounts: (params) => get(buildUrlWithParams('resourceAccounts/lookup', params)),
    getResourceAccount: (id) => get('resourceAccounts/' + id),
    updateResourceAccount: (id, o) => update('resourceAccounts/' + id, o),
    removeResourceAccount: (id) => remove('resourceAccounts/' + id),
    getImportableResourceAccounts: (o) => get(buildUrlWithParams('importResourceAccounts', o)),
    importResourceAccounts: (o) => create('importResourceAccounts', o),

    getSharedVoicemails: (params) => get(buildUrlWithParams('sharedVoicemails', params)),
    lookupSharedVoicemails: (params) => get(buildUrlWithParams('sharedVoicemails/lookup', params)),
    getSharedVoicemail: (id) => get('sharedVoicemails/' + id),
    createSharedVoicemail: (o) => create('sharedVoicemails', o),
    updateSharedVoicemail: (id, o) => update('sharedVoicemails/' + id, o),
    removeSharedVoicemail: (id) => remove('sharedVoicemails/' + id),

    getAutoAttendants: (params) => get(buildUrlWithParams('autoattendants', params)),
    lookupAutoAttendants: (params) => get(buildUrlWithParams('autoattendants/lookup', params)),
    getAutoAttendant: (id) => get('autoattendants/' + id),
    createAutoAttendant: (o) => create('autoattendants', o),
    updateAutoAttendant: (id, o) => update('autoattendants/' + id, o),
    removeAutoAttendant: (id) => remove('autoattendants/' + id),

    getCallQueues: (params) => get(buildUrlWithParams('callqueues', params)),
    lookupCallqueues: (params) => get(buildUrlWithParams('callqueues/lookup', params)),
    getCallQueue: (id) => get('callqueues/' + id),
    createCallQueue: (o) => create('callqueues', o),
    updateCallQueue: (id, o) => update('callqueues/' + id, o),
    removeCallQueue: (id) => remove('callqueues/' + id),

    getHolidays: (params) => get(buildUrlWithParams('holidays', params)),
    lookupHolidays: (params) => get(buildUrlWithParams('holidays/lookup', params)),
    getHoliday: (id) => get('holidays/' + id),
    createHoliday: (o) => create('holidays', o),
    updateHoliday: (id, o) => update('holidays/' + id, o),
    removeHoliday: (id) => remove('holidays/' + id),

    getUsers: (params) => get(buildUrlWithParams('users', params)),
    getUser: (id) => get('users/' + id),
    updateUser: (id, o) => update('users/' + id, o),
    removeUser: (id) => remove('users/' + id),
    getImportableUsers: (o) => get(buildUrlWithParams('importusers', o)),
    importUsers: (o) => create('importusers', o),

    getTenants: (params) => get(buildUrlWithParams('tenants', params)),
    getTenant: (id) => get('tenants/' + id),
    createTenant: (o) => create('tenants', o),
    updateTenant: (id, o) => update('tenants/' + id, o),
    removeTenant: (id) => remove('tenants/' + id),

    getOrgUnits: (params) => get(buildUrlWithParams('orgunits', params)),
    getOrgUnit: (id) => get('orgunits/' + id),
    createOrgUnit: (o) => create('orgunits', o),
    updateOrgUnit: (id, o) => update('orgunits/' + id, o),
    removeOrgUnit: (id) => remove('orgunits/' + id),

    getSubOrgUnits: (params) => get(buildUrlWithParams('suborgunits', params)),
    getSubOrgUnit: (id) => get('suborgunits/' + id),
    createSubOrgUnit: (o) => create('suborgunits', o),
    updateSubOrgUnit: (id, o) => update('suborgunits/' + id, o),
    removeSubOrgUnit: (id) => remove('suborgunits/' + id),

    getEntitySyncs: (params) => get(buildUrlWithParams('entitysyncs', params)),
    getEntitySync: (id) => get('entitysyncs/' + id),

    getTenantConfigs: (params) => get(buildUrlWithParams('tenantConfigs', params)),
    getTenantConfig: (id) => get('tenantConfigs/' + id),
};