import { useState } from 'react';
import { Spinner } from 'reactstrap';
import { Api } from './../services/Api';

export const AudioFile = ({ name, value, onChange, onBlur, disabled }) => {
    const [uploading, setUploading] = useState(false);

    const notify = (value) => {
        onChange && onChange(value);
    }

    const onDelete = () => {
        notify(null);
    }

    const onUpload = async (event) => {
        setUploading(true);

        let file = event.target.files[0];
        Api.uploadFile(file).then(data => {
            setUploading(false);
            notify(data);
        }).catch(error => {

            if (error.error && (error.error.title || error.error.detail)) {
                if (error.error.detail) alert(error.error.title + " : " + error.error.detail);
                else alert(error.title);
            } else if (error && error.status) {
                alert("Une erreur est survenue (" + error.status + ")");
            }

            setUploading(false);
            notify(null);
        });
    }

    return (
        <div>
            <div style={{ maxWidth: '400px' }}>
                {value != null &&
                    <div className="form-control form-control-sm">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="d-flex align-items-center">
                                <i className="bi-file-earmark-music pe-2 text-primary"></i>
                                {value?.name}
                            </div>
                            {!disabled &&
                                <button
                                    type="button"
                                    className="btn btn-sm btn-close"
                                    onClick={onDelete}></button>
                            }
                        </div>
                    </div>
                }
                {uploading &&
                    <div className="form-control">
                        <div className="d-flex">
                            <Spinner
                                className="me-2 mt-1"
                                color="info"
                                size="sm">&nbsp;</Spinner>
                            <div>Téléchargement en cours...</div>
                        </div>
                    </div>
                }
                {!uploading && value == null &&
                    <input
                        name={name}
                        type="file"
                        accept=".mp3, .wav, .wma"
                        className="form-control form-control-sm"
                        onChange={onUpload}
                        onBlur={onBlur}
                        disabled={disabled}
                    />
                }
            </div>
            <div className="small mt-1 text-muted lh-1">Les fichiers audio doivent être au format MP3, WAV ou WMA et avoir une taille inférieure à 5 Mo.</div>
        </div>
    );
}