import { useParams } from 'react-router-dom';
import { Api } from './../../services/Api';
import { EntityForm } from '../EntityForm';
import { CallQueueEditor } from './CallQueueEditor';
import { callQueueSchema } from './CallQueueValidation';

export function CallQueueDetail() {
    const { id } = useParams();

    const newThresholdOverrun = () => {
        return {
            action: 0,
            agent: null,
            callQueue: null,
            autoAttendant: null,
            sharedVoicemail: null,
            sharedVoicemailPrompt: null,
            enableSharedVoicemailTranscription: false,
            externalPstn: null,
        };
    };

    const newEntity = ({ user }) => {
        return {
            scope: user.scope,
            name: '',
            language: 'fr-FR',
            hasWelcomeMusic: false,
            welcomeMusic: null,
            useDefaultMusicOnHold: true,
            musicOnHold: null,
            agents: [],
            routingMethod: 0,
            presenceBasedRouting: false,
            conferenceMode: false,
            allowOptOut: false,
            agentAlertTime: 30,
            overflowThreshold: 50,
            overflowThresholdOverrun: newThresholdOverrun(),
            timeoutThreshold: 1200,
            timeoutThresholdOverrun: newThresholdOverrun(),
        };
    };

    return (
        <EntityForm
            id={id}
            pageTitle="Groupes d'agents"
            validationSchema={callQueueSchema}
            listUrl="/callqueues/"
            getEntityDisplay={({ value }) => value?.name || ''}
            newEntity={newEntity}
            getEntity={({ id }) => Api.getCallQueue(id)}
            createEntity={({ value }) => Api.createCallQueue(value)}
            updateEntity={({ value }) => Api.updateCallQueue(value.id, value)}
            removeEntity={({ id }) => Api.removeCallQueue(id)}
            isSync>
            {(props) => <CallQueueEditor {...props} />}
        </EntityForm>
    );
}