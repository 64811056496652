import { useParams } from 'react-router-dom';
import { Api } from './../../services/Api';
import { EntityForm } from '../EntityForm';
import { ResourceAccountEditor } from './ResourceAccountEditor';
import { resourceAccountSchema } from './ResourceAccountValidation';
import { UserLevels } from '../../services/UserLevels';
import { UserRoles } from '../../services/UserRoles';

export function ResourceAccountDetail() {
    const { id } = useParams();

    const newEntity = ({ user }) => {
        return {
            scope: user.scope,
            name: '',
            upn: '',
        };
    };

    return (
        <EntityForm
            id={id}
            pageTitle="Comptes de ressource"
            validationSchema={resourceAccountSchema}
            listUrl="/admin/resourceaccounts/"
            getEntityDisplay={({ value }) => value?.name || ''}
            newEntity={newEntity}
            getEntity={({ id }) => Api.getResourceAccount(id)}
            createEntity={({ value }) => Api.createResourceAccount(value)}
            updateEntity={({ value }) => Api.updateResourceAccount(value.id, value)}
            removeEntity={({ id }) => Api.removeResourceAccount(id)}
            canEdit={({ user }) => user && user.role >= UserRoles.SUPERADMIN && user.level >= UserLevels.ROOT}
            canDelete={({ user }) => user && user.role >= UserRoles.SUPERADMIN && user.level >= UserLevels.ROOT}>
            {(props) => <ResourceAccountEditor {...props} />}
        </EntityForm>
    );
}