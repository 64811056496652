import { Form, Formik, useField } from 'formik';
import { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import * as yup from 'yup';
import { useScope } from '../../services/ScopeContext';
import { CheckboxList } from '../CheckboxList';
import { EntityFormField } from '../EntityFormField';
import { Api } from './../../services/Api';
import { Error } from './../Error';
import { AutoAttendantCallFlow } from './AutoAttendantCallFlow';
import { callFlowSchema } from './AutoAttendantValidation';


const AutoAttendantHolidayForm = ({ holidays, disabled }) => {
    const [selectedHolidays, , selectedHolidaysHelpers] = useField('holidays');
    const [holidayOptions, setHolidayOptions] = useState([]);

    useEffect(() => {
        if (holidays) {
            setHolidayOptions(holidays.map((o) => {
                return {
                    value: o.id,
                    label: o.name
                };
            }));
        } else {
            setHolidayOptions([]);
        }
    }, [holidays, setHolidayOptions]);

    const isHolidayAllSelected = () => {
        return selectedHolidays.value && holidays && selectedHolidays.value.length === holidays.length;
    };

    const toggleHolidaySelection = () => {
        if (isHolidayAllSelected()) {
            selectedHolidaysHelpers.setValue([]);
        } else {
            selectedHolidaysHelpers.setValue(holidays);
        }
    };

    return (
        <>
            <EntityFormField label="Vacances" field={selectedHolidays}>
                <div className="card px-3 py-2">
                    <div>
                        <Button color="link" className="px-0" onClick={toggleHolidaySelection}>{isHolidayAllSelected() ? "Tout dé-sélectionner" : "Tout sélectionner" }</Button>
                    </div>
                    <CheckboxList
                        value={selectedHolidays.value.map(o => o.id)}
                        options={holidayOptions}
                        disabled={disabled}
                        onChange={(e) => selectedHolidaysHelpers.setValue(holidays.filter(o => e.indexOf(o.id) >= 0))} />
                </div>
            </EntityFormField>
            <AutoAttendantCallFlow
                name="callFlow"
                disabled={disabled}
            />
        </>
    );
};

export const AutoAttendantHolidayModal = ({
    isOpen,
    toggle,
    title,
    value,
    onChange,
    disabled
}) => {
    const [holidays, setHolidays] = useState(null);
    const [error, setError] = useState(null);
    const scope = useScope();

    useEffect(() => {
        if (holidays == null && isOpen) {

            const args = Api.buildScopedParams({
                pageSize: 10000 // We dont want paging here
            }, scope);

            Api.lookupHolidays(args).then(data => {
                setHolidays(data.values);
                setError(null);
            }).catch(error => {
                setError(error);
            });
        }
    }, [isOpen, holidays, scope]);

    const save = (values) => {
        onChange && onChange(values);
    };

    const validationSchema = yup.object().shape({
        holidays: yup.array().of(yup.object().nullable()).min(1, "Au moins un congé est requis"),
        callFlow: callFlowSchema.required('Requis')
    });


    if (!value) return null;

    return (
        <Formik initialValues={value} validationSchema={validationSchema} validateOnMount>
            {(props) => (
                <Modal
                    isOpen={isOpen}
                    toggle={toggle}
                    size="xl">
                    <ModalHeader>{title}</ModalHeader>
                    <ModalBody className="p-4">
                        {error && <Error error={error} />}
                        <Form>
                            <AutoAttendantHolidayForm holidays={holidays} disabled={disabled} />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            className="btn btn-link"
                            onClick={toggle}>Annuler</button>
                        <button
                            type="button"
                            className="btn btn-primary ms-2"
                            disabled={disabled || !props.isValid}
                            onClick={() => save(props.values)}>Enregistrer</button>
                    </ModalFooter>
                </Modal>
            )}
        </Formik>
    );
};