import * as yup from 'yup';
import { scopeSchema } from '../scopes/ScopeValidation';

const businessHoursDay = yup.array().of(yup.object().shape({
    start: yup.number().integer().required('Requis'),
    end: yup.number().integer().required('Requis').test({
        message: "L'heure de fin doit être postérieure à l'heure de début",
        test: (value, context) => {
            return context.parent.start == null || value == null || context.parent.start < value;
        }
    }),
})).required('Requis');

const createBusinessHoursConflictTestOptions = (name) => {
    return {
        message: `Certaines périodes sont en conflit`,
        test: (value, context) => {

            let conflicts = new Map();

            const ranges = context.parent[name];

            let intervals = [];
            ranges.forEach((range, i) => {

                let conflictIndexes = [];
                intervals.forEach((interval, j) => {
                    if (range.start < interval.end && range.end > interval.start) {
                        conflictIndexes.push(j);
                    }
                });

                if (conflictIndexes.length) {
                    conflicts.set(i, conflictIndexes);
                } else {
                    intervals.push(range);
                }
            });

            return conflicts.size === 0;
        }
    }
};

const businessHours = yup.object().shape({
    monday: businessHoursDay,
    tuesday: businessHoursDay,
    wednesday: businessHoursDay,
    thursday: businessHoursDay,
    friday: businessHoursDay,
    saturday: businessHoursDay,
    sunday: businessHoursDay,
    mondayConflict: yup.bool().test(createBusinessHoursConflictTestOptions('monday')),
    tuesdayConflict: yup.bool().test(createBusinessHoursConflictTestOptions('tuesday')),
    wednesdayConflict: yup.bool().test(createBusinessHoursConflictTestOptions('wednesday')),
    thursdayConflict: yup.bool().test(createBusinessHoursConflictTestOptions('thursday')),
    fridayConflict: yup.bool().test(createBusinessHoursConflictTestOptions('friday')),
    saturdayConflict: yup.bool().test(createBusinessHoursConflictTestOptions('saturday')),
    sundayConflict: yup.bool().test(createBusinessHoursConflictTestOptions('sunday')),
});

const promptSchema = yup.object().shape({
    activeType: yup.number().integer().oneOf([0, 1, 2], "Sélection invalide"),
    audioFile: yup.object().nullable().when('activeType', (v, s) => v === 1 ? s.required('Requis') : s),
    textToSpeech: yup.string().nullable().when('activeType', (v, s) => v === 2 ? s.required('Requis').min(10, "Minimum 10 caractères").max(1000, "Maximum 1000 caractères") : s),
});

const requiredPromptSchema = yup.object().shape({
    activeType: yup.number().integer().oneOf([1, 2], "Sélection invalide"),
    audioFile: yup.object().nullable().when('activeType', (v, s) => v === 1 ? s.required('Requis') : s),
    textToSpeech: yup.string().nullable().when('activeType', (v, s) => v === 2 ? s.required('Requis').min(10, "Minimum 10 caractères").max(1000, "Maximum 1000 caractères") : s),
});

const menuOptionSchema = yup.object().shape({
    dtmfResponse: yup.number().integer().min(0).max(9).required('Requis'),
    action: yup.number().integer().oneOf([0, 1, 2, 3, 4, 5, 6, 7], "Sélection invalide"),
    agent: yup.object().nullable().when('action', (v, s) => v === 1 ? s.required('Requis') : s),
    callQueue: yup.object().nullable().when('action', (v, s) => v === 2 ? s.required('Requis') : s),
    autoAttendant: yup.object().nullable().when('action', (v, s) => v === 3 ? s.required('Requis') : s),
    sharedVoicemail: yup.object().nullable().when('action', (v, s) => v === 4 ? s.required('Requis') : s),
    enableSharedVoicemailTranscription: yup.bool().nullable().when('action', (v, s) => v === 4 ? s.required('Requis') : s),
    enableSharedVoicemailSystemPromptSuppression: yup.bool().nullable().when('action', (v, s) => v === 4 ? s.required('Requis') : s),
    externalPstn: yup.string().nullable().when('action', (v, s) => v === 5 ? s.required('Requis') : s),
    textToSpeechAnnouncement: yup.string().nullable().when('action', (v, s) => v === 6 ? s.required('Requis').min(10, "Minimum 10 caractères").max(1000, "Maximum 1000 caractères") : s),
    audioAnnouncement: yup.object().nullable().when('action', (v, s) => v === 7 ? s.required('Requis') : s),
});

const menuOptionsConflictTestOptions = {
    message: "Certaines touches sont en conflit",
    test: (value, context) => {
        if (context.parent.menuOptions) {
            var dtmfResponses = context.parent.menuOptions.map(o => o.dtmfResponse);
            return dtmfResponses.every((v, i, a) => a.indexOf(v) === i);
        } else {
            return true;
        }
    }
};

const menuSchema = yup.object().nullable().shape({
    playMenuOptionsPrompt: requiredPromptSchema.required('Requis'),
    menuOptions: yup.array().of(menuOptionSchema).when('automaticAction', (v, s) => v === -1 ? s.min(1, 'Au moins une touche est requise') : s),
    menuOptionsConflict: yup.bool().test(menuOptionsConflictTestOptions),
});

export const callFlowSchema = yup.object().shape({
    greetings: promptSchema.required('Requis'),
    action: yup.number().integer().oneOf([0, 1, 2, 3, 4, 5, 6], "Sélection invalide"),
    agent: yup.object().nullable().when('action', (v, s) => v === 1 ? s.required('Requis') : s),
    callQueue: yup.object().nullable().when('action', (v, s) => v === 2 ? s.required('Requis') : s),
    autoAttendant: yup.object().nullable().when('action', (v, s) => v === 3 ? s.required('Requis') : s),
    sharedVoicemail: yup.object().nullable().when('action', (v, s) => v === 4 ? s.required('Requis') : s),
    enableSharedVoicemailTranscription: yup.bool().nullable().when('action', (v, s) => v === 4 ? s.required('Requis') : s),
    enableSharedVoicemailSystemPromptSuppression: yup.bool().nullable().when('action', (v, s) => v === 4 ? s.required('Requis') : s),
    externalPstn: yup.string().nullable().when('action', (v, s) => v === 5 ? s.required('Requis') : s),
    menu: menuSchema.when('action', (v, s) => v === 6 ? s.required('Requis') : s),
});

const bypassTarget = yup.object().shape({
    action: yup.number().integer().oneOf([0, 1, 2], "Sélection invalide"),
    externalPstn: yup.string().nullable().when('action', (v, s) => v === 0 ? s.required('Requis') : s),
    agent: yup.object().nullable().when('action', (v, s) => v === 1 ? s.required('Requis') : s),
    sharedVoicemail: yup.object().nullable().when('action', (v, s) => v === 2 ? s.required('Requis') : s),
});

const holidayCallFlowsSchema = yup.array().of(yup.object().shape({
    holidays: yup.array().of(yup.object()).required('Requis'),
    callFlow: callFlowSchema.required('Requis'),
}));

const holidayCallFlowsConflictTestOptions = {
    message: "Certains congés sont en doublon",
    test: (value, context) => {
        if (context.parent.holidayCallFlows) {
            let ids = [];
            for (let i = 0; i < context.parent.holidayCallFlows.length; i++) {
                if (context.parent.holidayCallFlows[i].holidays) {
                    for (let j = 0; j < context.parent.holidayCallFlows[i].holidays.length; j++) {
                        if (ids.indexOf(context.parent.holidayCallFlows[i].holidays[j].id) >= 0) {
                            return false;
                        } else {
                            ids.push(context.parent.holidayCallFlows[i].holidays[j].id);
                        }
                    }
                }
            }
        }
        return true;
    }
};

export const autoAttendantSchema = yup.object().shape({
    scope: scopeSchema.required('Requis'),
    name: yup.string().required('Requis'),
    language: yup.string().required('Requis'),
    timeZone: yup.string().required('Requis'),
    resourceAccounts: yup.array().required('Requis'),
    bypass: yup.bool().required('Requis'),
    bypassTarget: yup.object().nullable().when('bypass', {
        is: true,
        then: bypassTarget.nullable().required('Requis')
    }),
    //bypassTarget: bypassTarget.nullable().when('bypass', (v, s) => v ? s.required('Requis') : s),
    businessHours: businessHours.required('Requis'),
    defaultCallFlow: callFlowSchema.required('Requis'),
    afterHoursCallFlow: callFlowSchema.required('Requis'),
    holidayCallFlows: holidayCallFlowsSchema.required('Requis'),
    holidayCallFlowsConflict: yup.bool().test(holidayCallFlowsConflictTestOptions),
});
