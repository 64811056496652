import { Link } from "react-router-dom";
import { UserRoles } from "../../services/UserRoles";
import { EntityList } from "../EntityList";
import { Api } from './../../services/Api';

export const Agents = () => {

    return (
        <EntityList
            pageTitle="Agents"
            getEntities={(params) => Api.getAgents(params)}
            entityCreationUrl="/admin/agents/import"
            entityCreationButtonInner={<div><i className="bi bi-arrow-down-circle pe-2"></i>Importer</div>}
            canEdit={({ user, value }) => user && user.role >= UserRoles.ADMIN}
            columns={[{
                sortBy: 'name',
                header: "Nom",
                renderCell: (o) => <Link to={`/admin/agents/${o.id}`}>{o.name}</Link>,
            },
            {
                sortBy: 'upn',
                header: "Upn",
                renderCell: (o) => o.upn,
            }]} />
    );
}
