import { FieldArray, useField } from 'formik';
import { useState } from 'react';
import { Input, TabContent, TabPane } from 'reactstrap';
import { ScopeProvider } from '../../services/ScopeContext';
import { EntityFormField } from '../EntityFormField';
import { EntityFormTabs } from '../EntityFormTabs';
import { ScopeEditor } from '../ScopeEditor';
import { Config } from './../../services/Config';
import { Label } from './../Label';
import { ResourceAccountsSelector } from './../resourceAccounts/ResourceAccountsSelector';
import { AutoAttendantBusinessHours } from './AutoAttendantBusinessHours';
import { AutoAttendantBypassRedirect } from './AutoAttendantBypassRedirect';
import { AutoAttendantCallFlow } from './AutoAttendantCallFlow';
import { AutoAttendantHolidays } from './AutoAttendantHolidays';

export const AutoAttendantEditor = ({ id, readonly }) => {

    const [currentTab, setCurrentTab] = useState(0);

    const [scope] = useField('scope');
    const [name] = useField("name");
    const [language] = useField("language");
    const [timeZone] = useField("timeZone");
    const [resourceAccounts, , resourceAccountsHelpers] = useField("resourceAccounts");
    const [businessHours, , businessHoursHelpers] = useField("businessHours");
    const [holidayCallFlows] = useField("holidayCallFlows");

    const tabs = [
        "Général",
        "Acheminement de l'appel",
        "Gestion des horaires",
        "Périodes de fermeture"
    ];

    return (
        <ScopeProvider value={scope.value}>
            <EntityFormTabs tabs={tabs} activeTab={currentTab} activateTab={setCurrentTab} />
            <TabContent activeTab={currentTab} className="border rounded-bottom p-4">
                <TabPane tabId={0}>
                    <ScopeEditor readonly={id > 0} />
                    <EntityFormField label="Nom" field={name}>
                        <Input {...name} bsSize="sm" disabled={readonly} />
                    </EntityFormField>
                    <EntityFormField label="Langue" field={language}>
                        <Input {...language} type="select" bsSize="sm" disabled={readonly}>
                            {!language.value &&
                                <option>Choisir</option>
                            }
                            {Config.getLanguages().map(o => <option value={o.value} key={o.value}>{o.label}</option>)}
                        </Input>
                    </EntityFormField>
                    <EntityFormField label="Fuseau horaire" field={timeZone}>
                        <Input {...timeZone} type="select" bsSize="sm" disabled={readonly}>
                            {!timeZone.value &&
                                <option>Choisir</option>
                            }
                            {Config.getTimeZones().map(o => <option value={o.value} key={o.value}>{o.label}</option>)}
                        </Input>
                    </EntityFormField>
                    <EntityFormField label="Comptes de ressource" field={resourceAccounts}>
                        <ResourceAccountsSelector
                            value={resourceAccounts.value}
                            onChange={(value) => {
                                resourceAccountsHelpers.setTouched(true);
                                resourceAccountsHelpers.setValue(value);
                            }}
                            disabled={readonly}
                        />
                    </EntityFormField>
                    {id > 0 &&
                        <div className="entity-form-field">
                            <AutoAttendantBypassRedirect disabled={readonly} />
                        </div>
                    }
                </TabPane>
                <TabPane tabId={1}>
                    <AutoAttendantCallFlow name="defaultCallFlow" disabled={readonly} />
                </TabPane>
                <TabPane tabId={2}>
                    <div className="entity-form-field">
                        <Label>Définir les heures d'ouverture</Label>
                        <AutoAttendantBusinessHours
                            {...businessHours}
                            disabled={readonly}
                            onChange={(value) => businessHoursHelpers.setValue(value)} />
                    </div>
                    <div className="entity-form-field">
                        <Label>Configurer le flux d'appels en dehors des heures de travail</Label>
                        <p className="my-4">
                            Si vous avez défini des heures d'ouverture, vous devrez également configurer l'action à effectuer en cas de réponse en dehors des heures de travail.
                        </p>
                        <AutoAttendantCallFlow
                            name="afterHoursCallFlow"
                            disabled={readonly} />
                    </div>
                </TabPane>
                <TabPane tabId={3}>
                    <div className="entity-form-field">
                        <Label>Configurer le flux d'appels pendant les vacances</Label>
                        <p className="my-4">
                            La configuration des jours fériés pour votre organisation n'est pas obligatoire, mais si vous souhaitez répondre à
                            des appels plusieurs jours ou semaines pendant la fermeture de votre entreprise, vous pouvez configurer une nouvelle
                            période pendant laquelle pour lire les messages d'accueil et les messages pour les dates souhaitées et configurer la
                            redirection des appels. Par exemple, vous pouvez ajouter des vacances pour Noël avec les dates 24/12/2020 et 25/12/2020
                            ou Nouvel an pour le 1/1/2020.
                        </p>
                        <FieldArray name={holidayCallFlows.name}>
                            {(props) =>
                                <AutoAttendantHolidays
                                    {...{ value: holidayCallFlows.value, ...props }}
                                    disabled={readonly} />
                            }
                        </FieldArray>
                    </div>
                </TabPane>
            </TabContent>
        </ScopeProvider>
    );
}