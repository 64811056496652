import { useEffect, useState } from 'react';
import { Autocomplete } from './../Autocomplete';
import { EntityRefThumbnail } from './../EntityRefThumbnail';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const CallQueueAgentsModal = ({ isOpen, toggle, onChange, getMatches }) => {
    const [selectedValue, setSelectedValue] = useState(null);
    const [agentsToAdd, setAgentsToAdd] = useState([]);

    useEffect(() => setAgentsToAdd([]), [isOpen]);

    const onSearch = async (params) => {
        const ids = agentsToAdd.map(o => o.id);
        const results = await getMatches(params);
        return results.filter(a => ids.indexOf(a.id) < 0);
    }

    const onSelect = (value) => {
        setAgentsToAdd(agentsToAdd.concat([value]));
        setSelectedValue(null);
    }

    const removeAgent = (index) => {
        let agents = [...agentsToAdd];
        agents.splice(index, 1);
        setAgentsToAdd(agents);
    }

    const onModalConfirm = () => {
        onChange && onChange(agentsToAdd);
    };


    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader>Ajouter des agents</ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    <Autocomplete
                        id="add-agents-lookup"
                        value={selectedValue}
                        getMatches={onSearch}
                        onChange={onSelect}
                        size="sm"
                        inputPlaceholder="Rechercher un agent"
                        renderMenuItem={(o) => <EntityRefThumbnail className="py-1" value={o} />} />
                    <div className="mt-3">
                        <div>Sélection: <strong>{agentsToAdd.length}</strong></div>
                        <ul className="list-group mt-2">
                            {agentsToAdd.map((agent, index) =>
                                <li key={agent.id} className="list-group-item lh-1 ps-3 pe-2">
                                    <div className="d-flex flex-row justify-content-between">
                                        <EntityRefThumbnail value={agent} />
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-danger"
                                            onClick={() => removeAgent(index)}>X</button>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    className="btn btn-link"
                    onClick={toggle}>Annuler</button>
                <button
                    type="button"
                    className="btn btn-primary ms-2"
                    disabled={agentsToAdd.length === 0}
                    onClick={onModalConfirm}>Ajouter la sélection</button>
            </ModalFooter>
        </Modal>
    );
}