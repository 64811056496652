import { useField } from 'formik';
import { Fragment, useState } from 'react';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { ValuesJoiner } from '../ValuesJoiner';
import { ConfirmButton } from './../ConfirmButton';
import { AutoAttendantHolidayModal } from './AutoAttendantHolidayModal';

export const AutoAttendantHolidays = ({ value, push, replace, remove, disabled }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selected, setSelected] = useState(null);
    const [holidayCallFlowsConflict] = useField('holidayCallFlowsConflict');

    const add = () => {
        setShowModal(true);
        setSelectedIndex(-1);
        setSelected({
            holidays: [],
            callFlow: {
                greetings: {
                    activeType: 0,
                    textToSpeech: null,
                    audioFile: null
                },
                action: 0,
                agent: null,
                callQueue: null,
                autoAttendant: null,
                sharedVoicemail: null,
                enableSharedVoicemailTranscription: false,
                enableSharedVoicemailSystemPromptSuppression: false,
                externalPstn: null,
                menu: null
            }
        });
    }

    const edit = (index) => {
        setSelectedIndex(index);
        setSelected(value[index]);
        setShowModal(true);
    };

    const toggleModal = () => {
        if (showModal) {
            setShowModal(false);
            setSelectedIndex(-1);
            setSelected(null);
        } else {
            setShowModal(true);
        }
    }

    const onModalConfirm = (result) => {
        if (selectedIndex >= 0) {
            replace(selectedIndex, result);
        } else {
            push(result);
        }
        toggleModal();
    };

    const getCallFlowActionDisplay = (value) => {
        switch (value) {
            case 0: return "Raccrocher";
            case 1: return "Transférer vers une personne dans l'organisation";
            case 2: return "Transférer vers un groupe d'agents";
            case 3: return "Transférer vers un standard";
            case 4: return "Transférer vers une messagerie vocale";
            case 5: return "Transférer vers un numéro de téléphone externe";
            case 6: return "Transférer vers un SVI (Serveur Vocal Interactif)";
            default: return "NC";
        }
    }

    if (!value) return null;

    return (
        <>
            <div className="card shadow-sm">
                <div className="p-2 bg-light border-bottom card-header">
                    <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={add}
                        disabled={disabled}>
                        <i className="bi-plus pe-1"></i>
                        Ajouter
                    </button>
                </div>
                <div className="table-responsive">
                    <table className='table table-striped m-0'>
                        <colgroup>
                            <col />
                            <col />
                            <col width="100px" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="ps-3">Congés</th>
                                <th>Action</th>
                                <th className="pe-3"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {value.length === 0 &&
                                <tr><td colSpan="3" className="p-4 text-center text-muted">Aucun</td></tr>
                            }
                            {value && value.map((holidayCallFlow, index) =>
                                <tr key={index}>
                                    <td className="ps-3">
                                        <ValuesJoiner values={holidayCallFlow.holidays} selector={(o) => o.name} />
                                    </td>
                                    <td>
                                        {getCallFlowActionDisplay(holidayCallFlow.callFlow.action)}
                                    </td>
                                    <td className="pe-3" align="right">
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-primary"
                                            onClick={() => edit(index)}>
                                            <i className="bi-pencil"></i>
                                        </button>
                                        {!disabled &&
                                            <span className="ms-3">
                                                <ConfirmButton size="sm" onConfirm={(confirmed) => confirmed && remove(index)}>
                                                    <i className="bi-trash"></i>
                                                </ConfirmButton>
                                            </span>
                                        }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <AutoAttendantHolidayModal
                    isOpen={showModal}
                    toggle={toggleModal}
                    value={selected}
                    title={selectedIndex >= 0 ? 'Modifier' : 'Nouveau'}
                    onChange={onModalConfirm}
                    disabled={disabled}
                />
            </div>
            <FieldErrorMessage name={holidayCallFlowsConflict.name} />
        </>
    );
}