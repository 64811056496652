import { useField } from 'formik';
import * as moment from 'moment';
import DatePicker from 'reactstrap-date-picker';
import { Config } from './../services/Config';
import { idSafer } from './../services/IdSafer';
import { EntityFormField } from './EntityFormField';

export const DatesRange = ({ name, value, onChange, children, disabled }) => {
    const [start, , startHelpers] = useField(name + '.start');
    const [end, , endHelpers] = useField(name + '.end');

    const parseValue = (value) => {
        if (value) return moment.utc(value).format('YYYY-MM-DDT00:00:00');
        else return value;
    };

    const handleDateChange = (value, helpers) => {
        helpers.setValue(value);
        helpers.setTouched(true);
    };

    const handleStartChange = (value) => {
        value = parseValue(value);
        handleDateChange(value, startHelpers);

        // set default endDate value if empty
        if (value && !end.value) {
            const defaultEndValue = moment.utc(value).add(1, 'd').format('YYYY-MM-DDT00:00:00');
            handleDateChange(defaultEndValue, endHelpers);
        }
    };

    const handleEndChange = (value) => {
        value = parseValue(value);
        handleDateChange(value, endHelpers);
    };

    return (
        <tr>
            <td className="ps-3">
                <EntityFormField field={start}>
                    <DatePicker disabled={disabled} size="sm" {...start} {...Config.datePickerConfig} id={idSafer(start.name)} onChange={handleStartChange} />
                </EntityFormField>
            </td>
            <td>
                <EntityFormField field={end}>
                    <DatePicker disabled={disabled} size="sm" {...end} {...Config.datePickerConfig} id={idSafer(end.name)} onChange={handleEndChange} />
                </EntityFormField>
            </td>
            {!disabled &&
                <td align="center" className="pe-3">
                    {children}
                </td>
            }
        </tr>
    );
}