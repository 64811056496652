import PropTypes from "prop-types";
import { useState } from 'react';
import { ConfirmModal } from './ConfirmModal';
import { Button } from 'reactstrap';

export const ConfirmButton = ({
    onConfirm,
    size,
    color,
    confirmationHeader,
    confirmationMessage,
    cancelLabel,
    confirmLabel,
    disabled,
    children
}) => {
    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!open);
    };

    const confirm = (value) => {
        setOpen(false);
        onConfirm && onConfirm(value);
    };

    return (
        <>
            <Button
                type="button"
                size={size}
                color={color}
                onClick={toggle}
                disabled={disabled}>
                {children}
            </Button>
            {!disabled && <ConfirmModal
                isOpened={open}
                onToggle={toggle}
                confirmButtonColor={color}
                confirmationHeader={confirmationHeader}
                confirmationMessage={confirmationMessage}
                cancelLabel={cancelLabel}
                confirmLabel={confirmLabel}
                onConfirm={confirm} />}
        </>
    );
}

ConfirmButton.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired,
    size: PropTypes.string,
    color: PropTypes.string,
    confirmationHeader: PropTypes.string,
    confirmationMessage: PropTypes.string,
    cancelLabel: PropTypes.string,
    confirmLabel: PropTypes.string,
    disabled: PropTypes.bool
};

ConfirmButton.defaultProps = {
    color: "danger"
};