import { createContext, useContext, useEffect, useState } from 'react';

const MenuToggleProviderCollapsedLSName = "MenuToggleProvider.collapsed";
const MenuToggleContext = createContext(false);

export const MenuToggleProvider = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);
    const isSmallWidthDevice = () => window.innerWidth <= 768;

    useEffect(() => {
        if (isSmallWidthDevice()) {
            setCollapsed(true);
        } else if (window.localStorage) {
            var item = window.localStorage.getItem(MenuToggleProviderCollapsedLSName);
            setCollapsed(item && item === "true");
        }
    }, []);

    const toggleCollapsed = () => {
        var value = !collapsed;
        setCollapsed(value);
        if (window.localStorage) {
            if (value) window.localStorage.setItem(MenuToggleProviderCollapsedLSName, value.toString());
            else window.localStorage.removeItem(MenuToggleProviderCollapsedLSName);
        }
    }

    return (
        <MenuToggleContext.Provider value={{ collapsed, toggleCollapsed, isSmallWidthDevice }}>
            <div className={isSmallWidthDevice() ? "small-screen" : "large-screen"}>{children}</div>
        </MenuToggleContext.Provider>
    );
}

export const useMenuToggle = () => {
    const context = useContext(MenuToggleContext);
    if (context == null) throw new Error('useMenuToggle must be used within a MenuToggleContext');    
    return context;
}