import { NavMenu } from './NavMenu';
import { Main } from './Main';

export const Layout = ({ children }) => {
    return (
        <div className="w-100 vh-100 overflow-hidden">
            <NavMenu />
            <Main>{children}</Main>
        </div>
    );
}
