import { Link } from "react-router-dom";
import { Api } from '../../services/Api';
import { EntityList } from "../EntityList";

export const SharedVoicemails = () => {

    return (
        <EntityList
            pageTitle="Boites vocales"
            getEntities={(params) => Api.getSharedVoicemails(params)}
            entityCreationUrl="/sharedvoicemails/new"
            columns={[{
                sortBy: 'name',
                header: "Nom",
                renderCell: (o) => <Link to={`/sharedvoicemails/${o.id}`}>{o.name}</Link>,
            }]} />
    );
}
