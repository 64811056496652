import moment from 'moment';

export const DateTimeUtils = {
    formatDate: (date) => {
        return date ? moment(date).format('DD/MM/YYYY') : '';
    },
    formatDuration: (minutes) => {
        return moment.utc(moment.duration(minutes, 'minutes').as('milliseconds')).format('HH:mm');
    },
    convertDateFromIso: (date) => {
        if (date) {
            return moment(date).format('YYYY-MM-DD');
        } else {
            return '';
        }
    },
    convertDateToIso: (date) => {
        if (date) {
            return moment.utc(date).format('YYYY-MM-DDT00:00:00');
        } else {
            return '';
        }
    }, humanizeDuration: (value) => {
        const minutes = Math.floor(value / 60);
        const seconds = value % 60;

        let result = "";

        if (minutes === 0 && seconds === 0) {
            return "0 seconde";
        }

        if (minutes > 0) {
            if (minutes === 1) {
                result += minutes + " minute";
            } else {
                result += minutes + " minutes";
            }
        }
        if (seconds > 0) {
            if (result.length) result += " ";
            if (seconds === 1) {
                result += seconds + " seconde";
            } else {
                result += seconds + " secondes";
            }
        }

        return result;
    }
};
