import { FieldErrorMessage } from './FieldErrorMessage';
import { Label } from './Label';
import { Info } from './Info';
import PropTypes from "prop-types";

import './EntityFormField.css';

export const EntityFormField = ({
    label,
    info,
    field,
    alwaysDisplayError,
    children
}) => {
    return (
        <div className="entity-form-field">
            {label &&
                <Label>{label}{info && <Info id={field.name + '__info'}>{info}</Info>}</Label>
            }
            {children}
            <FieldErrorMessage name={field.name} touchedOnly={!alwaysDisplayError}  />
        </div>
    );
}

EntityFormField.propTypes = {
    field: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    alwaysDisplayError: PropTypes.bool
};

EntityFormField.defaultProps = {
    alwaysDisplayError: true
};