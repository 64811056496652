import PropTypes from "prop-types";

export const RangeInput = ({
    name,
    min,
    max,
    step,
    value,
    valueFormatter,
    onChange,
    onBlur,
    disabled
}) => {

    const onInputChange = (event) => onChange && onChange(event);
    const onInputBlur = (event) => onBlur && onBlur(event);

    return (
        <div>
            <input
                name={name}
                type="range"
                className="form-range"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={onInputChange}
                onBlur={onInputBlur}
                disabled={disabled}
                style={{ maxWidth: "500px" }}
            />
            <div className="lh-1">{valueFormatter(value)}</div>
        </div>
    );
}


RangeInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    valueFormatter: PropTypes.func
};

RangeInput.defaultProps = {
    step: 1,
    valueFormatter: (value) => value.toString(),
};
