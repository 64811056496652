import { Form, Formik } from "formik";
import { useState } from "react";
import { Redirect } from "react-router-dom";
import { Error } from './Error';
import { FormDataLossGuard } from "./FormDataLossGuard";
import { PageContent } from "./PageContent";
import { PageHeader } from "./PageHeader";
import { PageTitle } from "./PageTitle";

export const ImportEntitiesForm = ({ value, validationSchema, listUrl, pageTitle, save, children }) => {
    const [writing, setWriting] = useState(false);
    const [error, setError] = useState(null);
    const [backToList, setBackToList] = useState(false);

    const goBackToList = () => {
        setBackToList(true);
    }

    const submit = async (values, actions) => {
        setWriting(true);

        save(values)
            .then(goBackToList)
            .catch(setError)
            .finally(() => setWriting(false));
    }

    if (backToList) {
        return <Redirect to={listUrl} />;
    } else {
        return (
            <>
                <PageHeader>
                    <PageTitle path={[{ label: pageTitle, url: listUrl }, "Importation"]} />
                </PageHeader>
                <PageContent>
                    <Formik initialValues={value} validationSchema={validationSchema} validateOnMount>
                        {(props) => (
                            <Form>
                                {error && <Error error={error} />}
                                {value && (
                                    <>
                                        {children()}

                                        <div className="mt-5">
                                            <FormDataLossGuard />
                                            <div className="d-flex flex-row justify-content-between">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary me-4"
                                                    onClick={goBackToList}>Annuler</button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-success"
                                                    onClick={() => submit(props.values)}
                                                    disabled={!props.isValid || props.isValidating || writing}>Importer</button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Form>
                        )}
                    </Formik>
                </PageContent>
            </>
        );
    }
}