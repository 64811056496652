import * as yup from 'yup';

export const scopeSchema = yup.object().nullable().shape({
    tenant: yup.object().nullable().shape({
        id: yup.number().integer().required("Requis")
    }).required("Requis"),
    orgUnit: yup.object().nullable().shape({
        id: yup.number().integer()
    }),
    subOrgUnit: yup.object().nullable().shape({
        id: yup.number().integer()
    }),
});