import { Link, useLocation } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { mergeClassNames } from '../services/ComponentHelper';
import { useMenuToggle } from '../services/MenuToggleContext';

export const NavMenuItemLink = ({ children, textColor, icon, className, ...props }) => {
    const location = useLocation();
    const { isSmallWidthDevice, toggleCollapsed } = useMenuToggle();
    const onNavLinkClick = () => isSmallWidthDevice() && toggleCollapsed();

    let isActive = location.pathname.startsWith(props.to);
    if (isActive) {
        className = mergeClassNames(className, "active");
    }

    return (
        <NavLink tag={Link} onClick={onNavLinkClick} {...props} className={className}>
            <div className="d-flex">
                <div className="me-3">
                    {icon != null && <i className={" bi bi-" + icon}></i>}
                    {icon == null && <div style={{ width: "1em" }}></div>}
                </div>
                {children}
            </div>
        </NavLink>
    );
}
