import { Link } from "react-router-dom";
import { UserLevels } from "../../services/UserLevels";
import { UserRoles } from "../../services/UserRoles";
import { Api } from '../../services/Api';
import { EntityList } from '../EntityList';

export const SubOrgUnits = () => {

    return (
        <EntityList
            pageTitle="Sous-organisations"
            getEntities={(params) => Api.getSubOrgUnits(params)}
            entityCreationUrl="/admin/suborgunits/new"
            canAdd={({ user }) => user && user.role >= UserRoles.ADMIN && user.level >= UserLevels.ORGUNIT}
            hideSubOrgUnitFilter={true}
            columns={[{
                sortBy: 'name',
                header: "Nom",
                renderCell: (o) => <Link to={`/admin/suborgunits/${o.id}`}>{o.name}</Link>,
            }]} />
    );
}
