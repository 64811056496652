import { useField } from 'formik';
import { Input } from 'reactstrap';
import { EntityFormField } from '../EntityFormField';
import { ScopeEditor } from '../ScopeEditor';

export const AgentEditor = ({ id }) => {
    const [name] = useField('name');
    const [upn] = useField('upn');

    return (
        <div>
            <ScopeEditor />
            <EntityFormField label="Nom" field={name}>
                <Input {...name} bsSize="sm" disabled={true} />
            </EntityFormField>
            <EntityFormField label="Upn" field={upn}>
                <Input {...upn} bsSize="sm" disabled={true} />
            </EntityFormField>
        </div>
    );
}