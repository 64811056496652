import { Autocomplete } from './Autocomplete';
import { EntityRefThumbnail } from './EntityRefThumbnail';
import { Api } from './../services/Api';
import { useScope } from '../services/ScopeContext';

export const CallTargetLookup = ({ name, placeholder, type, value, onChange, onBlur, disabled }) => {
    const scope = useScope();

    const changeValue = (value) => {
        onChange && onChange(value);
    }

    const getMatches = async (params) => {
        let matches = [];

        const args = Api.buildScopedParams({ ...params }, scope);

        try {
            if (type === 'agent') {
                matches = (await Api.lookupAgents(args)).values;
            } else if (type === 'callQueue') {
                matches = (await Api.lookupCallqueues({ ...args })).values;
            } else if (type === 'autoAttendant') {
                matches = (await Api.lookupAutoAttendants({ ...args })).values;
            } else if (type === 'sharedVoicemail') {
                matches = (await Api.lookupSharedVoicemails(args)).values;
            }
        } catch (error) {
            alert(error.status + ": " + error.message);
        }

        return matches;
    }

    return (
        <Autocomplete
            name={name}
            value={value}
            disabled={disabled}
            getMatches={async (o) => await getMatches(o)}
            onChange={(o) => changeValue(o)}
            onBlur={onBlur}
            size="sm"
            inputPlaceholder={placeholder}
            renderMenuItem={(o) => <EntityRefThumbnail className="py-1" value={o} />} />
    );
}