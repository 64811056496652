import { useField } from 'formik';
import { Col, Input, Row } from 'reactstrap';
import { EntityFormField } from '../EntityFormField';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { PhonePicker } from '../PhonePicker';
import { SwitchInput } from '../SwitchInput';
import { AudioFile } from './../AudioFile';
import { CallTargetLookup } from './../CallTargetLookup';

export const AutoAttendantMenuOptions = ({ name, value, push, remove, disabled }) => {

    const getUsedDtmfResponses = () => {
        return value
            .filter(option => option.dtmfResponse !== undefined || option.dtmfResponse !== null)
            .map(option => option.dtmfResponse)
            .sort();
    }

    const getNextUnsedDtmfResponses = () => {
        let used = getUsedDtmfResponses();
        for (var i = 0; i < AutoAttendantMenuOptions.DtmfResponses.length; i++) {
            if (used.indexOf(AutoAttendantMenuOptions.DtmfResponses[i]) < 0) {
                return AutoAttendantMenuOptions.DtmfResponses[i];
            }
        }

        throw new Error("Out of range");
    }

    const addOption = () => {
        var next = getNextUnsedDtmfResponses();
        push({ dtmfResponse: next, action: 2 });
    }

    if (value == null) return null;

    return (
        <>
            <div className="card shadow-sm">
                <div className="p-2 bg-light border-bottom card-header">
                    <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={addOption}
                        disabled={disabled || value.length >= AutoAttendantMenuOptions.DtmfResponses.length}>
                        <i className="bi-plus pe-1"></i>
                        Attribuer une touche d'accès
                    </button>
                </div>
                <div>
                    {value && value.map((_, index) =>
                        <div key={index}
                            className={index > 0 ? "border-top d-flex  p-0" : "d-flex  p-0"}
                            style={{ backgroundColor: index % 2 === 0 ? "#0000000d" : "unset" }}>
                            <div className="flex-fill p-0">
                                <AutoAttendantMenuOption
                                    name={name + `[${index}]`}
                                    disabled={disabled}
                                />
                            </div>
                            {!disabled &&
                                <div className="p-2">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-danger"
                                        onClick={() => remove(index)}>
                                        <i className="bi-trash"></i>
                                    </button>
                                </div>
                            }
                        </div>
                    )}
                </div>
            </div>
            <FieldErrorMessage name={name + 'Conflict'} />
        </>
    );
}

export const AutoAttendantMenuOption = ({ name, onChange, disabled }) => {
    const [option, , optionHelpers] = useField(name);
    const [dtmfResponse, , dtmfResponseHelpers] = useField(name + ".dtmfResponse");
    const [action] = useField(name + ".action");
    const [agent, , agentHelpers] = useField(name + ".agent");
    const [callQueue, , callQueueHelpers] = useField(name + ".callQueue");
    const [autoAttendant, , autoAttendantHelpers] = useField(name + ".autoAttendant");
    const [sharedVoicemail, , sharedVoicemailHelpers] = useField(name + ".sharedVoicemail");
    const [externalPstn, , externalPstnHelpers] = useField(name + ".externalPstn");
    const [enableSharedVoicemailSystemPromptSuppression] = useField(name + ".enableSharedVoicemailSystemPromptSuppression");
    const [enableSharedVoicemailTranscription] = useField(name + ".enableSharedVoicemailTranscription");
    const [textToSpeechAnnouncement] = useField(name + ".textToSpeechAnnouncement");
    const [audioAnnouncement, , audioAnnouncementHelpers] = useField(name + ".audioAnnouncement");

    const getInput = () => {
        switch (action.value) {
            case 1:
                return (
                    <EntityFormField field={agent}>
                        <CallTargetLookup
                            {...agent}
                            placeholder="Rechercher un agent"
                            type="agent"
                            onChange={(o) => agentHelpers.setValue(o)}
                            disabled={disabled}
                        />
                    </EntityFormField>
                );
            case 2:
                return (
                    <EntityFormField field={callQueue}>
                        <CallTargetLookup
                            {...callQueue}
                            placeholder="Rechercher un groupe d'agents"
                            type="callQueue"
                            onChange={(o) => callQueueHelpers.setValue(o)}
                            disabled={disabled}
                        />
                    </EntityFormField>
                );
            case 3:
                return (
                    <EntityFormField field={autoAttendant}>
                        <CallTargetLookup
                            {...autoAttendant}
                            placeholder="Rechercher un standard"
                            type="autoAttendant"
                            onChange={(o) => {
                                autoAttendantHelpers.setValue(o);
                                autoAttendantHelpers.setTouched(true);
                            }}
                            disabled={disabled}
                        />
                    </EntityFormField>
                );
            case 4:
                return (
                    <>
                        <EntityFormField field={sharedVoicemail}>
                            <CallTargetLookup
                                {...sharedVoicemail}
                                placeholder="Rechercher une boite vocale"
                                type="sharedVoicemail"
                                onChange={(o) => {
                                    sharedVoicemailHelpers.setValue(o);
                                    sharedVoicemailHelpers.setTouched(true);
                                }}
                                disabled={disabled}
                            />
                        </EntityFormField>
                        <Row>
                            <Col lg="4">
                                <EntityFormField label="Transcription" field={enableSharedVoicemailTranscription}>
                                    <SwitchInput
                                        {...enableSharedVoicemailTranscription}
                                        trueLabel="Activée"
                                        falseLabel="Désactivée"
                                        disabled={disabled}
                                    />
                                </EntityFormField>
                            </Col>
                            <Col lg="8">
                                <EntityFormField label="Ignorer le message système de messagerie vocale" field={enableSharedVoicemailSystemPromptSuppression}
                                    info="L'activation de cette option ignorera le message vocal « Veuillez laisser un message après la tonalité. Une fois que vous avez terminé, raccrochez ou appuyez sur la touche de hachage pour obtenir plus d'options ». Les appelants entendront uniquement le signal sonore et pourront commencer à enregistrer leur message.">
                                    <SwitchInput
                                        {...enableSharedVoicemailSystemPromptSuppression}
                                        trueLabel="Activée"
                                        falseLabel="Désactivée"
                                        disabled={disabled}
                                    />
                                </EntityFormField>
                            </Col>
                        </Row>
                    </>
                );
            case 5:
                return (
                    <EntityFormField field={externalPstn}>
                        <PhonePicker
                            {...externalPstn}
                            onChange={(o) => {
                                externalPstnHelpers.setValue(o);
                                externalPstnHelpers.setTouched(true);
                            }}
                            disabled={disabled}
                        />
                    </EntityFormField>
                );
            case 6:
                return (
                    <EntityFormField field={textToSpeechAnnouncement}>
                        <Input
                            {...textToSpeechAnnouncement}
                            type="textarea"
                            bsSize="sm"
                            disabled={disabled}
                        />
                        <div className="small mt-1 text-muted lh-1">Le message doit comporter entre 10 et 1000 caractères.</div>
                    </EntityFormField>
                );
            case 7:
                return (
                    <EntityFormField field={audioAnnouncement}>
                        <AudioFile
                            {...audioAnnouncement}
                            onChange={(file) => {
                                audioAnnouncementHelpers.setValue(file);
                                audioAnnouncementHelpers.setTouched(true);
                            }}
                            disabled={disabled}
                        />
                    </EntityFormField>
                );
            default:
                return null;
        }
    }


    return (
        <div className="container-fluid px-0 py-1">
            <Row className="m-0">
                <Col md="2" className="px-2 py-1 m-0">
                    <EntityFormField field={dtmfResponse}>
                        <Input {...dtmfResponse}
                            type="select"
                            bsSize="sm"
                            onChange={(e) => dtmfResponseHelpers.setValue(+e.target.value)}
                            disabled={disabled}>
                            {AutoAttendantMenuOptions.DtmfResponses.map(o => <option value={o} key={o}>Touche {o}</option>)}
                        </Input>
                    </EntityFormField>
                </Col>
                <Col md="10" lg="4" className="px-2 py-1 m-0">
                    <EntityFormField field={action}>
                        <Input
                            {...action}
                            type="select"
                            bsSize="sm"
                            onChange={(e) => {
                                let selectedAction = +e.target.value;
                                var data = { dtmfResponse: option.value.dtmfResponse, action: selectedAction };
                                optionHelpers.setValue(data);
                            }}
                            disabled={disabled}>
                            {AutoAttendantMenuOptions.Actions.map(o => <option value={o.value} key={o.value}>{o.label}</option>)}
                        </Input>
                    </EntityFormField>
                </Col>
                <Col md="12" lg="6" className="px-2 py-1 m-0">
                    {getInput()}
                </Col>
            </Row>
        </div>
    );
}

AutoAttendantMenuOptions.DtmfResponses = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
AutoAttendantMenuOptions.Actions = [
    //{
    //    value: 0,
    //    label: "Opérateur"
    //},
    {
        value: 2,
        label: "Transférer à un groupe d'agents"
    }, {
        value: 1,
        label: "Transférer à une personne dans l'organisation"
    }, {
        value: 3,
        label: "Transférer à un autre standard"
    }, {
        value: 4,
        label: 'Transférer à une messagerie vocale'
    }, {
        value: 5,
        label: 'Transférer à un numéro de téléphone externe'
    }, {
        value: 6,
        label: 'Jouer une annonce texte'
    }, {
        value: 7,
        label: 'Jouer une annonce audio'
    }];