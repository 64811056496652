import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export const PhonePicker = ({ name, value, onChange, onBlur, disabled }) => {

    const onInternalChange = (value) => {
        onChange && onChange(value);
    }

    return (
        <div className="input-group input-group-sm">
            <PhoneInput
                name={name}
                value={value}
                placeholder="Entrer un numéro de téléphone"
                defaultCountry="FR"
                onChange={onInternalChange}
                onBlur={onBlur}
                disabled={disabled}
            />
            <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                title="Supprimer la sélection"
                onClick={(o) => onInternalChange(null)}
                disabled={disabled}>
                <i className="bi-x-lg"></i>
            </button>
        </div>
    );
};


//export const ForwardedPhoneInput = forwardRef((props, ref) => {

//    const { className, ...rest } = props;

//    //debugger;

//    return (
//        <input
//            className="form-control form-control-sm"
//            placeholder="Entrer un numéro de téléphone ZZZZ"
//            ref={ref}
//            {...rest}
//        />
//    )
//});

//const CountrySelect = ({ value, onChange, labels, ...rest }) => {
//    const [expanded, setExpanded] = useState(false);
//    const toggle = () => setExpanded(!expanded);

//    return (
//        <Dropdown className="user-select-none" isOpen={expanded} toggle={toggle}>
//            <DropdownToggle data-toggle="dropdown" tag="div" caret className="btn btn-outline-secondary dropdown-toggle d-flex align-items-center">
//                <div className="PhoneInputCountryIcon">
//                    <img className="PhoneInputCountryIconImg"
//                        alt={labels[value]}
//                        src={"https://purecatamphetamine.github.io/country-flag-icons/3x2/" + value + ".svg"} />
//                </div>
//            </DropdownToggle>
//            <DropdownMenu className="mt-2 pt-0" style={{ maxHeight: "200px", overflowY: "auto", overflowX: "hidden" }}>
//                <DropdownItem onClick={() => onChange("")}>{labels["ZZ"]}</DropdownItem>
//                {getCountries().map((country) => (
//                    <DropdownItem onClick={() => onChange(country)}>{labels[country]}</DropdownItem>
//                ))}
//            </DropdownMenu>
//        </Dropdown>
//    );
//};

//export const Phone = ({ name, value, onChange, onBlur }) => {
//    const defaultCountry = 'FR';
//    const [country, setCountry] = useState(defaultCountry);

//    const onInternalChange = (value) => {
//        onChange && onChange(value);
//    }

//    useEffect(() => {

//    }, [value, country, setCountry]);

//    return (
//        <div className="input-group input-group-sm">


//            {/*<Input country={country} value={phoneNumber} onChange={setPhoneNumber} placeholder="Enter phone number" name="phoneNumber" />*/}

//            <CountrySelect
//                labels={fr}
//                value={country}
//                onChange={setCountry} />

//            <Input
//                name={name}
//                value={value}
//                onChange={onInternalChange}
//                onBlur={onBlur}
//                inputComponent={ForwardedPhoneInput}

//                size="sm"
//                defaultCountry={defaultCountry}
//            />


//            {/*<PhoneInput*/}
//            {/*    name={name}*/}
//            {/*    value={value}*/}
//            {/*    className="form-control"*/}
//            {/*    placeholder="Entrer un numéro de téléphone"*/}
//            {/*    defaultCountry="FR"*/}
//            {/*    onChange={(o) => notify(o)} />*/}
//            <button
//                type="button"
//                className="btn btn-outline-secondary"
//                title="Supprimer la sélection"
//                onClick={() => onInternalChange(null)}>
//                <i className="bi-x-lg"></i>
//            </button>
//        </div>
//    );
//}
