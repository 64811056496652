import { useParams } from 'react-router-dom';
import { Api } from './../../services/Api';
import { EntityForm } from '../EntityForm';
import { HolidayEditor } from './HolidayEditor';
import { holidaySchema } from './HolidayValidation';

export function HolidayDetail() {
    const { id } = useParams();

    const newEntity = ({ user }) => {
        return {
            scope: user.scope,
            name: '',
            isFrenchBankHoliday: false,
            frenchBankHoliday: null,
            dateRanges: [{ start: null, end: null }],
        };
    };

    return (
        <EntityForm
            id={id}
            pageTitle="Congés"
            validationSchema={holidaySchema}
            listUrl="/holidays/"
            getEntityDisplay={({ value }) => value?.name || ''}
            newEntity={newEntity}
            getEntity={({ id }) => Api.getHoliday(id)}
            createEntity={({ value }) => Api.createHoliday(value)}
            updateEntity={({ value }) => Api.updateHoliday(value.id, value)}
            removeEntity={({ id }) => Api.removeHoliday(id)}
            canDelete={({ user, value }) => !value.isSystem}
            canEdit={({ user, value }) => !value.isSystem}
            isSync>
            {(props) => <HolidayEditor {...props} />}
        </EntityForm>
    );
}