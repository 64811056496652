import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Autocomplete } from "../Autocomplete";
import { Api } from './../../services/Api';
import { Input } from 'reactstrap';
import { EntityList, useUrlSearchParam } from './../EntityList';

const NullableIntParser = (o) => o ? +o : null;
const NullableBoolParser = (o) => o && o.length ? o.toLowerCase(o) === "true" : null;

const EntitySyncsFilters = ({ setParams }) => {
    const [tenantConfigId, setTenantConfigId] = useUrlSearchParam("tenantConfigId", null, NullableIntParser);
    const [targetType] = useUrlSearchParam("targetType", null, NullableIntParser);
    const [action] = useUrlSearchParam("action", null, NullableIntParser);
    const [failed] = useUrlSearchParam("failed", null, NullableBoolParser);
    const [tenantConfig, setTenantConfig] = useState(null);
    const [hydrateTenantConfigRequest, setHydrateTenantConfigRequest] = useState(null);

    // init filters
    useEffect(() => {
        if (tenantConfigId !== tenantConfig?.id) {
            if (tenantConfigId) {
                if (hydrateTenantConfigRequest == null) {
                    var request = Api.getTenantConfig(tenantConfigId);
                    setHydrateTenantConfigRequest(request);
                    request.then(data => {
                        setTenantConfig(data);
                    }).catch(() => {
                        setTenantConfigId(null);
                        setTenantConfig(null);
                    }).finally(() => {
                        setHydrateTenantConfigRequest(null);
                    });
                }
            } else {
                if (hydrateTenantConfigRequest != null) {
                    hydrateTenantConfigRequest.abort();
                }
                setTenantConfig(null);
            }
        }
    }, [tenantConfigId, setTenantConfigId, tenantConfig, setTenantConfig, hydrateTenantConfigRequest, setHydrateTenantConfigRequest]);

    return (
        <>
            <Autocomplete
                className="col mb-3"
                inputPlaceholder="Filtrer par tenantConfig ..."
                value={tenantConfig}
                getMatches={async (o) => (await Api.getTenantConfigs(o)).values}
                itemToString={option => option?.ozmoCode || ''}
                onChange={(o) => {
                    setTenantConfig(o);
                    setParams({ tenantConfigId: o?.id, page: null });
                }} />
            <div className="col mb-3">
                <Input type="select"
                    onChange={(o) => {
                        const value = NullableIntParser(o.target.value);
                        setParams({ targetType: value, page: null });
                    }}>
                    <option value={""} selected={targetType == null}>Tous les types</option>
                    <option value={0} selected={targetType === 0}>Congé</option>
                    <option value={1} selected={targetType === 1}>Groupe d'agents</option>
                    <option value={2} selected={targetType === 2}>Standard</option>
                    <option value={3} selected={targetType === 3}>Boite vocale</option>
                </Input>
            </div>
            <div className="col mb-3">
                <Input type="select"
                    onChange={(o) => {
                        const value = NullableIntParser(o.target.value);
                        setParams({ action: value, page: null });
                    }}>
                    <option value={""} selected={action == null}>Toutes les actions</option>
                    <option value={0} selected={action === 0}>A mettre à jour</option>
                    <option value={1} selected={action === 1}>Aucune</option>
                    <option value={-1} selected={action === -1}>A supprimer</option>
                </Input>
            </div>
            <div className="col mb-3">
                <Input type="select"
                    onChange={(o) => {
                        const value = NullableBoolParser(o.target.value);
                        setParams({ failed: value, page: null });
                    }}>
                    <option value={""} selected={failed == null}>Tous les états</option>
                    <option value={true} selected={failed === 0}>Erreur</option>
                    <option value={false} selected={failed === 1}>Succès</option>
                </Input>
            </div>
        </>
    );
}

export const EntitySyncs = () => {
    const [tenantConfigId] = useUrlSearchParam("tenantConfigId", null, NullableIntParser);
    const [targetType] = useUrlSearchParam("targetType", null, NullableIntParser);
    const [action] = useUrlSearchParam("action", null, NullableIntParser);
    const [failed] = useUrlSearchParam("failed", null, NullableBoolParser);

    return (
        <EntityList
            pageTitle="Synchronisations"
            getEntities={(params) => Api.getEntitySyncs({ ...params, tenantConfigId, targetType, action, failed })}
            searcheable={false}
            hideTenantFilter={true}
            hideOrgUnitFilter={true}
            hideSubOrgUnitFilter={true}
            canAdd={() => false}
            filtersAddon={(setParams) => <EntitySyncsFilters setParams={setParams} />}
            columns={[
                {
                    sortBy: 'id',
                    header: "#",
                    renderCell: (o) => <Link to={`/admin/entitysyncs/${o.id}`}>{o.id}</Link>
                },
                {
                    sortBy: 'tenantConfig',
                    header: "Config",
                    renderCell: (o) => o.tenantConfig.ozmoCode + " (" + o.tenantConfig.azureDomain + ")"
                },
                {
                    sortBy: 'targetType',
                    header: "Type",
                    renderCell: (o) => {
                        switch (o.targetType) {
                            case 0: return "Congé";
                            case 1: return "Groupe d'agents";
                            case 2: return "Standard";
                            case 3: return "Boite vocale";
                            default: return "NC";
                        }
                    }
                },
                {
                    sortBy: 'targetId',
                    header: "Cible",
                    renderCell: (o) => o.targetDisplay
                },
                {
                    sortBy: 'action',
                    header: "Action",
                    renderCell: (o) => {
                        switch (o.action) {
                            case 0: return "A mettre à jour";
                            case 1: return "Aucune";
                            case -1: return "A supprimer";
                            default: return "NC";
                        }
                    }
                },
                {
                    sortBy: 'consecutiveFailures',
                    header: "Etat",
                    renderCell: (o) => o.consecutiveFailures > 0 ? 'Erreur' : 'Succès'
                }]}
        />
    );
}
