import { useField } from 'formik';
import { Input } from 'reactstrap';
import { Api } from '../../services/Api';
import { useUser } from '../../services/UserContext';
import { Autocomplete } from '../Autocomplete';
import { EntityFormField } from '../EntityFormField';

export const OrgUnitEditor = ({ id, readonly }) => {
    const [name] = useField('name');
    const [tenant, , tenantHelpers] = useField('tenant');
    const user = useUser();

    return (
        <div>
            <EntityFormField label="Tenant" field={tenant}>
                <Autocomplete
                    {...tenant}
                    disabled={readonly || id > 0 || user.scope.tenant}
                    getMatches={async (params) => (await Api.getTenants(params)).values}
                    size="sm"
                    onChange={(value) => {
                        tenantHelpers.setTouched(true);
                        tenantHelpers.setValue(value);
                    }} />
            </EntityFormField>
            <EntityFormField label="Nom" field={name}>
                <Input {...name} bsSize="sm" disabled={readonly} />
            </EntityFormField>
        </div>
    );
}