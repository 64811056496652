import { useField } from 'formik';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
import { EntityFormField } from '../EntityFormField';

export const EntitySyncEditor = () => {
    const [tenantConfig] = useField('tenantConfig');
    const [action] = useField('action');
    const [state] = useField('state');
    const [targetType] = useField('targetType');
    const [targetId] = useField('targetId');
    const [targetDisplay] = useField('targetDisplay');
    const [lastSync] = useField('lastSync');
    const [consecutiveFailures] = useField('consecutiveFailures');
    const [lastFailure] = useField('lastFailure');
    const [lastFailureMessage] = useField('lastFailureMessage');

    return (
        <div>
            <EntityFormField label="TenantConfig" field={tenantConfig}>
                <div className="form-control-sm form-control bg-light">{tenantConfig.value.ozmoCode} ({tenantConfig.value.azureDomain})</div>
            </EntityFormField>
            <EntityFormField label="Type" field={targetType}>
                <Input {...targetType} bsSize="sm" readOnly disabled type="select">
                    <option value={0}>Congé</option>
                    <option value={1}>Groupe d'agents</option>
                    <option value={2}>Standard</option>
                    <option value={3}>Boite vocale</option>
                </Input>
            </EntityFormField>
            {targetDisplay.value ?
                (
                    <EntityFormField label="Cible" field={targetDisplay}>
                        <div className="form-control-sm form-control bg-light">
                            {targetType.value === 0 && <Link to={`/holidays/${targetId.value}`}>{targetDisplay.value}</Link>}
                            {targetType.value === 1 && <Link to={`/callqueues/${targetId.value}`}>{targetDisplay.value}</Link>}
                            {targetType.value === 2 && <Link to={`/autoattendants/${targetId.value}`}>{targetDisplay.value}</Link>}
                            {targetType.value === 3 && <Link to={`/sharedvoicemails/${targetId.value}`}>{targetDisplay.value}</Link>}
                        </div>
                    </EntityFormField>
                ) : (
                    <EntityFormField label="Cible (Id)" field={targetId}>
                        <Input {...targetId} bsSize="sm" readOnly disabled />
                    </EntityFormField>
                )
            }
            <EntityFormField label="Action" field={action}>
                <Input {...action} bsSize="sm" readOnly disabled type="select">
                    <option value={0}>A mettre à jour</option>
                    <option value={1}>Aucune</option>
                    <option value={-1}>A supprimer</option>
                </Input>
            </EntityFormField>
            <EntityFormField label="Etat" field={state}>
                <Input {...state} bsSize="sm" readOnly disabled type="select">
                    <option value={0}>A synchroniser</option>
                    <option value={1}>Synchronisé</option>
                    <option value={-1}>En échec</option>
                </Input>
            </EntityFormField>
            <EntityFormField label="Dernière synchronisation effectuée avec succès" field={lastSync}>
                <Input value={lastSync.value ? (moment(lastSync.value).format("DD/MM/YYYY HH:mm")) : "NC"} bsSize="sm" readOnly disabled />
            </EntityFormField>
            <EntityFormField label="Dernière erreur de synchronisation" field={lastFailure}>
                <Input value={lastFailure.value ? (moment(lastFailure.value).format("DD/MM/YYYY HH:mm")) : "NC"} bsSize="sm" readOnly disabled />
            </EntityFormField>
            <EntityFormField label="Nombre d'erreur consécutives" field={consecutiveFailures}>
                <Input {...consecutiveFailures} bsSize="sm" readOnly disabled />
            </EntityFormField>
            <EntityFormField label="Dernier message d'erreur" field={lastFailureMessage}>
                <pre className="form-control-sm form-control bg-light" style={{ whiteSpace: 'pre-wrap' }}>{lastFailureMessage.value}</pre>
            </EntityFormField>
        </div>
    );
}
