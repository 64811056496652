import { Link } from "react-router-dom";
import { UserLevels } from "../../services/UserLevels";
import { UserRoles } from "../../services/UserRoles";
import { Api } from '../../services/Api';
import { EntityList } from '../EntityList';

export const OrgUnits = () => {

    return (
        <EntityList
            pageTitle="Organisations"
            getEntities={(params) => Api.getOrgUnits(params)}
            entityCreationUrl="/admin/orgunits/new"
            canAdd={({ user }) => user && user.role >= UserRoles.ADMIN && user.level >= UserLevels.TENANT}
            hideOrgUnitFilter={true}
            hideSubOrgUnitFilter={true}
            columns={[{
                sortBy: 'name',
                header: "Nom",
                renderCell: (o) => <Link to={`/admin/orgunits/${o.id}`}>{o.name}</Link>,
            }]} />
    );
}
