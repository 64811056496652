import { useParams } from 'react-router-dom';
import { UserLevels } from '../../services/UserLevels';
import { UserRoles } from '../../services/UserRoles';
import { EntityForm } from '../EntityForm';
import { Api } from './../../services/Api';
import { TenantEditor } from './TenantEditor';
import { tenantSchema } from './TenantValidation';

export function TenantDetail() {
    const { id } = useParams();
    const newEntity = () => {
        return {
            name: '',
            ozmoCode: '',
            azureDomain: '',
            status: 0
        };
    };

    return (
        <EntityForm
            id={id}
            pageTitle="Tenants"
            listUrl="/admin/tenants/"
            validationSchema={tenantSchema}
            getEntityDisplay={({ value }) => value?.name || ''}
            newEntity={newEntity}
            getEntity={({ id }) => Api.getTenant(id)}
            createEntity={({ value }) => Api.createTenant(value)}
            updateEntity={({ value }) => Api.updateTenant(value.id, value)}
            removeEntity={({ id }) => Api.removeTenant(id)}
            canEdit={({ user }) => user && user.role >= UserRoles.SUPERADMIN && user.level >= UserLevels.ROOT}
            canDelete={({ user }) => user && user.role >= UserRoles.SUPERADMIN && user.level >= UserLevels.ROOT}>
            {(props) => <TenantEditor {...props} />}
        </EntityForm>
    );
}