import { useState } from 'react';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { useUser } from '../services/UserContext';
import { UserRoles } from '../services/UserRoles';
import { DateFormatter } from './DateFormatter';

export const EntitySyncState = ({ value }) => {
    const user = useUser();
    const [displayErrorPopover, setDisplayErrorPopover] = useState(false);

    const toggleErrorPopover = () => {
        setDisplayErrorPopover(!displayErrorPopover);
    };

    if (!value) {
        return null;
    } else {
        return (
            <div>
                {value.state === 0 &&
                    <span>
                        En attente de synchronisation
                        {value.lastSync &&
                            <> (dernière synchronisation réussie le <DateFormatter date={value.lastSync} />)</>
                        }
                    </span>
                }
                {value.state === 1 &&
                    <span>Synchronisé le <DateFormatter date={value.lastSync} /></span>
                }
                {value.state === -1 &&
                    <>
                        {(value.lastFailureMessage && user.role === UserRoles.SUPERADMIN) ?
                            (
                                <span>
                                    <button id="syncErrorDetail" className="link-button ps-2" type="button" title="Voir le détail de l'erreur">Synchronisation en erreur</button>
                                    <Popover toggle={toggleErrorPopover} placement="top" trigger="legacy" isOpen={displayErrorPopover} target="syncErrorDetail" delay={0}>
                                        <PopoverHeader className="bg-danger text-white">Détails de l'erreur</PopoverHeader>
                                        <PopoverBody>
                                            <pre className="mb-0" style={{ whiteSpace: 'pre-wrap' }}>{value.lastFailureMessage}</pre>
                                        </PopoverBody>
                                    </Popover>
                                </span>
                            ) : (
                                <span>Synchronisation en erreur</span>
                            )
                        }
                        <span> depuis le <DateFormatter date={value.lastFailure} /></span>
                        {(value.consecutiveFailures > 1 || value.lastSync != null) &&
                            <>
                                <span> (</span>
                                {value.consecutiveFailures > 1 && <span>{value.consecutiveFailures} erreurs consécutives</span>}
                                {value.lastSync && <span>, dernier succès le <DateFormatter date={value.lastSync} /></span>}
                                <span>)</span>
                            </>
                        }
                    </>
                }
            </div>
        );
    }
}