import { useState, useEffect } from 'react';
import { Error } from './Error';
import { Api } from './../services/Api';
import { UserProvider } from './../services/UserContext';

export const Authenticate = ({ children }) => {
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {

        const request = Api.getUserSession();
        request.then(data => {
            if (!request.aborted) {
                setUser(data);
                setError(null);
            }
        }).catch(error => {
            if (!request.aborted) {
                setUser(null);
                setError(error);
            }
        });

        //return request.abort;
    }, []);

    return (
        <>
            {error && <Error error={error} />}
            {user && <UserProvider value={user}>{children}</UserProvider>}
        </>
    );
}
