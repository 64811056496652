import { useParams } from 'react-router-dom';
import { EntityForm } from '../EntityForm';
import { Api } from './../../services/Api';
import { Config } from './../../services/Config';
import { AutoAttendantEditor } from './AutoAttendantEditor';
import { autoAttendantSchema } from './AutoAttendantValidation';

export function AutoAttendantDetail() {
    const { id } = useParams();

    const newCallFlow = () => {
        return {
            greetings: {
                activeType: 0,
                textToSpeech: null,
                audioFile: null
            },
            action: 0,
            agent: null,
            callQueue: null,
            autoAttendant: null,
            sharedVoicemail: null,
            enableSharedVoicemailTranscription: false,
            enableSharedVoicemailSystemPromptSuppression: false,
            externalPstn: null,
            menu: null
        };
    };

    const newEntity = ({ user }) => {
        return {
            scope: user.scope,
            name: '',
            language: 'fr-FR',
            timeZone: 'Romance Standard Time',
            bypass: false,
            bypassCallTarget: null,
            businessHours: Config.getDefaultBusinessWeekHours(),
            defaultCallFlow: newCallFlow(),
            afterHoursCallFlow: newCallFlow(),
            holidayCallFlows: [],
            resourceAccounts: [],
        };
    };

    return (
        <EntityForm
            id={id}
            pageTitle="Standards"
            validationSchema={autoAttendantSchema}
            listUrl="/autoattendants/"
            getEntityDisplay={({ value }) => value?.name || ''}
            newEntity={newEntity}
            getEntity={({ id }) => Api.getAutoAttendant(id)}
            createEntity={({ value }) => Api.createAutoAttendant(value)}
            updateEntity={({ value }) => Api.updateAutoAttendant(value.id, value)}
            removeEntity={({ id }) => Api.removeAutoAttendant(id)}
            isSync>
            {(props) => <AutoAttendantEditor {...props} />}
        </EntityForm>
    );
}