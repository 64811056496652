import { mergeClassNames } from '../services/ComponentHelper';
import { Env } from '../services/Env';
import { useMenuToggle } from '../services/MenuToggleContext';
import { UserStatus } from './UserStatus';

export const PageHeader = ({ className, children }) => {
    const { toggleCollapsed } = useMenuToggle();
    const helpUrl = Env.helpUrl;

    return (
        <div className={mergeClassNames(className, "d-flex bg-light pe-3")}>
            <div className="d-flex align-items-center me-auto px-3 py-2">
                <div
                    className="me-3 cursor-pointer"
                    onClick={() => toggleCollapsed()}
                    title="Déplier/replier le menu">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="bi" fill="currentColor" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
                    </svg>
                </div>
                {children}
            </div>
            {helpUrl != null && helpUrl.length &&
                <a href={helpUrl} className="p-3 py-0 d-flex align-items-center page-header-button" title="Aller sur l'aide en ligne" target="_blank" rel="noreferrer">
                    <i className="bi bi-question-circle text-primary fs-5" />
                </a>
            }
            <div className="d-flex align-items-center page-header-button">
                <UserStatus />
            </div>
        </div>
    );
};