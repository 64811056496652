import { useEffect, useState } from 'react';
import { Autocomplete } from './../Autocomplete';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ResourceAccountThumb } from './ResourceAccountThumb';

export const ResourceAccountsSelectorModal = ({ isOpen, toggle, onChange, getMatches }) => {
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => setSelectedValue(null), [isOpen]);

    const onModalConfirm = () => {
        onChange && onChange(selectedValue);
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader>Ajouter un compte de ressource</ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    <Autocomplete
                        id="add-agents-lookup"
                        value={selectedValue}
                        //getMatches={async (params) => await getMatches(params)}
                        getMatches={getMatches}
                        onChange={setSelectedValue}
                        size="sm"
                        inputPlaceholder="Rechercher un compte de ressource"
                        renderMenuItem={(o) => <ResourceAccountThumb value={o} />} />
                </div>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    className="btn btn-link"
                    onClick={toggle}>Annuler</button>
                <button
                    type="button"
                    className="btn btn-primary ms-2"
                    disabled={selectedValue == null}
                    onClick={onModalConfirm}>Ajouter</button>
            </ModalFooter>
        </Modal>
    );
}