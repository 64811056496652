import { useState } from 'react';
import { useScope } from '../../services/ScopeContext';
import { Api } from './../../services/Api';
import { EntityRefThumbnail } from '../EntityRefThumbnail';
import { CallQueueAgentsModal } from './CallQueueAgentsModal';

export const CallQueueAgents = ({ value, onChange, disabled }) => {
    const scope = useScope();
    const [showAgentsModal, setShowAgentsModal] = useState(false);
    const [selectedAgentIndex, setSelectedAgentIndex] = useState(-1);

    const toggleAgentsModal = () => {
        setShowAgentsModal(!showAgentsModal);
    }

    const selectAgent = (index) => {
        setSelectedAgentIndex(selectedAgentIndex === index ? - 1 : index);
    }

    const moveItem = (array, from, to) => {
        var f = array.splice(from, 1)[0];
        array.splice(to, 0, f);
    }

    const upAgent = () => {
        let newValue = [...value];
        let newIndex = Math.max(0, selectedAgentIndex - 1);
        moveItem(newValue, selectedAgentIndex, newIndex);
        setSelectedAgentIndex(newIndex);
        notify(newValue);
    }

    const downAgent = () => {
        let newValue = [...value];
        let newIndex = Math.min(newValue.length, selectedAgentIndex + 1);
        moveItem(newValue, selectedAgentIndex, newIndex);
        setSelectedAgentIndex(newIndex);
        notify(newValue);
    }

    const removeAgent = () => {
        let newValue = [...value];
        newValue.splice(selectedAgentIndex, 1);
        notify(newValue);
    }

    const onSearch = async (params) => {
        try {
            const args = Api.buildScopedParams(params, scope);
            const data = (await Api.lookupAgents(args)).values;
            const ids = value.map(o => o.id);
            return data.filter(a => ids.indexOf(a.id) < 0);
        } catch (error) {
            alert(error.status + ": " + error.message);
        }
    }

    const onModalConfirm = (results) => {
        let newValue = [...value];
        if (results && results.length) {
            newValue = newValue.concat(results);
        }

        notify(newValue);
        toggleAgentsModal();
    }

    const notify = (value) => {
        onChange && onChange(value);
    }


    if (value == null) return null;

    return (
        <div className="card">
            <div className="p-2 bg-light border-bottom card-header">
                <div className="d-flex flex-row justify-content-between">
                    <div>
                        <button
                            type="button"
                            disabled={disabled}
                            className="btn btn-sm btn-primary me-2"
                            onClick={() => toggleAgentsModal()}
                            title="Ajouter">
                            <i className="bi-plus"></i>
                            <span className="ps-1 d-none d-sm-inline">Ajouter</span>
                        </button>
                        <button
                            type="button"
                            className="btn btn-sm btn-danger me-2"
                            disabled={disabled || selectedAgentIndex < 0 || selectedAgentIndex >= value.length}
                            onClick={() => removeAgent()}
                            title="Supprimer">
                            <i className="bi-trash"></i>
                            <span className="ps-1 d-none d-sm-inline">Supprimer</span>
                        </button>
                        <button
                            type="button"
                            className="btn btn-sm btn-secondary me-2"
                            disabled={disabled || selectedAgentIndex <= 0}
                            onClick={() => upAgent()}
                            title="Monter">
                            <i className="bi-arrow-up"></i>
                            <span className="ps-1 d-none d-sm-inline">Monter</span>
                        </button>
                        <button
                            type="button"
                            className="btn btn-sm btn-secondary me-2"
                            disabled={disabled || selectedAgentIndex < 0 || selectedAgentIndex >= value.length - 1}
                            onClick={() => downAgent()}
                            title="Descendre">
                            <i className="bi-arrow-down"></i>
                            <span className="ps-1 d-none d-sm-inline">Descendre</span>
                        </button>
                    </div>
                    <div className="d-flex align-items-center">{value.length} agent(s)</div>
                </div>
            </div>
            <div className="table-responsive">
                <table className='table table-hover m-0'>
                    <colgroup>
                        <col width="50px" />
                        <col />
                    </colgroup>
                    <tbody>
                        {value && value.map((agent, index) =>
                            <tr
                                key={agent.id}
                                onClick={() => !disabled && selectAgent(index)}
                                role="button"
                                className={(selectedAgentIndex === index ? "table-primary" : "")}>
                                <th className="align-middle p-3">
                                    <div className="bg-primary text-white rounded-circle text-center"
                                        style={{ lineHeight: '2em', width: '2em', height: '2em' }}>{index + 1}</div>
                                </th>
                                <td className="align-middle p-3">
                                    <EntityRefThumbnail value={agent} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {value.length === 0 &&
                    <div className="p-3 text-center text-muted">Aucun</div>
                }
            </div>
            <CallQueueAgentsModal
                isOpen={showAgentsModal}
                toggle={toggleAgentsModal}
                getMatches={onSearch}
                onChange={onModalConfirm}
            />
        </div>
    );
}
