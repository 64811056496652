import { Link } from "react-router-dom";
import { EntityList } from "../EntityList";
import { Api } from './../../services/Api';

export const Holidays = () => {

    return (
        <EntityList
            pageTitle="Congés"
            getEntities={(params) => Api.getHolidays(params)}
            entityCreationUrl="/holidays/new"
            columns={[{
                sortBy: 'name',
                header: "Nom",
                renderCell: (o) => <Link to={`/holidays/${o.id}`}>{o.name}</Link>,
            }]}
            header={() =>
                <>
                    Les congés sont utilisés pour définir des dates et heures spécifiques pendant lesquelles votre organisation sera fermée et indisponible pendant les heures d'ouverture.
                    Les congés définis ici peuvent être ensuite associés à des standards automatiques que vous créez dans votre organisation.
                </>
            }
        />
    );
}
