import { useState } from 'react';
import { useScope } from '../../services/ScopeContext';
import { Api } from './../../services/Api';
import { EntityRefThumbnail } from './../EntityRefThumbnail';
import { SharedVoicemailAgentsModal } from './SharedVoicemailAgentsModal';

export const SharedVoicemailAgents = ({ value, disabled, onChange }) => {
    const scope = useScope();
    const [showAgentsModal, setShowAgentsModal] = useState(false);
    const [selectedAgentIndex, setSelectedAgentIndex] = useState(-1);

    value = value ?? [];

    const toggleAgentsModal = () => {
        setShowAgentsModal(!showAgentsModal);
    }

    const selectAgent = (index) => {
        setSelectedAgentIndex(selectedAgentIndex === index ? - 1 : index);
    }

    const removeAgent = () => {
        let newValue = [...value];
        newValue.splice(selectedAgentIndex, 1);
        notify(newValue);
    }

    const onSearch = async (params) => {
        try {
            const args = Api.buildScopedParams(params, scope);
            const data = (await Api.lookupAgents(args)).values;
            const ids = value.map(o => o.id);
            return data.filter(a => ids.indexOf(a.id) < 0);
        } catch (error) {
            alert(error.status + ": " + error.message);
        }
    }

    const onModalConfirm = (results) => {
        let newValue = [...value];
        if (results && results.length) {
            newValue = newValue.concat(results);
        }

        notify(newValue);
        toggleAgentsModal();
    }

    const notify = (value) => {
        onChange && onChange(value);
    }



    return (
        <div className="card">
            <div className="p-2 bg-light border-bottom card-header">
                <div className="d-flex flex-row justify-content-between">
                    <div>
                        <button
                            type="button"
                            disabled={disabled}
                            className="btn btn-sm btn-primary me-3"
                            onClick={() => toggleAgentsModal()}
                            title="Ajouter">
                            <i className="bi-plus"></i>
                            <span className="ps-1 d-none d-sm-inline">Ajouter</span>
                        </button>
                        <button
                            type="button"
                            className="btn btn-sm btn-danger me-3"
                            disabled={disabled || selectedAgentIndex < 0 || selectedAgentIndex >= value.length}
                            onClick={() => removeAgent()}
                            title="Supprimer">
                            <i className="bi-trash"></i>
                            <span className="ps-1 d-none d-sm-inline">Supprimer</span>
                        </button>
                    </div>
                    <div className="d-flex align-items-center">{value.length} membre(s)</div>
                </div>
            </div>
            <div className="table-responsive">
                <table className='table table-hover m-0'>
                    <colgroup>
                        <col />
                    </colgroup>
                    <tbody>
                        {value != null && value.map((agent, index) =>
                            <tr
                                key={agent.id}
                                onClick={() => !disabled && selectAgent(index)}
                                role="button"
                                className={(selectedAgentIndex === index ? "table-primary" : "")}>
                                <td className="align-middle p-3">
                                    <EntityRefThumbnail value={agent} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {value != null && value.length === 0 &&
                    <div className="p-3 text-center text-muted">Aucun</div>
                }
            </div>
            <SharedVoicemailAgentsModal
                isOpen={showAgentsModal}
                toggle={toggleAgentsModal}
                getMatches={onSearch}
                onChange={onModalConfirm}
            />
        </div>
    );
}
