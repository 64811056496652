import { useField } from 'formik';
import { Link } from 'react-router-dom';
import { DateFormatter } from './DateFormatter';

export const EntityAuditTrail = () => {
    const [cTime] = useField("cTime");
    const [mTime] = useField("mTime");
    const [cUser] = useField("cUser");
    const [mUser] = useField("mUser");

    return (
        <div>
            <div>Créé le <DateFormatter date={cTime.value} /> par <Link to={`/admin/users/${cUser.value.id}`}>{cUser.value.name}</Link></div>
            <div>Modifié le <DateFormatter date={mTime.value} /> par <Link to={`/admin/users/${mUser.value.id}`}>{mUser.value.name}</Link></div>
        </div>
    );
}