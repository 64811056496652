import { useField } from 'formik';
import { useEffect } from 'react';
import { Input } from 'reactstrap';
import { useUser } from '../../services/UserContext';
import { UserLevels } from '../../services/UserLevels';
import { UserRoles } from '../../services/UserRoles';
import { EntityFormField } from '../EntityFormField';
import { SwitchInput } from '../SwitchInput';
import { UserScopeEditor } from './UserScopeEditor';

export const UserEditor = ({ id, readonly }) => {
    const [upn] = useField('upn');
    const [firstName] = useField('firstName');
    const [lastName] = useField('lastName');
    const [level] = useField('level');
    const [enabled] = useField('enabled');
    const [role, , roleHelpers] = useField('role');
    const user = useUser();

    useEffect(() => {
        if (role.value === UserRoles.SUPERADMIN && level.value < UserLevels.ROOT) {
            roleHelpers.setValue(UserRoles.READER);
        }
    }, [level.value, role.value, roleHelpers]);

    return (
        <>
            <EntityFormField label="Prénom" field={firstName}>
                <Input {...firstName} disabled={true} bsSize="sm" />
            </EntityFormField>
            <EntityFormField label="Nom de famille" field={lastName}>
                <Input {...lastName} disabled={true} bsSize="sm" />
            </EntityFormField>
            <EntityFormField label="Upn" field={upn}>
                <Input {...upn} disabled={true} bsSize="sm" />
            </EntityFormField>
            <EntityFormField label="Statut" field={enabled}>
                <SwitchInput {...enabled} disabled={readonly} trueLabel="Activé" falseLabel="Désactivé" />
            </EntityFormField>
            <EntityFormField label="Rôle" field={role}>
                <Input {...role}
                    bsSize="sm"
                    disabled={readonly || role.value > user.role}
                    type="select"
                    placeholder="Choisissez une valeur"
                    onChange={(e) => roleHelpers.setValue(+(e.target.value))}>
                    <option disabled={user.role < UserRoles.READER} value={UserRoles.READER}>Lecteur</option>
                    <option disabled={user.role < UserRoles.WRITER} value={UserRoles.WRITER}>Rédacteur</option>
                    <option disabled={user.role < UserRoles.ADMIN} value={UserRoles.ADMIN}>Administrateur</option>
                    <option disabled={user.role < UserRoles.SUPERADMIN || level.value !== UserLevels.ROOT} value={UserRoles.SUPERADMIN}>Super Administrateur</option>
                </Input>
            </EntityFormField>
            <UserScopeEditor readonly={readonly}/>
        </>
    );
}
