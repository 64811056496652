import { DateTimeUtils } from './DateTimeUtils';


const timezones = [
    {
        "value": "Dateline Standard Time",
        "label": "(UTC-12:00) International Date Line West"
    },
    {
        "value": "UTC-11",
        "label": "(UTC-11:00) Coordinated Universal Time-11"
    },
    {
        "value": "Hawaiian Standard Time",
        "label": "(UTC-10:00) Hawaii"
    },
    {
        "value": "Alaskan Standard Time",
        "label": "(UTC-09:00) Alaska"
    },
    {
        "value": "Pacific Standard Time (Mexico)",
        "label": "(UTC-08:00) Baja California"
    },
    {
        "value": "Pacific Standard Time",
        "label": "(UTC-08:00) Pacific Time (US \u0026 Canada)"
    },
    {
        "value": "US Mountain Standard Time",
        "label": "(UTC-07:00) Arizona"
    },
    {
        "value": "Mountain Standard Time (Mexico)",
        "label": "(UTC-07:00) Chihuahua, La Paz, Mazatlan"
    },
    {
        "value": "Mountain Standard Time",
        "label": "(UTC-07:00) Mountain Time (US \u0026 Canada)"
    },
    {
        "value": "Central America Standard Time",
        "label": "(UTC-06:00) Central America"
    },
    {
        "value": "Central Standard Time",
        "label": "(UTC-06:00) Central Time (US \u0026 Canada)"
    },
    {
        "value": "Central Standard Time (Mexico)",
        "label": "(UTC-06:00) Guadalajara, Mexico City, Monterrey"
    },
    {
        "value": "Canada Central Standard Time",
        "label": "(UTC-06:00) Saskatchewan"
    },
    {
        "value": "SA Pacific Standard Time",
        "label": "(UTC-05:00) Bogota, Lima, Quito, Rio Branco"
    },
    {
        "value": "Eastern Standard Time (Mexico)",
        "label": "(UTC-05:00) Chetumal"
    },
    {
        "value": "Eastern Standard Time",
        "label": "(UTC-05:00) Eastern Time (US \u0026 Canada)"
    },
    {
        "value": "US Eastern Standard Time",
        "label": "(UTC-05:00) Indiana (East)"
    },
    {
        "value": "Venezuela Standard Time",
        "label": "(UTC-04:00) Caracas"
    },
    {
        "value": "Paraguay Standard Time",
        "label": "(UTC-04:00) Asuncion"
    },
    {
        "value": "Atlantic Standard Time",
        "label": "(UTC-04:00) Atlantic Time (Canada)"
    },
    {
        "value": "Central Brazilian Standard Time",
        "label": "(UTC-04:00) Cuiaba"
    },
    {
        "value": "SA Western Standard Time",
        "label": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan"
    },
    {
        "value": "Newfoundland Standard Time",
        "label": "(UTC-03:30) Newfoundland"
    },
    {
        "value": "E. South America Standard Time",
        "label": "(UTC-03:00) Brasilia"
    },
    {
        "value": "Argentina Standard Time",
        "label": "(UTC-03:00) Buenos Aires"
    },
    {
        "value": "SA Eastern Standard Time",
        "label": "(UTC-03:00) Cayenne, Fortaleza"
    },
    {
        "value": "Greenland Standard Time",
        "label": "(UTC-03:00) Greenland"
    },
    {
        "value": "Montevideo Standard Time",
        "label": "(UTC-03:00) Montevideo"
    },
    {
        "value": "Bahia Standard Time",
        "label": "(UTC-03:00) Salvador"
    },
    {
        "value": "Pacific SA Standard Time",
        "label": "(UTC-03:00) Santiago"
    },
    {
        "value": "UTC-02",
        "label": "(UTC-02:00) Coordinated Universal Time-02"
    },
    {
        "value": "Mid-Atlantic Standard Time",
        "label": "(UTC-02:00) Mid-Atlantic - Old"
    },
    {
        "value": "Azores Standard Time",
        "label": "(UTC-01:00) Azores"
    },
    {
        "value": "Cape Verde Standard Time",
        "label": "(UTC-01:00) Cabo Verde Is."
    },
    {
        "value": "Morocco Standard Time",
        "label": "(UTC) Casablanca"
    },
    {
        "value": "UTC",
        "label": "(UTC) Coordinated Universal Time"
    },
    {
        "value": "GMT Standard Time",
        "label": "(UTC) Dublin, Edinburgh, Lisbon, London"
    },
    {
        "value": "Greenwich Standard Time",
        "label": "(UTC) Monrovia, Reykjavik"
    },
    {
        "value": "W. Europe Standard Time",
        "label": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
    },
    {
        "value": "Central Europe Standard Time",
        "label": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
    },
    {
        "value": "Romance Standard Time",
        "label": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris"
    },
    {
        "value": "Central European Standard Time",
        "label": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb"
    },
    {
        "value": "W. Central Africa Standard Time",
        "label": "(UTC+01:00) West Central Africa"
    },
    {
        "value": "Namibia Standard Time",
        "label": "(UTC+01:00) Windhoek"
    },
    {
        "value": "Jordan Standard Time",
        "label": "(UTC+02:00) Amman"
    },
    {
        "value": "GTB Standard Time",
        "label": "(UTC+02:00) Athens, Bucharest"
    },
    {
        "value": "Middle East Standard Time",
        "label": "(UTC+02:00) Beirut"
    },
    {
        "value": "Egypt Standard Time",
        "label": "(UTC+02:00) Cairo"
    },
    {
        "value": "Syria Standard Time",
        "label": "(UTC+02:00) Damascus"
    },
    {
        "value": "E. Europe Standard Time",
        "label": "(UTC+02:00) E. Europe"
    },
    {
        "value": "South Africa Standard Time",
        "label": "(UTC+02:00) Harare, Pretoria"
    },
    {
        "value": "FLE Standard Time",
        "label": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"
    },
    {
        "value": "Turkey Standard Time",
        "label": "(UTC+02:00) Istanbul"
    },
    {
        "value": "Israel Standard Time",
        "label": "(UTC+02:00) Jerusalem"
    },
    {
        "value": "Kaliningrad Standard Time",
        "label": "(UTC+02:00) Kaliningrad (RTZ 1)"
    },
    {
        "value": "Libya Standard Time",
        "label": "(UTC+02:00) Tripoli"
    },
    {
        "value": "Arabic Standard Time",
        "label": "(UTC+03:00) Baghdad"
    },
    {
        "value": "Arab Standard Time",
        "label": "(UTC+03:00) Kuwait, Riyadh"
    },
    {
        "value": "Belarus Standard Time",
        "label": "(UTC+03:00) Minsk"
    },
    {
        "value": "Russian Standard Time",
        "label": "(UTC+03:00) Moscow, St. Petersburg, Volgograd (RTZ 2)"
    },
    {
        "value": "E. Africa Standard Time",
        "label": "(UTC+03:00) Nairobi"
    },
    {
        "value": "Iran Standard Time",
        "label": "(UTC+03:30) Tehran"
    },
    {
        "value": "Arabian Standard Time",
        "label": "(UTC+04:00) Abu Dhabi, Muscat"
    },
    {
        "value": "Azerbaijan Standard Time",
        "label": "(UTC+04:00) Baku"
    },
    {
        "value": "Russia Time Zone 3",
        "label": "(UTC+04:00) Izhevsk, Samara (RTZ 3)"
    },
    {
        "value": "Mauritius Standard Time",
        "label": "(UTC+04:00) Port Louis"
    },
    {
        "value": "Georgian Standard Time",
        "label": "(UTC+04:00) Tbilisi"
    },
    {
        "value": "Caucasus Standard Time",
        "label": "(UTC+04:00) Yerevan"
    },
    {
        "value": "Afghanistan Standard Time",
        "label": "(UTC+04:30) Kabul"
    },
    {
        "value": "West Asia Standard Time",
        "label": "(UTC+05:00) Ashgabat, Tashkent"
    },
    {
        "value": "Ekaterinburg Standard Time",
        "label": "(UTC+05:00) Ekaterinburg (RTZ 4)"
    },
    {
        "value": "Pakistan Standard Time",
        "label": "(UTC+05:00) Islamabad, Karachi"
    },
    {
        "value": "India Standard Time",
        "label": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi"
    },
    {
        "value": "Sri Lanka Standard Time",
        "label": "(UTC+05:30) Sri Jayawardenepura"
    },
    {
        "value": "Nepal Standard Time",
        "label": "(UTC+05:45) Kathmandu"
    },
    {
        "value": "Central Asia Standard Time",
        "label": "(UTC+06:00) Astana"
    },
    {
        "value": "Bangladesh Standard Time",
        "label": "(UTC+06:00) Dhaka"
    },
    {
        "value": "N. Central Asia Standard Time",
        "label": "(UTC+06:00) Novosibirsk (RTZ 5)"
    },
    {
        "value": "Myanmar Standard Time",
        "label": "(UTC+06:30) Yangon (Rangoon)"
    },
    {
        "value": "SE Asia Standard Time",
        "label": "(UTC+07:00) Bangkok, Hanoi, Jakarta"
    },
    {
        "value": "North Asia Standard Time",
        "label": "(UTC+07:00) Krasnoyarsk (RTZ 6)"
    },
    {
        "value": "China Standard Time",
        "label": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi"
    },
    {
        "value": "North Asia East Standard Time",
        "label": "(UTC+08:00) Irkutsk (RTZ 7)"
    },
    {
        "value": "Singapore Standard Time",
        "label": "(UTC+08:00) Kuala Lumpur, Singapore"
    },
    {
        "value": "W. Australia Standard Time",
        "label": "(UTC+08:00) Perth"
    },
    {
        "value": "Taipei Standard Time",
        "label": "(UTC+08:00) Taipei"
    },
    {
        "value": "Ulaanbaatar Standard Time",
        "label": "(UTC+08:00) Ulaanbaatar"
    },
    {
        "value": "Tokyo Standard Time",
        "label": "(UTC+09:00) Osaka, Sapporo, Tokyo"
    },
    {
        "value": "Korea Standard Time",
        "label": "(UTC+09:00) Seoul"
    },
    {
        "value": "Yakutsk Standard Time",
        "label": "(UTC+09:00) Yakutsk (RTZ 8)"
    },
    {
        "value": "Cen. Australia Standard Time",
        "label": "(UTC+09:30) Adelaide"
    },
    {
        "value": "AUS Central Standard Time",
        "label": "(UTC+09:30) Darwin"
    },
    {
        "value": "E. Australia Standard Time",
        "label": "(UTC+10:00) Brisbane"
    },
    {
        "value": "AUS Eastern Standard Time",
        "label": "(UTC+10:00) Canberra, Melbourne, Sydney"
    },
    {
        "value": "West Pacific Standard Time",
        "label": "(UTC+10:00) Guam, Port Moresby"
    },
    {
        "value": "Tasmania Standard Time",
        "label": "(UTC+10:00) Hobart"
    },
    {
        "value": "Magadan Standard Time",
        "label": "(UTC+10:00) Magadan"
    },
    {
        "value": "Vladivostok Standard Time",
        "label": "(UTC+10:00) Vladivostok, Magadan (RTZ 9)"
    },
    {
        "value": "Russia Time Zone 10",
        "label": "(UTC+11:00) Chokurdakh (RTZ 10)"
    },
    {
        "value": "Central Pacific Standard Time",
        "label": "(UTC+11:00) Solomon Is., New Caledonia"
    },
    {
        "value": "Russia Time Zone 11",
        "label": "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky (RTZ 11)"
    },
    {
        "value": "New Zealand Standard Time",
        "label": "(UTC+12:00) Auckland, Wellington"
    },
    {
        "value": "UTC+12",
        "label": "(UTC+12:00) Coordinated Universal Time+12"
    },
    {
        "value": "Fiji Standard Time",
        "label": "(UTC+12:00) Fiji"
    },
    {
        "value": "Kamchatka Standard Time",
        "label": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old"
    },
    {
        "value": "Tonga Standard Time",
        "label": "(UTC+13:00) Nuku\u0027alofa"
    },
    {
        "value": "Samoa Standard Time",
        "label": "(UTC+13:00) Samoa"
    },
    {
        "value": "Line Islands Standard Time",
        "label": "(UTC+14:00) Kiritimati Island"
    }
];

const languages = [
    {
        "value": "ar-EG",
        "label": "Arabe (Égypte)"
    },
    {
        "value": "ca-ES",
        "label": "Catalan (Catalan)"
    },
    {
        "value": "da-DK",
        "label": "Danois (Danemark)"
    },
    {
        "value": "de-DE",
        "label": "Allemand (Allemagne)"
    },
    {
        "value": "en-AU",
        "label": "Anglais (Australie)"
    },
    {
        "value": "en-CA",
        "label": "Anglais (Canada)"
    },
    {
        "value": "en-GB",
        "label": "Anglais (Royaume-Uni)"
    },
    {
        "value": "en-IN",
        "label": "Anglais (Inde)"
    },
    {
        "value": "en-US",
        "label": "Anglais (États-Unis)"
    },
    {
        "value": "es-ES",
        "label": "Espagnol (Espagne, international)"
    },
    {
        "value": "es-MX",
        "label": "Espagnol (Mexique)"
    },
    {
        "value": "fi-FI",
        "label": "Finnois (Finlande)"
    },
    {
        "value": "fr-CA",
        "label": "Français (Canada)"
    },
    {
        "value": "fr-FR",
        "label": "Français (France)"
    },
    {
        "value": "it-IT",
        "label": "Italien (Italie)"
    },
    {
        "value": "ja-JP",
        "label": "Japonais (Japon)"
    },
    {
        "value": "ko-KR",
        "label": "Coréen (Corée du Sud)"
    },
    {
        "value": "nb-NO",
        "label": "Norvégien, Bokmål (Norvège)"
    },
    {
        "value": "nl-NL",
        "label": "Néerlandais (Pays-Bas)"
    },
    {
        "value": "pl-PL",
        "label": "Polonais (Pologne)"
    },
    {
        "value": "pt-PT",
        "label": "Portugais (Portugal)"
    },
    {
        "value": "pt-BR",
        "label": "Portugais (Brésil)"
    },
    {
        "value": "ru-RU",
        "label": "Russe (Russie)"
    },
    {
        "value": "sv-SE",
        "label": "Suédois (Suède)"
    },
    {
        "value": "zh-CN",
        "label": "Chinois (simplifié, R.P.C.)"
    },
    {
        "value": "zh-HK",
        "label": "Chinois (traditionnel, Hong Kong R.A.S.)"
    },
    {
        "value": "zh-TW",
        "label": "Chinois (traditionnel, Taiwan)"
    },
    {
        "value": "tr-TR",
        "label": "Turc (Turquie)"
    },
    {
        "value": "cs-CZ",
        "label": "Tchèque (République tchèque)"
    },
    {
        "value": "th-TH",
        "label": "Thaï (Thaïlande)"
    },
    {
        "value": "el-GR",
        "label": "Grec (Grèce)"
    },
    {
        "value": "hu-HU",
        "label": "Hongrois (Hongrie)"
    },
    {
        "value": "sk-SK",
        "label": "Slovaque (Slovaquie)"
    },
    {
        "value": "hr-HR",
        "label": "Croate (Croatie)"
    },
    {
        "value": "sl-SI",
        "label": "Slovène (Slovénie)"
    },
    {
        "value": "id-ID",
        "label": "Indonésien (Indonésie)"
    },
    {
        "value": "ro-RO",
        "label": "Roumain (Roumanie)"
    },
    {
        "value": "vi-VN",
        "label": "Vietnamien (Vietnam)"
    }
];

const days = [
    { value: 'monday', label: 'Lundi' },
    { value: 'tuesday', label: 'Mardi' },
    { value: 'wednesday', label: 'Mercredi' },
    { value: 'thursday', label: 'Jeudi' },
    { value: 'friday', label: 'Vendredi' },
    { value: 'saturday', label: 'Samedi' },
    { value: 'sunday', label: 'Dimanche' }
];

const buildTimeValues = (min, max, incr) => {
    let array = [];
    for (var i = min; i <= max; i += incr) {
        array.push({
            value: i,
            label: DateTimeUtils.formatDuration(i)
        });
    }
    return array;
};

const startTimeValues = buildTimeValues(0, (24 * 60) - 15, 15);
const endTimeValues = buildTimeValues(0, (24 * 60), 15);



export const Config = {
    getTimeZones: () => timezones,
    getLanguages: () => languages,
    buildDayHours: (start, end) => [{ start: start * 60, end: end * 60 }],
    buildBusinessWeekHours: (start, end, openOnWeekdEnd) => {
        return {
            monday: Config.buildDayHours(start, end),
            tuesday: Config.buildDayHours(start, end),
            wednesday: Config.buildDayHours(start, end),
            thursday: Config.buildDayHours(start, end),
            friday: Config.buildDayHours(start, end),
            saturday: openOnWeekdEnd ? Config.buildDayHours(start, end) : [],
            sunday: openOnWeekdEnd ? Config.buildDayHours(start, end) : []
        };
    },
    getDefaultBusinessDayHours: () => Config.buildDayHours(9, 19),
    getDefaultBusinessWeekHours: () => Config.buildBusinessWeekHours(9, 19, false),
    getDays: () => days,
    getStartTimeValues: () => startTimeValues,
    getEndTimeValues: () => endTimeValues,
    datePickerConfig: {
        dayLabels: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        monthLabels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        dateFormat: 'DD/MM/YYYY',
        weekStartsOn: 1,
        roundedCorners: true,
        showClearButton: false,
        placeholder: "Sélectionner une date",
        cellPadding: "5px",
        showTodayButton: true,
        todayButtonLabel: "Aujourd'hui",
        autoComplete: "off"
    }
};