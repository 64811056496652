import { useField } from 'formik';
import { useEffect } from 'react';
import { Input } from 'reactstrap';
import { EntityFormField } from '../EntityFormField';
import { Label } from '../Label';
import { PhonePicker } from '../PhonePicker';
import { SwitchInput } from '../SwitchInput';
import { CallTargetLookup } from './../CallTargetLookup';


export const AutoAttendantBypassRedirect = ({ disabled }) => {
    const [bypass, , bypassHelpers] = useField("bypass");
    const [bypassTarget] = useField("bypassTarget");
    const [bypassTargetAction, , bypassTargetActionHelpers] = useField("bypassTarget.action");
    const [bypassTargetExternalPstn, , bypassTargetExternalPstnHelpers] = useField("bypassTarget.externalPstn");
    const [bypassTargetAgent, , bypassTargetAgentHelpers] = useField("bypassTarget.agent");
    const [bypassTargetSharedVoicemail, , bypassTargetSharedVoicemailHelpers] = useField("bypassTarget.sharedVoicemail");


    // FIXME: we should not call setTouched explicitly here, something is wrong
    // To be reworked

    useEffect(() => {
        if (bypass.value && bypassTarget.value == null) {
            setValue(bypassTargetActionHelpers, 0);
            setValue(bypassTargetAgentHelpers, null);
            setValue(bypassTargetExternalPstnHelpers, null);
            setValue(bypassTargetSharedVoicemailHelpers, null);
        }
    }, [bypass.value, bypassTarget.value, bypassTargetActionHelpers, bypassTargetAgentHelpers, bypassTargetExternalPstnHelpers, bypassTargetSharedVoicemailHelpers]);

    const setBypassTargetAction = (action) => {
        setValue(bypassTargetActionHelpers, action);
        setValue(bypassTargetAgentHelpers, null);
        setValue(bypassTargetExternalPstnHelpers, null);
        setValue(bypassTargetSharedVoicemailHelpers, null);
    }

    const setValue = (helpers, value) => {
        helpers.setValue(value);
        // quick fix to trigger validation, otherwise validation is done on next compute
        setTimeout(() => helpers.setTouched(true));
    };

    return (
        <EntityFormField field={bypass} label="Activer le renvoi d'appel ?" info="Permet de renvoyer tous les appels en occultant toutes les stratégies d'acheminement d'appel définies.">
            
            <div className="card px-3 py-2" style={{ background: bypass.value ? "#FFF4D4" : "inherit" }}>
                <SwitchInput {...bypass} onChange={(e) => setValue(bypassHelpers, e.target.checked)} disabled={disabled} />
                {bypass.value &&
                    <div className="mt-3 mb-3">
                        <Label>Renvoyer vers</Label>
                        {bypassTarget.value != null &&
                            <div className="d-flex flex-row">
                                <div className="w-50">
                                    <EntityFormField field={bypassTargetAction}>
                                        <Input {...bypassTargetAction}
                                            type="select"
                                            bsSize="sm"
                                            disabled={disabled}
                                            onChange={(e) => setBypassTargetAction(+e.target.value)}>
                                            <option value="0">Numéro de téléphone externe</option>
                                            <option value="1">Personne dans l'organisation</option>
                                            <option value="2">Messagerie vocale</option>
                                        </Input>
                                    </EntityFormField>
                                </div>
                                <div className="w-50 ms-4">
                                    {bypassTargetAction.value === 0 &&
                                        <EntityFormField field={bypassTargetExternalPstn}>
                                            <PhonePicker
                                                {...bypassTargetExternalPstn}
                                                onChange={(o) => setValue(bypassTargetExternalPstnHelpers, o)}
                                                disabled={disabled} />
                                        </EntityFormField>
                                    }
                                    {bypassTargetAction.value === 1 &&
                                        <EntityFormField field={bypassTargetAgent}>
                                            <CallTargetLookup
                                                {...bypassTargetAgent}
                                                type="agent"
                                                placeholder="Rechercher un agent"
                                                onChange={(o) => setValue(bypassTargetAgentHelpers, o)}
                                                disabled={disabled}
                                            />
                                        </EntityFormField>
                                    }
                                    {bypassTargetAction.value === 2 &&
                                        <EntityFormField field={bypassTargetSharedVoicemail}>
                                            <CallTargetLookup
                                                {...bypassTargetSharedVoicemail}
                                                type="sharedVoicemail"
                                                placeholder="Rechercher une boite vocale"
                                                onChange={(o) => setValue(bypassTargetSharedVoicemailHelpers, o)}
                                                disabled={disabled}
                                            />
                                        </EntityFormField>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </EntityFormField>
    );
}
